import { Slider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

export default function sliderPosition(props) {
  const [portraitX, setPortraitX] = useState(_.get(props, `value.portrait.x`, 0.5));
  const [portraitY, setPortraitY] = useState(_.get(props, `value.portrait.y`, 0.5));
  const [landscapeX, setLandscapeX] = useState(_.get(props, `value.landscape.x`, 0.5));
  const [landscapeY, setLandscapeY] = useState(_.get(props, `value.landscape.x`, 0.5));

  useEffect(() => {
    setPortraitX(_.get(props, `value.portrait.x`, 0.5));
    setPortraitY(_.get(props, `value.portrait.y`, 0.5));
    setLandscapeX(_.get(props, `value.landscape.x`, 0.5));
    setLandscapeY(_.get(props, `value.landscape.y`, 0.5));
  }, [props.value]);
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }
  console.debug("props classes:", props.classes);
  return (
    <>
      {" "}
      {props.isVisible && !props.y_axis && (
        <div>
          <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }}>{props.label}</Typography>
          <div>
            <Typography>Portrait X</Typography>
            <Slider
              className={props.classes.sliderSize}
              value={portraitX}
              aria-labelledby="labelOffsetX"
              valueLabelDisplay="auto"
              step={0.001}
              min={0}
              max={1}
              onChange={(event, newValue) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setPortraitX(newValue);
                props.setValue({
                  portrait: { x: newValue, y: portraitY },
                  landscape: { x: landscapeX, y: landscapeY },
                });
              }}
            />
            <Typography>Portrait Y</Typography>
            <Slider
              className={props.classes.sliderSize}
              value={portraitY}
              aria-labelledby="labelOffsetX"
              valueLabelDisplay="auto"
              step={0.001}
              min={0}
              max={1}
              onChange={(event, newValue) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setPortraitY(newValue);
                props.setValue({
                  portrait: { x: portraitX, y: newValue },
                  landscape: { x: landscapeX, y: landscapeY },
                });
              }}
            />
          </div>

          <div>
            <Typography>Landscape X</Typography>
            <Slider
              className={props.classes.sliderSize}
              value={landscapeX}
              aria-labelledby="labelOffsetX"
              valueLabelDisplay="auto"
              step={0.001}
              min={0}
              max={1}
              onChange={(event, newValue) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setLandscapeX(newValue);
                props.setValue({
                  portrait: { x: portraitX, y: portraitY },
                  landscape: { x: newValue, y: landscapeY },
                });
              }}
            />
            <Typography>Landscape Y</Typography>
            <Slider
              className={props.classes.sliderSize}
              value={landscapeY}
              aria-labelledby="labelOffsetX"
              valueLabelDisplay="auto"
              step={0.001}
              min={0}
              max={1}
              onChange={(event, newValue) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setLandscapeY(newValue);
                props.setValue({
                  portrait: { x: portraitX, y: portraitY },
                  landscape: { x: landscapeX, y: newValue },
                });
              }}
            />
          </div>
        </div>
      )}
      {props.isVisible && props.y_axis && (
        <div>
          <Typography>{props.label}</Typography>
          <div>
            <Typography>Portrait Y</Typography>
            <Slider
              className={props.classes.sliderSize}
              value={portraitY}
              aria-labelledby="labelOffsetX"
              valueLabelDisplay="auto"
              step={0.001}
              min={0}
              max={1}
              onChange={(event, newValue) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setPortraitY(newValue);
                props.setValue({
                  portrait: { x: portraitX, y: newValue },
                  landscape: { x: landscapeX, y: landscapeY },
                });
              }}
            />
          </div>
          <div>
            <Typography>Landscape Y</Typography>
            <Slider
              className={props.classes.sliderSize}
              value={landscapeY}
              aria-labelledby="labelOffsetX"
              valueLabelDisplay="auto"
              step={0.001}
              min={0}
              max={1}
              onChange={(event, newValue) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setLandscapeY(newValue);
                props.setValue({
                  portrait: { x: portraitX, y: portraitY },
                  landscape: { x: landscapeX, y: newValue },
                });
              }}
            />
          </div>
        </div>
      )}
    </>
  );
}
