import { Slider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

/**
 *
 * @param props
 * @param props.classes
 * @param props.label
 * @param props.value
 * @param props.setValue
 * @param props.min
 * @param props.max
 * @param props.step
 * @returns {JSX.Element|null}
 */

export default function sliderMaxLength(props) {
  if (_.isNil(props.value)) {
    return null;
  }
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  return (
    <div className={props.classes.editorItem}>
      <Typography id="discrete-slider" variant="body2" gutterBottom>
        {_.get(props, `label`)}
      </Typography>
      <Slider
        style={{ width: "100%" }}
        value={value}
        aria-labelledby="discrete-slider"
        valueLabelDisplay="auto"
        step={props.step}
        min={props.min}
        max={props.max}
        onChange={(event, newValue) => {
          setValue(newValue);
          props.setValue(newValue);
        }}
      />
    </div>
  );
}
