import React, { useContext, useEffect, useState } from "react";
import useCtrlZEffect from "./commandZComponent";
import useCtrlShiftZEffect from "./commandShiftZComponent";
import GamePreviewToolbar from "./toolbarGamePreview";

import _ from "lodash";
import { makeStyles } from "@material-ui/core/styles";
import base5f5b691eb75dc0c7b89b2493 from "../../../games/game-5f5b691eb75dc0c7b89b2492/base-5f5b691eb75dc0c7b89b2493/node_modules/@games/solitaireguru/output/default/en/default/index.html";
import base601a649cb3e245291110acce from "../../../games/game-601a6443b3e245291110ac7a/base-601a649cb3e245291110acce/node_modules/@games/wowguru/output/default/default/en/en_index_default_playable.html";
import base603ce698a91fee82f50336fe from "../../../games/game-603ce43ea91fee82f50334d3/base-603ce698a91fee82f50336fe/node_modules/@games/wow/output/default/default/en/index.html";
import base605cc8a259a203d772445287 from "../../../games/game-605cc7b259a203d77244525c/base-605cc8a259a203d772445287/node_modules/@games/wowsearch/output/default/default/en/en_index_default_playable.html";
import base60796c54222d9801f96bac5b from "../../../games/game-6079680d222d9801f96bab74/base-60796c54222d9801f96bac5b/node_modules/@games/colorbump3d/output/default/en/default/index.html";
import base6103eaf12eae9d3e856d6ec8 from "../../../games/game-601a6443b3e245291110ac7a/base-6103eaf12eae9d3e856d6ec8/node_modules/@games/wowgurubase2/output/default/default/en/en_index_default_playable.html";
import base6140893d93ddb73076a1554e from "../../../games/game-614081de93ddb73076a1538a/base-6140893d93ddb73076a1554e/node_modules/@games/hunter-assassin-2/output/default/en/default/index.html";
import base615df9f441e1e0829ab60118 from "../../../games/game-601a6443b3e245291110ac7a/base-615df9f441e1e0829ab60118/node_modules/@games/wowgurubase3/output/default/en/default/index.html";
import base619e14fb68af90fbc06a4345 from "../../../games/game-619e14fb68af90fbc06a4345/base-619e14fb68af90fbc06a4345/node_modules/@apps/endcard/output/default/en/default/index.html";
import base61af33d3222f4485886da806 from "../../../games/game-61af33d3222f4485886da806/base-61af33d3222f4485886da806/node_modules/@apps/atlasgame/output/default/en/default/index.html";
import base61fd1eb64e862442e1bd6828 from "../../../games/game-61fd1d864e862442e1bd67db/base-61fd1eb64e862442e1bd6828/node_modules/@games/word-letter/output/default/en/default/index.html";
import base623c457358d6dd7853a01a00 from "../../../games/game-61af33d3222f4485886da806/base-623c457358d6dd7853a01a00/node_modules/@apps/video-game/output/default/en/default/index.html";
import base625984d758d6dd7853a1bd9d from "../../../games/game-62581d4558d6dd7853a161e8/base-625984d758d6dd7853a1bd9d/node_modules/@games/balls-master/output/default/en/default/index.html";
import base625e7ecf152625add904a6d9 from "../../../games/game-625e7d9c152625add904a679/base-625e7ecf152625add904a6d9/node_modules/@games/ball-blast/output/default/en/default/index.html";
import base6266a6fe152625add904bd19 from "../../../games/game-6266a66a152625add904bce8/base-6266a6fe152625add904bd19/node_modules/@games/tap-on-time/output/default/en/default/index.html";
import base6267c05ae12f014cd14b6eed from "../../../games/game-6267bb9ee12f014cd14b6c9f/base-6267c05ae12f014cd14b6eed/node_modules/@games/game-of-words/output/default/en/default/index.html";
import base627babd141f6066717ff417d from "../../../games/game-627baaf241f6066717ff413f/base-627babd141f6066717ff417d/node_modules/@games/name-the-activity/output/default/en/default/index.html";
import base6286500f41f6066717ff80c7 from "../../../games/game-62864cd341f6066717ff801e/base-6286500f41f6066717ff80c7/node_modules/@games/mad-smash/output/default/en/default/index.html";
import base629e0b671f70c95b7fd67de8 from "../../../games/game-629e01961f70c95b7fd67b3a/base-629e0b671f70c95b7fd67de8/node_modules/@games/wordsquare/output/default/default/en/en_index_default_playable.html";
import base62a0a15241f6066717fff6ee from "../../../games/game-62a09e2041f6066717fff686/base-62a0a15241f6066717fff6ee/node_modules/@apps/video-game-html/output/default/default/en/en_index_default_playable.html";
import base62b9b2a2cc0ad8e0a9eaa9b7 from "../../../games/game-62b9a830cc0ad8e0a9eaa81c/base-62b9b2a2cc0ad8e0a9eaa9b7/node_modules/@apps/atlas-game-html/output/default/en/default/index.html";
import base62e8c9a757d6958461cbea45 from "../../../games/game-62e8c71357d6958461cbe9d6/base-62e8c9a757d6958461cbea45/node_modules/@games/crossword-playable/output/default/en/default/index.html";
import base62f50da057d6958461cc550e from "../../../games/game-62f50cd857d6958461cc54d3/base-62f50da057d6958461cc550e/node_modules/@games/crossword-jam/output/default/en/default/index.html";
import base62f612be60e29e02961bbfd6 from "../../../games/game-62f6121457d6958461cc7ef0/base-62f612be60e29e02961bbfd6/node_modules/@games/wordsquare-playsimple/output/default/en/default/index.html";
import base6318818257d6958461cd6005 from "../../../games/game-6318807957d6958461cd5fc1/base-6318818257d6958461cd6005/node_modules/@games/word-wars/output/default/en/default/index.html";
import base632acab6d12f27f917de5a16 from "../../../games/game-632aca17d12f27f917de59ec/base-632acab6d12f27f917de5a16/node_modules/@games/anagram/output/default/en/default/index.html";
import base6332e467d12f27f917df1ac0 from "../../../games/game-62f50cd857d6958461cc54d3/base-6332e467d12f27f917df1ac0/node_modules/@games/cwdJam2/output/default/en/default/index.html";
import base635fe9eaed1431b3f7d22181 from "../../../games/game-635fe93ded1431b3f7d2215c/base-635fe9eaed1431b3f7d22181/node_modules/@games/alien_shooter/output/default/default/en/index.html";
import base637345bcdd2735639e05c8b0 from "../../../games/game-6371ed43dd2735639e05b6ab/base-637345bcdd2735639e05c8b0/node_modules/@games/sunday-solitaire/output/default/default/en/en_index_default_playable.html";
import base63970bb3a16dbba4ba8df48a from "../../../games/game-601a6443b3e245291110ac7a/base-63970bb3a16dbba4ba8df48a/node_modules/@games/wowgurubase5/output/default/default/en/index.html";
import base6397080fa16dbba4ba8df419 from "../../../games/game-601a6443b3e245291110ac7a/base-6397080fa16dbba4ba8df419/node_modules/@games/wowgurubase4/output/default/en/default/index.html";
import base63f8a309a2afbaeb5f803e1f from "../../../games/game-63f8a26aa2afbaeb5f803de3/base-63f8a309a2afbaeb5f803e1f/node_modules/@games/word-surf/output/default/en/default/index.html";
import base63ff2e7ff1b9d0fb84872367 from "../../../games/game-63ff24d8f1b9d0fb8487223f/base-63ff2e7ff1b9d0fb84872367/node_modules/@games/word-search-ps/output/default/en/default/index.html";
import base64007d1f7d19caf1ca79a27b from "../../../games/game-640060027d19caf1ca799d1f/base-64007d1f7d19caf1ca79a27b/node_modules/@games/scratch-html/output/default/en/default/index.html";
import base6450ec1566c580b614f8bb06 from "../../../games/game-629e01961f70c95b7fd67b3a/base-6450ec1566c580b614f8bb06/node_modules/@games/wordsquarebase2/output/default/default/en/en_index_default_playable.html";
import base6461dcd4f01fdda7a6d2769b from "../../../games/game-6318807957d6958461cd5fc1/base-6461dcd4f01fdda7a6d2769b/node_modules/@games/wordWarsBase2/output/default/en/default/index.html";
import base649a90aaf01fdda7a6d44888 from "../../../games/game-649a7061f01fdda7a6d44598/base-649a90aaf01fdda7a6d44888/node_modules/@games/wizard-hero/output/default/en/default/index.html";
import base650d4cfef1e29aa6e06d261f from "../../../games/game-603ce43ea91fee82f50334d3/base-650d4cfef1e29aa6e06d261f/node_modules/@games/wowBase2/output/default/en/default/index.html";
import base651a6d1f1bc1b828d545667b from "../../../games/game-603ce43ea91fee82f50334d3/base-651a6d1f1bc1b828d545667b/node_modules/@games/wowBase3/output/default/default/en/index.html";
import base6527bf7c1904b92b4fbd6721 from "../../../games/game-63ff24d8f1b9d0fb8487223f/base-6527bf7c1904b92b4fbd6721/node_modules/@games/wordsearchpsbase2/output/default/en/default/index.html";
import base6530e2601904b92b4fbe379d from "../../../games/game-603ce43ea91fee82f50334d3/base-6530e2601904b92b4fbe379d/node_modules/@games/wowBase4/output/default/default/en/en_index_default_playable.html";
import base653777541904b92b4fbec721 from "../../../games/game-603ce43ea91fee82f50334d3/base-653777541904b92b4fbec721/node_modules/@games/wowBase5/output/default/default/en/en_index_default_playable.html";
import base654b3d221904b92b4fbeeb53 from "../../../games/game-605cc7b259a203d77244525c/base-654b3d221904b92b4fbeeb53/node_modules/@games/wordsearchbase2/output/default/default/en/en_index_default_playable.html";
import base6570216c7e3091d6e2607d91 from "../../../games/game-656f16ee7e3091d6e2607a1d/base-6570216c7e3091d6e2607d91/node_modules/@games/differences/output/default/en/default/index.html";
import base65a6644b99010ea68565fd0e from "../../../games/game-65a6576f99010ea68565fb77/base-65a6644b99010ea68565fd0e/node_modules/@games/tile-match-ps/output/default/en/default/index.html";
import base65cb1d1d897ec6f9db711418 from "../../../games/game-65cb16fc897ec6f9db711354/base-65cb1d1d897ec6f9db711418/node_modules/@games/2248-tiles-ps/output/default/en/default/index.html";
import base65fae73d40aceaef5d88bd70 from "../../../games/game-65fae6d540aceaef5d88bd51/base-65fae73d40aceaef5d88bd70/node_modules/@games/bloxie_base1/output/default/default/en/en_index_default_playable.html";
import base6613bbb840aceaef5d8ca8fd from "../../../games/game-601a6443b3e245291110ac7a/base-6613bbb840aceaef5d8ca8fd/node_modules/@games/crossword-jam-fugo/output/default/default/en/en_index_default_playable.html";
import base6615052f40aceaef5d8cba51 from "../../../games/game-661504b840aceaef5d8cba1f/base-6615052f40aceaef5d8cba51/node_modules/@games/block-buster/output/default/default/en/en_index_default_playable.html";
import base6615094d40aceaef5d8cbafd from "../../../games/game-6615087940aceaef5d8cbace/base-6615094d40aceaef5d8cbafd/node_modules/@games/endCard-html/output/default/default/en/en_index_default_playable.html";
import base6627a1da40aceaef5d8eee0d from "../../../games/game-6627943e40aceaef5d8eec81/base-6627a1da40aceaef5d8eee0d/node_modules/@games/find-hidden-objects/output/default/en/default/index.html";
import base66334529937d97accbf16a85 from "../../../games/game-66333f70937d97accbf169e1/base-66334529937d97accbf16a85/node_modules/@games/hidden-tiles/output/default/en/default/index.html";
import base6644c147937d97accbf26d7a from "../../../games/game-6644b6a2937d97accbf26c30/base-6644c147937d97accbf26d7a/node_modules/@games/word-roll/output/default/en/default/index.html";
import base664df9c5bb75a0017c85a6aa from "../../../games/game-603ce43ea91fee82f50334d3/base-664df9c5bb75a0017c85a6aa/node_modules/@games/grammar-game/output/default/default/en/en_index_default_playable.html";
import base668e67310f1aafc87706b7d0 from "../../../games/game-66333f70937d97accbf169e1/base-668e67310f1aafc87706b7d0/node_modules/@games/hiddenObjects/output/default/en/default/index.html";
import base66b5fe200f1aafc87731fcb5 from "../../../games/game-62f50cd857d6958461cc54d3/base-66b5fe200f1aafc87731fcb5/node_modules/@games/crossword-jam-base3/output/default/en/default/index.html";

import { Typography } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import { UndoContext } from "../contexts/UndoContext";

const useClass = makeStyles({
  gamePreviewDiv: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
  },
  iframeDiv: {
    flex: 1,
    padding: "10px",
    display: "flex",
    // position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },
  buttons: {
    zIndex: "100",
    position: "absolute",
    bottom: "55px",
    right: "405px",
    display: "flex",
    backgroundColor: "#30302f",
    borderRadius: "5px",
    flexDirection: "column",
  },
  button: {
    // backgroundColor:"#30302f",
    marginBottom: "10px",
    borderRadius: "5px",
    padding: "10px",
  },
  selectedVersionTitle: { height: "50px", textAlign: "center" },
  gamePreviewIframe: { maxWidth: "100%", maxHeight: "100%", borderStyle: "none" },
  fullScreen: {
    zIndex: 10000,
    position: "absolute",
    width: "100%",
    height: "100%",
    border: "0 !important",
    margin: "0 !important",
    padding: "0 !important",
    top: 0,
    right: 0,
  },
  gamePreviewLoader: {
    position: "fixed",
    top: "50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    left: "50%",
  },
});

export function resize({
  newAspectRatio,
  newIsPortrait,
  containerDivID = "iframeDiv",
  previewIframeID = "gamePreviewIframe",
}) {
  try {
    let iframeDiv = document.getElementById(containerDivID);
    let previewIframe = document.getElementById(previewIframeID);
    let tempWidth = iframeDiv.clientWidth; // previewTable.clientHeight - 50;
    let tempHeight = tempWidth * (newIsPortrait ? newAspectRatio : 1 / newAspectRatio);

    if (tempHeight > iframeDiv.clientHeight) {
      tempHeight = iframeDiv.clientHeight;
      tempWidth = tempHeight * (newIsPortrait ? 1 / newAspectRatio : newAspectRatio);
    }

    previewIframe.width = tempWidth - 25;
    previewIframe.height = tempHeight - 25;
  } catch (err) {
    console.error(err);
  }
}

/**
 *
 * @param props.fetchVersions {function}
 * @param props.setSelectedVersion {function}
 * @param props.isPreviewFullScreen {boolean}
 * @param props.selectedBase
 * @param props.selectedGame
 * @param props.selectedVersion
 * @param props.deleteVersionFromView
 * @param props.isPreviewLoaded
 * @param props.setIsPreviewLoaded
 * @param props.applyPreview
 * @param props.refresh
 * @param props.userPermissions
 * @param props.setIsDownloadingPlayable
 * @param props.insertNewVersion
 * @param props.prevButton
 * @param props.setValue
 * @param props.versionRename
 * @param props.versions
 * @param props.saveVersionAndApplyPreview
 * @param props.iFrameId
 * @param props.totalSizeValue
 * @param props.userCompany
 * @param props.setUserCompany
 * @param props.localizationLanguage
 * @param props.setPortrait
 * @param props.classes
 * @param props.setSelectedVersionPathValue
 * @param props.createSnackbar
 * @param props.plans
 * @param props.setSelectedVersionPathValue
 * @param props.getPlanIndex
 * @param props.selectedVersion.gameConfig.components.autoplay1
 * @returns {JSX.Element|null}
 */
export default function containerGamePreview(props) {
  let [aspectRatio, setAspectRatio] = useState(1.77);
  let [isPortrait, setIsPortrait] = useState(true);
  let [gameTitle, setGameTitle] = useState("");
  const [previewLoaded, setPreviewLoaded] = useState(false);

  const undoCtx = useContext(UndoContext);
  const handleCtrlZ = () => {
    undo();
  };
  useCtrlZEffect(handleCtrlZ);
  const handleCtrlShiftZ = () => {
    redo();
  };
  useCtrlShiftZEffect(handleCtrlShiftZ);

  const undo = () => {
    let gameConf;
    try {
      console.log(" undoStack  ", JSON.parse(undoCtx.undoStack[undoCtx.cursor - 1]).maxLength != null);
      gameConf = false;
    } catch {
      gameConf = true;
    }
    if (gameConf) {
      let gameConfig = undoCtx.applyUndo(props.selectedVersion.gameConfig, 1);
      if (!gameConfig) {
        return;
      }
      if (typeof gameConfig.components.autoplay1 !== "undefined") {
        gameConfig.components.autoplay1.startAutoplay = props.prevButton;
      }
      applyUpdates(gameConfig);
    } else {
      let recorderSettings = JSON.parse(undoCtx.applyUndo(props.selectedVersion.recorderSettings, 0));
      // if (!recorderSettings) {
      //     return
      // }
      applyUpdatesRecorder(recorderSettings);
    }
  };
  const redo = () => {
    let gameConf;
    try {
      console.log(" redostack  ", JSON.parse(undoCtx.undoStack[undoCtx.cursor + 1]).maxLength != null);
      gameConf = false;
    } catch {
      gameConf = true;
    }
    if (!gameConf) {
      const recorderSettings = JSON.parse(undoCtx.applyRedo());
      // if (!recorderSettings) {
      //     return
      // }
      applyUpdatesRecorder(recorderSettings);
    } else {
      let gameConfig = undoCtx.applyRedo();

      if (!gameConfig) {
        return;
      }
      if (typeof gameConfig.components.autoplay1 !== "undefined") {
        gameConfig.components.autoplay1.startAutoplay = props.prevButton;
      }

      applyUpdates(gameConfig);

    }

  };
  useEffect(() => {
    if (props.selectedVersion) {
      setGameTitle(props.selectedVersion.title);
    }
  }, [props.selectedVersion]);
  useEffect(() => {
    props.fetchVersions(props.selectedBase._id, localStorage.getItem("user.companyID"));
    if (props.selectedVersion) {
      props.setValue(getVersionByID(props.selectedVersion._id));
    }
    setGameTitle(props.versionRename);
  }, [props.versionRename]);

  function getVersionByID(versionIDSString) {
    const found = props.versions.filter((version) => {
      return String(version._id) === versionIDSString;
    });
    if (_.size(found) > 0) {
      return found[0];
    } else {
      //throw { message: "could not find version by id: ", versionIDSString };
      return {};
    }
  }

  const applyUpdates = (gameConfig) => {
    props.applyPreview({
      newGameConfig: gameConfig,
      selectedBase: props.selectedBase,
    });
    props.saveVersionAndApplyPreview(
      {
        newSelectedVersion: {
          ...props.selectedVersion,
          gameConfig,
        },
      },
      false,
      true
    );
    props.setValue((prev) => {
      return {
        ...prev,
        gameConfig,
      };
    });
  };

  const applyUpdatesRecorder = (recorderSettings) => {
    props.saveVersionAndApplyPreview(
      {
        newSelectedVersion: {
          ...props.selectedVersion,
          recorderSettings,
        },
      },
      false,
      true
    );
    props.setValue((prev) => {
      return {
        ...prev,
        recorderSettings,
      };
    });
  };
  useEffect(() => {
    if (!props.selectedVersion) {
      // console.log("selected version is not found");
      undoCtx.clearUndoStack();

      return;
    }

    undoCtx.clearUndoStack();
  }, [props.selectedVersion?._id]);
  const classes = useClass();
  const previews = {
    "5f5b691eb75dc0c7b89b2493": base5f5b691eb75dc0c7b89b2493,
    "601a649cb3e245291110acce": base601a649cb3e245291110acce,
    "603ce698a91fee82f50336fe": base603ce698a91fee82f50336fe,
    "605cc8a259a203d772445287": base605cc8a259a203d772445287,
    "60796c54222d9801f96bac5b": base60796c54222d9801f96bac5b,
    "6103eaf12eae9d3e856d6ec8": base6103eaf12eae9d3e856d6ec8,
    "6140893d93ddb73076a1554e": base6140893d93ddb73076a1554e,
    "615df9f441e1e0829ab60118": base615df9f441e1e0829ab60118,
    "619e14fb68af90fbc06a4345": base619e14fb68af90fbc06a4345,
    "61af33d3222f4485886da806": base61af33d3222f4485886da806,
    "61fd1eb64e862442e1bd6828": base61fd1eb64e862442e1bd6828,
    "623c457358d6dd7853a01a00": base623c457358d6dd7853a01a00,
    "625984d758d6dd7853a1bd9d": base625984d758d6dd7853a1bd9d,
    "625e7ecf152625add904a6d9": base625e7ecf152625add904a6d9,
    "6266a6fe152625add904bd19": base6266a6fe152625add904bd19,
    "6267c05ae12f014cd14b6eed": base6267c05ae12f014cd14b6eed,
    "627babd141f6066717ff417d": base627babd141f6066717ff417d,
    "6286500f41f6066717ff80c7": base6286500f41f6066717ff80c7,
    "629e0b671f70c95b7fd67de8": base629e0b671f70c95b7fd67de8,
    "62a0a15241f6066717fff6ee": base62a0a15241f6066717fff6ee,
    "62b9b2a2cc0ad8e0a9eaa9b7": base62b9b2a2cc0ad8e0a9eaa9b7,
    "62e8c9a757d6958461cbea45": base62e8c9a757d6958461cbea45,
    "62f50da057d6958461cc550e": base62f50da057d6958461cc550e,
    "62f612be60e29e02961bbfd6": base62f612be60e29e02961bbfd6,
    "6318818257d6958461cd6005": base6318818257d6958461cd6005,
    "632acab6d12f27f917de5a16": base632acab6d12f27f917de5a16,
    "6332e467d12f27f917df1ac0": base6332e467d12f27f917df1ac0,
    "635fe9eaed1431b3f7d22181": base635fe9eaed1431b3f7d22181,
    "637345bcdd2735639e05c8b0": base637345bcdd2735639e05c8b0,
    "6397080fa16dbba4ba8df419": base6397080fa16dbba4ba8df419,
    "63970bb3a16dbba4ba8df48a": base63970bb3a16dbba4ba8df48a,
    "63f8a309a2afbaeb5f803e1f": base63f8a309a2afbaeb5f803e1f,
    "63ff2e7ff1b9d0fb84872367": base63ff2e7ff1b9d0fb84872367,
    "64007d1f7d19caf1ca79a27b": base64007d1f7d19caf1ca79a27b,
    "6450ec1566c580b614f8bb06": base6450ec1566c580b614f8bb06,
    "6461dcd4f01fdda7a6d2769b": base6461dcd4f01fdda7a6d2769b,
    "649a90aaf01fdda7a6d44888": base649a90aaf01fdda7a6d44888,
    "650d4cfef1e29aa6e06d261f": base650d4cfef1e29aa6e06d261f,
    "651a6d1f1bc1b828d545667b":base651a6d1f1bc1b828d545667b,
    "6527bf7c1904b92b4fbd6721":base6527bf7c1904b92b4fbd6721,
    "6530e2601904b92b4fbe379d":base6530e2601904b92b4fbe379d,
    "653777541904b92b4fbec721" :base653777541904b92b4fbec721,
    "654b3d221904b92b4fbeeb53" : base654b3d221904b92b4fbeeb53,
    "6570216c7e3091d6e2607d91":base6570216c7e3091d6e2607d91,
    "65a6644b99010ea68565fd0e": base65a6644b99010ea68565fd0e,
    "65cb1d1d897ec6f9db711418":base65cb1d1d897ec6f9db711418,
    "65fae73d40aceaef5d88bd70":base65fae73d40aceaef5d88bd70,
    "6613bbb840aceaef5d8ca8fd": base6613bbb840aceaef5d8ca8fd,
    "6615052f40aceaef5d8cba51":base6615052f40aceaef5d8cba51,
    "6615094d40aceaef5d8cbafd": base6615094d40aceaef5d8cbafd,
    "6627a1da40aceaef5d8eee0d":base6627a1da40aceaef5d8eee0d,
    "66334529937d97accbf16a85":base66334529937d97accbf16a85,
    "6644c147937d97accbf26d7a":base6644c147937d97accbf26d7a,
    "664df9c5bb75a0017c85a6aa":base664df9c5bb75a0017c85a6aa,
    "668e67310f1aafc87706b7d0":base668e67310f1aafc87706b7d0,
    "66b5fe200f1aafc87731fcb5":base66b5fe200f1aafc87731fcb5
  };

  if (!previews.hasOwnProperty(String(_.get(props, "selectedBase._id")))) {
    return <div>Game preview is not available</div>;
  }
  if (_.isNil(props.selectedBase) || _.isNil(props.selectedVersion)) {
    return null;
  }

  window.onresize = _.debounce((event) => {
    resize({ event, newAspectRatio: aspectRatio, newIsPortrait: isPortrait });
  }, 100);

  return (
    <div id={"gamePreviewDiv"} className={classes.gamePreviewDiv}>
      <GamePreviewToolbar
        iFrameId={props.iFrameId}
        totalSizeValue={props.totalSizeValue}
        selectedVersion={props.selectedVersion}
        selectedBase={props.selectedBase}
        selectedGame={props.selectedGame}
        userCompany={props.userCompany}
        applyPreview={props.applyPreview}
        setUserCompany={props.setUserCompany}
        versionsAreFetched={props.versionsAreFetched}
        deleteVersionFromView={props.deleteVersionFromView}
        fetchVersions={props.fetchVersions}
        versionUpdate={props.versionUpdate}
        isPortrait={isPortrait}
        versionRename={props.versionRename}
        versions={props.versions}
        localizationLanguage={props.localizationLanguage}
        setIsPortrait={setIsPortrait}
        setPortrait={props.setPortrait}
        aspectRatio={aspectRatio}
        setAspectRatio={setAspectRatio}
        setSelectedVersion={props.setSelectedVersion}
        isPreviewLoaded={props.isPreviewLoaded}
        setIsPreviewLoaded={props.setIsPreviewLoaded}
        classes={props.classes}
        setSelectedVersionPathValue={props.setSelectedVersionPathValue}
        refresh={props.refresh}
        createSnackbar={props.createSnackbar}
        plans={props.plans}
        getPlanIndex={props.getPlanIndex}
        userPermissions={props.userPermissions}
        setIsDownloadingPlayable={props.setIsDownloadingPlayable}
        insertNewVersion={props.insertNewVersion}
        setValue={props.setValue}
      ></GamePreviewToolbar>
      {(!props.isPreviewLoaded || !previewLoaded) && (
        <div className={classes.gamePreviewLoader}>
          <CircularProgress />
        </div>
      )}
      <div id={"iframeDiv"} className={classes.iframeDiv}>
        <iframe
          allow="autoplay"
          id={props.iFrameId}
          allow-scripts={"true"}
          className={classes.gamePreviewIframe + " " + (props.isPreviewFullScreen ? classes.fullScreen : "")}
          src={previews[String(_.get(props, "selectedBase._id"))]}
          style={{ visibility: "hidden" }}
          onLoad={(event) => {
            resize({ event, newAspectRatio: aspectRatio, newIsPortrait: isPortrait });
            props.setIsPreviewLoaded(true);
            setPreviewLoaded(true);
            props.applyPreview({
              newGameConfig: props.selectedVersion.gameConfig,
              selectedBase: props.selectedBase,
            });
            document.getElementById("gamePreviewIframe").style.setProperty("visibility", "visible");
          }}
        ></iframe>
      </div>
      <div
        id={"buttons"}
        style={{ display: undoCtx.clearButtons() ? undoCtx.clearButtons() : "none" }}
        className={classes.buttons}
      >
        <button
          style={{ background: "none", border: "none", borderRadius: "5px", width: "50px", height: "50px" }}
          onClick={undo}
        >
          <svg height="60%" viewBox="0 0 24 24" width="60%" xmlns="http://www.w3.org/2000/svg">
            <path
              fill={undoCtx.buttonUndoColor}
              d="M9 10h6c1.654 0 3 1.346 3 3s-1.346 3-3 3h-3v2h3c2.757 0 5-2.243 5-5s-2.243-5-5-5H9V5L4 9l5 4v-3z"
            ></path>
          </svg>
        </button>

        <button
          style={{ background: "none", border: "none", borderRadius: "5px", width: "50px", height: "50px" }}
          onClick={redo}
        >
          <svg height="60%" viewBox="0 0 24 24" width="60%" xmlns="http://www.w3.org/2000/svg">
            <path
              fill={undoCtx.buttonRedoColor}
              d="M9 18h3v-2H9c-1.654 0-3-1.346-3-3s1.346-3 3-3h6v3l5-4-5-4v3H9c-2.757 0-5 2.243-5 5s2.243 5 5 5z"
            ></path>
          </svg>
        </button>
      </div>
      <div
        style={{ wordBreak: "break-all", flexShrink: "1", overflow: "hidden", marginLeft: "5px", marginRight: "5px" }}
      >
        <Typography id={"selectedVersionDiv"} className={classes.selectedVersionTitle} variant={"h6"}>
          {gameTitle}
        </Typography>
      </div>
    </div>
  );
}
