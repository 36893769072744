import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Card,
  CardContent,
  Dialog,
  DialogTitle,
  Button,
  Typography,
  MenuItem,
  TextField,
  Select,
  Input,
  ListItemText,
  CircularProgress,
  IconButton,
  Chip,
  Box,
  InputLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import applovinLogo from "../../images/network/IconsThin/applovin-icon.png";
import facebookLogo from "../../images/network/IconsThin/facebook-icon.png";
import googleLogo from "../../images/network/IconsThin/googleads-icon.png";
import ironsourceLogo from "../../images/network/IconsThin/ironsource-icon.png";
import mintegralLogo from "../../images/network/IconsThin/mintegral-icon.png";
import unityLogo from "../../images/network/IconsThin/unityads-icon.png";
import vungleLogo from "../../images/network/IconsThin/vungle-icon.png";
import tiktokLogo from "../../images/network/IconsThin/tiktok-icon.png";
import molocoIcon from "../../images/network/IconsThin/moloco-icon.png";
import DownloadIcon from "@mui/icons-material/Download";
import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ArrowRightRoundedIcon from "@material-ui/icons/ArrowRightRounded";
import ArrowLeftRoundedIcon from "@material-ui/icons/ArrowLeftRounded";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
const networkViews = [
  { name: "applovin", logo: applovinLogo, caption: "AppLovin" },
  { name: "facebook", logo: facebookLogo, caption: "Facebook" },
  { name: "google", logo: googleLogo, caption: "Google" },
  { name: "ironsource", logo: ironsourceLogo, caption: "IronSource" },
  { name: "mintegral", logo: mintegralLogo, caption: "Mintegral" },
  { name: "unity", logo: unityLogo, caption: "Unity" },
  { name: "vungle", logo: vungleLogo, caption: "Vungle" },
  { name: "tiktok", logo: tiktokLogo, caption: "Tiktok" },
  { name: "moloco", logo: molocoIcon, caption: "Moloco" },
];

const useStyles = makeStyles(() => ({
  networkLogo: { height: 20 },
  networkDiv: { display: "flex", alignItems: "center", padding: "2px 10px 2px 0px" }, //#9bcec0
  versionSelector: {
    width: "100%",
    fontSize: "large",
    fontFamily: "Helvetica",
    color: "#eeeeee",
    "&:before": {
      "borderBottom": "1px solid #676767 !important",
    },
    "&:hover:not(.Mui-disabled):before": {
      "borderBottom": "2px solid #ffffff !important",
    },
  },
  inputLabel: { fontSize: "14px", color: "#9bcec0" },
  downloadDialogCard: {
    margin: "0px 30px 15px 30px",
    padding: 0,
    width: 440,
    backgroundColor: "#363636",
    color: "#eeeeee",
    borderRadius: "10px",
  },
  dialogTitle: { textAlign: "center", fontSize: "100px", fontFamily: "Helvetica" },
  htmlCreditTypography: { width: "146.6px" },
  dialogStyle: { borderRadius: "10px" },
  loadingIcon: {
    width: 400,
    height: 500,
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: "inherit",
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 15 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

/**
 *
 * @param props.open
 * @param props.isOpen
 * @param props.onClose
 * @param props.selectedVersion
 * @param props.selectedBase
 * @param props.setIsDownloadingPlayable
 * @returns {JSX.Element}
 */
export default function dialogMultipleVersionDownload(props) {
  const [selectedLanguagesSet, setSelectedLanguagesSet] = useState(new Set());

  const [isBuilding, setIsBuilding] = useState(false);
  const [buttonText, setButtonText] = useState("Download");
  const [error, setError] = useState("");
  const [selectedVersions, setSelectedVersions] = useState([]);
  const [versionNames, setVersionNames] = useState([]);
  const [selectedVersionsInfos, setSelectedVersionsInfos] = useState([
    { versionID: props.selectedVersion?._id, languages: ["en"], networks: [] },
  ]);
  const [versionSizes, setVersionSizes] = useState([]);
  let [currentVersion, setCurrentVersion] = useState({});
  let [versionIndex, setVersionIndex] = useState(0);
  let [currentPlayableCount, setCurrentPlayableCount] = useState(0);
  let [htmlCount, setHtmlCount] = useState(0);
  let [creditLeft, setCreditLeft] = useState(currentPlayableCount);
  let [downloadedVersionCount, setDownloadedVersionCount] = useState(1);
  let [progressCompleted, setProgressCompleted] = useState(true);
  let [languagesLoaded, setLanguagesLoaded] = useState(false);
  let [allVersionsAdded, setAllVersionsAdded] = useState(false);
  let [loadingCompleted, setLoadingCompleted] = useState(false);
  let [allLanguagesSelected, setAllLanguagesSelected] = useState(false);
  const classes = useStyles();
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: { main: "#4eabb1" },
    },
  });

  const StyledChip = styled(Chip)(() => ({
    borderRadius: "3px",
    width: "325px",
    borderWidth: "thin",
    borderColor: "#676767",
    height: "70px",
    fontSize: "20px",
    lineHeight: "23px",
    color: "#eeeeee",
    "& .MuiChip-label": {
      whiteSpace: "normal",
      maxWidth: "325px",
      overflow: "visible",
      wordWrap: "break-word",
      display: "block",
      textAlign: "center",
    },
  }));

  const StyledDopDownIcon = styled(ArrowDropDownRoundedIcon)(() => ({
    fontSize: "35px",
    "&. MuiSelect-icon": {
      "top": "0px",
    },
  }));

  const StyledDialogTitle = styled(DialogTitle)({
    textAlign: "center",
    color: "#eeeeee",
    marginTop: "3px",
    "& .MuiTypography-h6": {
      fontSize: "24px",
      fontFamily: "Helvetica",
    },
  });

  const StyledButton = styled(Button)({
    fontSize: "20px",
    backgroundColor: "#418989",
    width: "190px",
    height: "60px",
    lineHeight: "23px",
    fontFamily: "Helvetica",
    fontWeight: "bold",
    "&:hover": {
      backgroundColor: "#579488",
    },
  });

  useEffect(() => {
    selectedVersionLanguagesFunction();
  }, [props.localizationLanguage]);

  useEffect(() => {
    getVersionNames();
    getCurrentPlayableCount(props.userCompany);
  }, []);

  useEffect(() => {
    let newVersionSizes = [...versionSizes];

    let versionSizeInfo = newVersionSizes.filter(
      (versionSize) => versionSize.versionID === props.selectedVersion?._id
    )[0];
    if (versionSizeInfo) {
      const index = newVersionSizes.indexOf(versionSizeInfo);

      newVersionSizes[index] = { versionID: props.selectedVersion?._id, size: props.totalSizeValue };
    } else {
      newVersionSizes.push({ versionID: props.selectedVersion?._id, size: props.totalSizeValue });
    }
    setVersionSizes(newVersionSizes);

    setLanguagesLoaded(false);
  }, [props.selectedVersion, props.totalSizeValue]);

  useEffect(() => {
    getCurrentSelectedVersionLanguageInfos();

    if (languagesLoaded && props.totalSizeValue > 0 && allVersionsAdded) {
      setLoadingCompleted(true);
    }
  }, [languagesLoaded, props.totalSizeValue, allVersionsAdded]);

  useEffect(() => {
    getVersionNames();
  }, [props.selectedVersion, props.versions, props.versionUpdate, props.versionRename]);

  useEffect(() => {
    let selectedVersionsInfo = [...selectedVersionsInfos];

    const currentVersionSelectedLanguages = selectedVersionsInfo.filter(
      (version) => version.versionID == selectedVersions[versionIndex]
    )[0]?.languages;
    const newSelectedLanguagesSet = currentVersionSelectedLanguages
      ? new Set(currentVersionSelectedLanguages)
      : new Set(selectedLanguagesSet);

    setSelectedLanguagesSet(new Set());

    if (!newSelectedLanguagesSet.has("en")) {
      newSelectedLanguagesSet.add("en");
    }

    setSelectedLanguagesSet(newSelectedLanguagesSet);

    getSelectedVersion();
    selectedVersionLanguagesFunction();
    selectedVersionNetworksFunction();
  }, [versionIndex]);

  useEffect(() => {
    setDownloadedVersionCount(downloadedVersionCount);
    getDownloadedVersionCount();
  }, [progressCompleted]);

  function getVersionNames() {
    setVersionNames([]);

    if (props.versions.length > 0) {
      let allVersionNames = [];

      props.versions.forEach((version) => {
        if (props.selectedVersion?._id == version._id) {
          setSelectedVersions([version._id]);
          setCurrentVersion({ versionName: version.title, key: version._id });
        }

        allVersionNames.push({ versionName: version.title, key: version._id });
      });
      setVersionNames(allVersionNames);
      setAllVersionsAdded(true);
    }
    setSelectedVersionsInfos([{ versionID: props.selectedVersion?._id, languages: ["en"], networks: [] }]);
    //
    // getSelectedVersionName();
    // setVersionIndex(0);
  }

  function getDownloadedVersionCount() {
    return downloadedVersionCount;
  }

  function getVersionSizes(version, allVersionSizes) {
    async function getSizes() {
      if (!_.isNil(version)) {
        let totalSizeResult = 0;
        let componentSizes = [];
        const promises = [];
        const base64SizeMultiplier = 1.37;

        Object.entries(_.get(version, "gameConfig.components", {})).forEach(async ([componentId, component]) => {
          if (_.get(component, "isEnabled") !== false) {
            if (_.has(component, "selectedImages")) {
              let selectedImages = _.get(component, "selectedImages");
              Object.entries(selectedImages).forEach(([id, images]) => {
                images.forEach((image) => {
                  let imagePromise = fetch(`images/${image.file}`)
                    .then((response) => response.blob())
                    .then((blob) => {
                      let name = `${componentId} - ${id}`;
                      let size = blob.size * base64SizeMultiplier;

                      if (_.has(image, "variant")) {
                        let variant = _.get(image, "variant");
                        name += ` - ${variant}`;
                      }
                      let index = componentSizes.indexOf((imageSize) => imageSize.name === name);

                      if (index !== -1) {
                        const imageSize = componentSizes.filter((imageSize) => imageSize.name == name)[0];
                        //add old size and new one to find total size
                        _.set(componentSizes[index], "size", imageSize.size + blob.size);
                      } else {
                        componentSizes.push({
                          id: componentSizes.length,
                          name: name,
                          size: size,
                          type: "image",
                        });
                      }
                      totalSizeResult += size;
                    });
                  promises.push(imagePromise);
                });
              });
            }
            if (_.has(component, "selectedAtlases")) {
              let selectedAtlases = _.get(component, "selectedAtlases");
              Object.entries(selectedAtlases).forEach(([id, atlases]) => {
                atlases.forEach((atlas) => {
                  let atlasPromise = fetch(`atlases/${atlas.file}`)
                    .then((response) => response.blob())
                    .then((blob) => {
                      let name = `${componentId} - ${id}`;
                      let size = blob.size * base64SizeMultiplier;

                      if (_.has(atlas, "variant")) {
                        let variant = _.get(atlas, "variant");
                        name += ` - ${variant}`;
                      }

                      let index = componentSizes.indexOf((atlasSize) => atlasSize.name === name);
                      if (index !== -1) {
                        const atlasSize = componentSizes.filter((atlasSize) => atlasSize.name == name)[0];

                        //add old size and new one to find total size
                        _.set(componentSizes[index], "size", atlasSize.size + blob.size);
                      } else {
                        componentSizes.push({
                          id: componentSizes.length,
                          name: name,
                          size: size,
                          type: "atlas",
                        });
                      }
                      totalSizeResult += size;
                    });
                  promises.push(atlasPromise);
                });
              });
            }
            if (_.has(component, "selectedSounds")) {
              let selectedSounds = _.get(component, "selectedSounds");
              Object.entries(selectedSounds).forEach(([id, sounds]) => {
                sounds.forEach((sound) => {
                  let soundPromise = fetch(`sounds/${sound.file}`)
                    .then((response) => response.blob())
                    .then((blob) => {
                      let name = `${componentId} - ${id}`;
                      let size = blob.size * base64SizeMultiplier;

                      if (_.has(sound, "variant")) {
                        let variant = _.get(sound, "variant");
                        name += ` - ${variant}`;
                      }

                      let index = componentSizes.indexOf((soundSize) => soundSize.name === name);
                      if (index !== -1) {
                        const soundSize = componentSizes.filter((soundSize) => soundSize.name == name)[0];

                        //add old size and new one to find total size
                        _.set(componentSizes[index], "size", soundSize.size + blob.size);
                      } else {
                        componentSizes.push({
                          id: componentSizes.length,
                          name: name,
                          size: size,
                          type: "sound",
                        });
                      }
                      totalSizeResult += size;
                    });
                  promises.push(soundPromise);
                });
              });
            }
            if (_.has(component, "selectedFonts")) {
              let selectedFonts = _.get(component, "selectedFonts");
              Object.entries(selectedFonts).forEach(([id, fonts]) => {
                fonts.forEach((font) => {
                  let fontPromise = fetch(`fonts/${font.file}`)
                    .then((response) => response.blob())
                    .then((blob) => {
                      let name = `${componentId} - ${id}`;
                      let size = blob.size * base64SizeMultiplier;

                      if (_.has(font, "variant")) {
                        let variant = _.get(font, "variant");
                        name += ` - ${variant}`;
                      }

                      let index = componentSizes.indexOf((fontSize) => fontSize.name === name);
                      if (index !== -1) {
                        const fontSize = componentSizes.filter((fontSize) => fontSize.name == name)[0];

                        //add old size and new one to find total size
                        _.set(componentSizes[index], "size", fontSize.size + blob.size);
                      } else {
                        componentSizes.push({
                          id: componentSizes.length,
                          name: name,
                          size: size,
                          type: "font",
                        });
                      }
                      totalSizeResult += size;
                    });

                  promises.push(fontPromise);
                });
              });
            }
            if (_.has(component, "selectedVideos")) {
              let selectedVideos = _.get(component, "selectedVideos");
              Object.entries(selectedVideos).forEach(([id, videos]) => {
                videos.forEach((video) => {
                  if (video.variant === "path") {
                    let videoPromise = fetch(`videos/${video.file}`)
                      .then((response) => response.blob())
                      .then((blob) => {
                        let name = `${componentId} - ${id}`;
                        let size = blob.size * base64SizeMultiplier;

                        if (_.has(video, "variant")) {
                          let variant = _.get(video, "variant");
                          name += ` - ${variant}`;
                        }

                        let index = componentSizes.indexOf((videoSize) => videoSize.name === name);
                        if (index !== -1) {
                          const videoSize = componentSizes.filter((videoSize) => videoSize.name == name)[0];

                          //add old size and new one to find total size
                          _.set(componentSizes[index], "size", videoSize.size + blob.size);
                        } else {
                          componentSizes.push({
                            id: componentSizes.length,
                            name: name,
                            size: size,
                            type: "video",
                          });
                        }
                        totalSizeResult += size;
                      });

                    promises.push(videoPromise);
                  } else if (video.variant === "url") {
                    let name = `${componentId} - ${id}`;
                    let size = null;

                    if (_.has(video, "variant")) {
                      let variant = _.get(video, "variant");
                      name += ` - ${variant}`;
                    }

                    componentSizes.push({
                      id: componentSizes.length,
                      name: name,
                      size: size,
                      type: "external video",
                    });
                  }
                });
              });
            }
          }
        });
        await Promise.all(promises);

        let defaultPlayableSize;
        if (_.isNil(props.selectedBase.defaultPlayableSizeInMb)) {
          //adding default playable size which is 1.4 mb
          defaultPlayableSize = 1.4 * 1024 * 1024;
        } else {
          //adding default playable size from selected base.
          defaultPlayableSize = props.selectedBase.defaultPlayableSizeInMb * 1024 * 1024;
        }
        componentSizes.push({
          id: componentSizes.length,
          name: "Default playable size",
          size: defaultPlayableSize,
          type: "default",
        });
        totalSizeResult += defaultPlayableSize;

        allVersionSizes.push({ versionID: version._id, size: totalSizeResult });
      }
    }
    getSizes();
  }

  function getCurrentVersionSize() {
    const size = versionSizes.filter(
      (versionSizeInfo) => versionSizeInfo.versionID == selectedVersions[versionIndex]
    )[0]?.size;

    const calculatedSize = (size / 1024 / 1024).toFixed(2);

    return "Estimated Size : " + calculatedSize + " MB";
  }

  function changeNetwork(newValue) {
    let selectedVersionsInfo = [...selectedVersionsInfos];

    const currentVersionSelectedNetworks = selectedVersionsInfo.filter(
      (version) => version.versionID == selectedVersions[versionIndex]
    )[0];

    const newTotalNetwork = new Set(currentVersionSelectedNetworks?.networks);

    newValue.forEach((value) => {
      if (!newTotalNetwork.has(value)) {
        newTotalNetwork.add(value);
      }
    });

    const difference = [...newTotalNetwork].filter((network) => !newValue.some((value) => value === network))[0];

    newTotalNetwork.delete(difference);

    for (let i = 0; i < selectedVersionsInfo.length; i++) {
      if (selectedVersionsInfo[i].versionID == selectedVersions[versionIndex]) {
        const selectedVersion = {
          versionID: selectedVersions[versionIndex],
          languages: selectedVersionsInfo[i]?.languages,
          networks: Array.from(newTotalNetwork),
        };

        selectedVersionsInfo[i] = selectedVersion;
      }
    }

    setSelectedVersionsInfos(selectedVersionsInfo);

    getHtmlCount(selectedVersionsInfo);
  }

  function selectedVersionNetworksFunction() {
    let networkArray = [];

    networkViews.map((network) => {
      networkArray.push({
        "caption": network.caption,
        "name": network.name,
      });
    });

    return networkArray;
  }

  function getSelectedVersion() {
    const selectedVersion = selectedVersions[versionIndex];

    const selectedVersionInfo = versionNames.filter((version) => version.key == selectedVersion)[0];

    setCurrentVersion(selectedVersionInfo);
  }

  function fetchCompany(companyId) {
    return new Promise((resolve, reject) => {
      fetch(getServerUri("company/findOne"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          companyID: companyId,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((company) => {
                props.setUserCompany(company);
                resolve(company);
              })
              .catch(() => reject);
          } else {
            response
              .text()
              .then(() => reject)
              .catch(() => reject);
          }
        })
        .catch((err) => {
          reject;
          console.log("error", err);
        });
    });
  }

  function defaultLanguage() {
    const languages = selectedVersionsInfos.filter(
      (selectedVersionInfo) => selectedVersionInfo.versionID == selectedVersions[versionIndex]
    )[0]?.languages;
    const languageInfo = Object.entries(props.selectedBase.supportedLanguages).filter(
      ([key]) => languages[0] == key
    )[0];

    return languageInfo ? languageInfo[1] : "";
  }

  function selectedVersionLanguagesFunction() {
    let newSelectedLanguages = [];

    const selectedVersionInfo = props.versions.filter((version) => version?._id == selectedVersions[versionIndex])[0];

    const selectedVersionLanguages = selectedVersionInfo?.createdLanguages;

    if (selectedVersionLanguages && selectedVersionLanguages.length > 0) {
      selectedVersionLanguages.forEach((languageKey) => {
        const languageInfo = Object.entries(props.selectedBase.supportedLanguages).filter(
          ([key]) => languageKey == key
        )[0];

        newSelectedLanguages.push({ key: languageInfo[0], title: languageInfo[1] });
      });
    }

    return newSelectedLanguages;
  }

  function selectLanguages(newValue) {
    let selectedVersionsInfo = [...selectedVersionsInfos];

    const currentVersionSelectedLanguages = selectedVersionsInfo.filter(
      (version) => version.versionID == selectedVersions[versionIndex]
    )[0]?.languages;
    const newSelectedLanguagesSet = new Set(currentVersionSelectedLanguages);

    let newSelectedLanguages = [];
    newValue.forEach((value) => {
      if (!newSelectedLanguagesSet.has(value)) {
        newSelectedLanguagesSet.add(value);
      }

      newSelectedLanguages.push(value);
    });

    for (let item in newSelectedLanguagesSet) {
      const hasIncludeItem = newValue.filter((value) => value != item).length;
      if (hasIncludeItem == 0) {
        newSelectedLanguagesSet.delete(item);
      }
    }

    setSelectedLanguagesSet(newSelectedLanguagesSet);

    for (let i = 0; i < selectedVersionsInfo.length; i++) {
      if (selectedVersionsInfo[i].versionID == selectedVersions[versionIndex]) {
        const newSelectedVersion = {
          versionID: selectedVersions[versionIndex],
          languages: newSelectedLanguages,
          networks: selectedVersionsInfo[i]?.networks,
        };

        selectedVersionsInfo[i] = newSelectedVersion;
      }
    }

    setSelectedVersionsInfos(selectedVersionsInfo);

    getHtmlCount(selectedVersionsInfo);
  }

  function getCurrentSelectedVersionNetworkInfos() {
    let selectedVersionInfo = [...selectedVersionsInfos];

    const currentVersionSelectedNetworks = selectedVersionInfo?.filter(
      (version) => version.versionID == selectedVersions[versionIndex]
    )[0]?.networks;

    let newSelectedNetworks = [];
    currentVersionSelectedNetworks?.forEach((networkKey) => {
      const networkInfo = networkViews.filter((network) => networkKey == network.name)[0];

      newSelectedNetworks.push(networkInfo.name);
    });

    return newSelectedNetworks;
  }

  function getCurrentSelectedVersionLanguageInfos() {
    let selectedVersionsInfo = [...selectedVersionsInfos];

    const currentVersionSelectedLanguages = selectedVersionsInfo.filter(
      (version) => version.versionID == selectedVersions[versionIndex]
    )[0]?.languages;

    if (!languagesLoaded) {
      if (currentVersionSelectedLanguages && currentVersionSelectedLanguages.length > 0) {
        setLanguagesLoaded(true);
      }
    }

    return currentVersionSelectedLanguages ? currentVersionSelectedLanguages : [];
  }

  function getHtmlCount(selectedVersionsInfo) {
    let totalHtmlCount = 0;

    selectedVersionsInfo.forEach((version) => {
      const languages = version.languages?.length;
      const networks = version.networks?.length;

      totalHtmlCount += languages * networks;
    });

    setHtmlCount(totalHtmlCount);

    getCreditLeft(totalHtmlCount);
  }

  function getCreditLeft(totalHtmlCount) {
    setCreditLeft(currentPlayableCount - totalHtmlCount);
  }

  function downloadGame() {
    let downloadingMonthlyCreatedPlayableCount = 0;
    if (selectedVersionsInfos.length == 1) {
      downloadingMonthlyCreatedPlayableCount =
        selectedVersionsInfos[0].networks.length * selectedVersionsInfos[0].languages.length;
    } else {
      selectedVersionsInfos.forEach((version) => {
        downloadingMonthlyCreatedPlayableCount += version.networks.length * version.languages.length;
      });
    }

    const downloadedHtmlLimit = props.userCompany.downloadHtmlLimit;

    if (downloadingMonthlyCreatedPlayableCount > downloadedHtmlLimit) {
      props.createSnackbar(`Downloadable html limit is ${downloadedHtmlLimit}`, "warning");
      return;
    }

    if (
      props.userCompany.subscription.periods[props.userCompany.subscription.periods.length - 1]
        .monthlyCreatedPlayables +
        downloadingMonthlyCreatedPlayableCount >
      props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfPlayables
    ) {
      props.createSnackbar(
        `Your company's current plan allows up to ${
          props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfPlayables
        } playables`,
        "warning"
      );
      return;
    }

    let networkErrorArray = [];
    let languageErrorArray = [];
    selectedVersionsInfos.forEach((selectedVersionInfo) => {
      if (selectedVersionInfo.networks.length == 0) {
        const versionName = props.versions.filter((version) => version._id == selectedVersionInfo.versionID)[0]?.title;
        networkErrorArray.push(versionName);
      }
      if (selectedVersionInfo.languages.length == 0) {
        const versionName = props.versions.filter((version) => version._id == selectedVersionInfo.versionID)[0]?.title;
        languageErrorArray.push(versionName);
      }
    });

    const hasNetworksEmpty = selectedVersionsInfos.some((version) => version.networks.length == 0);
    const hasLanguagesEmpty = selectedVersionsInfos.some((version) => version.languages.length == 0);

    if (hasNetworksEmpty) {
      setError("Select network for all versions");
      return;
    }
    if (hasLanguagesEmpty) {
      setError("No languages were selected.");
      return;
    }
    setError(null);
    setIsBuilding(true);
    setButtonText("Building...");
    console.info("sending download request");

    props.setIsDownloadingPlayable(true);

    downloadPlayableOrdered(0);
  }

  function resetDownloadVersionSettings() {
    setSelectedVersions([props.selectedVersion?._id]);
    setCurrentVersion({ versionName: props.selectedVersion?.title, key: props.selectedVersion?._id });
    setVersionIndex(0);
    const selectedVersionInfoUpdate = [{ versionID: props.selectedVersion?._id, languages: ["en"], networks: [] }];
    setDownloadedVersionCount(1);
    setSelectedVersionsInfos(selectedVersionInfoUpdate);
    setHtmlCount(0);
  }

  function arrowButtonsVisibility(direction) {
    if (direction == "left") {
      return versionIndex > 0;
    } else {
      return versionIndex < selectedVersions.length - 1;
    }
  }

  function downloadPlayableOrdered(index) {
    const selectedVersionInfo = selectedVersionsInfos[index];

    if (_.isNil(selectedVersionInfo) || selectedVersionInfo == undefined) {
      return;
    }
    setDownloadedVersionCount(downloadedVersionCount++);

    setProgressCompleted(false);

    let networkArray = selectedVersionInfo.networks; //Array.from(totalSet);
    console.info("networks: " + JSON.stringify(networkArray));
    fetch(getServerUri("version/downloadGame"), {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        versionID: String(selectedVersionInfo.versionID),
        networks: networkArray,
        languages: selectedVersionInfo.languages,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((object) => {
              if (_.has(object, "completion")) {
                console.info("Progress Received: ", object);
              } else if (_.has(object, "file")) {
                setButtonText("Download");

                if (selectedVersionsInfos[selectedVersionsInfos.length - 1] == selectedVersionInfo) {
                  setIsBuilding(false);
                  props.setIsDownloadingPlayable(false);
                  props.setIsDownloadDialogOpen(false);
                  resetDownloadVersionSettings();
                }
                setProgressCompleted(true);

                updatePlayableCounterWithOneVersion(selectedVersionInfo, () => {
                  window.location = object.file;

                  setTimeout(() => {
                    downloadPlayableOrdered(index + 1);
                  }, 100);
                }).catch((err) => {
                  console.log(err);
                });
              }
            })
            .catch((err) => {
              props.setIsDownloadingPlayable(false);
              setError("Server did not process the request");
              setError(err);
              console.log(err);
            });
        } else {
          props.setIsDownloadingPlayable(false);
          response
            .json()
            .then((object) => {
              setError("Server did not process the request");
              console.log("non 200 response:");
              console.dir(object);

              resetDownloadVersionSettings();
            })
            .catch(() => {
              console.log("can't parse response as json");
            });
        }
      })
      .catch((err) => {
        props.setIsDownloadingPlayable(false);
        setError("Could not send request to server");
        console.error("FETCH ERROR");
        console.error(err);
      });
  }

  function getCurrentPlayableCount(userCompany) {
    const monthlyPlayableCount = _.get(
      props.plans[props.getPlanIndex(_.get(props, "userCompany.subscription.currentPlan"))],
      "numberOfPlayables"
    );
    let monthlyCreatedPlayables = _.get(
      userCompany?.subscription.periods[userCompany?.subscription.periods.length - 1],
      "monthlyCreatedPlayables"
    );

    const currentPlayableLimit = monthlyPlayableCount - monthlyCreatedPlayables;

    setCurrentPlayableCount(currentPlayableLimit);
    setCreditLeft(currentPlayableLimit);
  }

  function updatePlayableCounterWithOneVersion(selectedVersionInfo, onCompleteCallback) {
    return fetchCompany(_.get(props.userCompany, "_id")).then((company) => {
      const newCompany = _.cloneDeep(company);

      let networksSize = selectedVersionInfo.networks?.length;
      let selectedLanguagesSize = selectedVersionInfo.languages?.length;

      let oldVal = _.get(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "monthlyCreatedPlayables"
      );

      if (_.isNil(oldVal)) oldVal = 0;

      _.set(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "monthlyCreatedPlayables",
        parseInt(oldVal) + networksSize * selectedLanguagesSize
      );

      const fetchUri = getServerUri("company/updateOne");
      const fetchInit = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          companyID: String(_.get(newCompany, "_id")),
          subscription: JSON.stringify(_.get(newCompany, "subscription")),
          //testPlayableCount: _.get(newCompany, "testPlayableCount")
        }),
      };
      fetch(fetchUri, fetchInit)
        .then((response) => {
          if (response.status === 200) {
            props.setUserCompany(newCompany);

            if (selectedVersionsInfos[selectedVersionsInfos.length - 1] == selectedVersionInfo) {
              getCurrentPlayableCount(newCompany);
            }

            onCompleteCallback();
            //console.debug("createdPlayables updated");
          } else {
            response
              .text()
              .then((textResponse) => {
                console.log("createdPlayables update failed: " + textResponse);
              })
              .catch(console.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }

  function getSelectedVersionName() {
    if (props.versionRename !== "") {
      return props.versionRename;
    } else {
      return versionNames.filter((versionName) => selectedVersions[versionIndex] == versionName.key)[0]?.versionName;
    }
  }

  function getNetworkName() {
    const networks = selectedVersionsInfos.filter(
      (selectedVersionInfo) => selectedVersionInfo.versionID == selectedVersions[versionIndex]
    )[0]?.networks;
    const networkInfo = networkViews.filter((network) => networks[0] == network.name)[0];

    return networkInfo?.caption;
  }

  function calculateSelectedVersionSize(selectedVersionInfos) {
    const newVersionSizes = [...versionSizes];

    selectedVersionInfos.forEach((selectedVersion) => {
      const isSelectedVersionSizeCalculated = newVersionSizes.some(
        (versionSize) => versionSize.versionID === selectedVersion
      );

      if (!isSelectedVersionSizeCalculated) {
        const versionInfo = props.versions.filter((version) => version._id === selectedVersion)[0];

        getVersionSizes(versionInfo, newVersionSizes);
        setVersionSizes(newVersionSizes);
      }
    });
  }

  if (props.selectedBase == null) {
    window.location.reload();
  }
  if (!props.selectedVersion === undefined) return null;
  return (
    <Dialog open={props.isOpen} onClose={props.onClose}>
      <StyledDialogTitle>DOWNLOAD CREATIVE</StyledDialogTitle>

      {!loadingCompleted && (
        <div className={classes.loadingIcon}>
          <CircularProgress
            color={"secondary"}
            style={{ textAlign: "center", width: "50px", height: "50px" }}
          ></CircularProgress>
          <Typography style={{ textAlign: "center", marginTop: "20px", marginLeft: "10px" }}>{"Loading..."}</Typography>
        </div>
      )}
      {loadingCompleted && (
        <div>
          {loadingCompleted}
          <Card className={classes.downloadDialogCard}>
            <CardContent style={{ marginTop: "10px" }}>
              <InputLabel id="select-version-label" className={classes.inputLabel}>
                Select Version
              </InputLabel>

              <Select
                multiple
                labelId="select-version-label"
                IconComponent={StyledDopDownIcon}
                input={<Input />}
                className={classes.versionSelector}
                MenuProps={MenuProps}
                renderValue={() => {
                  if (selectedVersions.length === 1) {
                    return getSelectedVersionName();
                  } else if (selectedVersions.length > 1) {
                    return "Multiple Versions";
                  } else if (selectedVersions.length === 0) {
                    return "Select Versions";
                  }
                }}
                onChange={(event) => {
                  setSelectedVersions(event.target.value);
                  calculateSelectedVersionSize(event.target.value);
                  event.target.value.forEach((value) => {
                    let newSelectedVersions = [...selectedVersionsInfos];

                    const getCurrentSelectedVersionInfo = newSelectedVersions.filter(
                      (version) => version.versionID == value
                    );

                    if (getCurrentSelectedVersionInfo.length == 0) {
                      newSelectedVersions.push({ versionID: value, languages: ["en"], networks: [] });
                      setSelectedVersionsInfos(newSelectedVersions);
                    }
                  });
                }}
                value={selectedVersions}
              >
                {versionNames.map((versionInfo) => {
                  const versionName = versionInfo.versionName;
                  const key = versionInfo.key;
                  return (
                    <MenuItem key={key} value={key}>
                      <Checkbox checked={selectedVersions.indexOf(key) > -1} />
                      <ListItemText primary={versionName} />
                    </MenuItem>
                  );
                })}
              </Select>
            </CardContent>
          </Card>
          <Card className={classes.downloadDialogCard}>
            <Typography
              style={{
                width: "440px",
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Helvetica",
                marginTop: "5px",
              }}
            >
              {"Selected Version"}
            </Typography>

            <CardContent
              style={{
                textAlign: "center",
                margin: "20px 0px 20px 0px", //top, right, bottom, left
                padding: 0,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  width: "57.5px",
                  height: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  name="leftIcon"
                  style={{ display: arrowButtonsVisibility("left") ? "flex" : "none", padding: 0 }}
                  onClick={() => {
                    setVersionIndex(versionIndex - 1);
                  }}
                >
                  <ArrowLeftRoundedIcon fontSize="large" style={{ width: "55px", height: "55px" }} />
                </IconButton>
              </div>
              <div
                style={{
                  width: "325px",
                  height: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Tooltip title={getSelectedVersionName() || ""} aria-label="versionName">
                  <StyledChip label={getSelectedVersionName()} variant="outlined" />
                </Tooltip>
              </div>
              <div
                style={{
                  width: "57.5px",
                  height: "70px",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  name="rightIcon"
                  style={{ display: arrowButtonsVisibility("right") ? "flex" : "none", padding: 0 }}
                  onClick={() => {
                    setVersionIndex(versionIndex + 1);
                  }}
                >
                  <ArrowRightRoundedIcon fontSize="large" style={{ width: "55px", height: "55px" }} />
                </IconButton>
              </div>
            </CardContent>

            <ThemeProvider theme={darkTheme}>
              <CardContent
                style={{
                  marginBottom: 15,
                }}
              >
                <InputLabel id="select-language-label" className={classes.inputLabel}>
                  Select Language
                </InputLabel>

                <Select
                  multiple
                  labelId="select-language-label"
                  IconComponent={StyledDopDownIcon}
                  input={<Input />}
                  className={classes.versionSelector}
                  renderValue={() => {
                    const currentSelectedVersionLanguages = getCurrentSelectedVersionLanguageInfos();
                    if (currentSelectedVersionLanguages.length === 1) {
                      return defaultLanguage();
                    } else if (currentSelectedVersionLanguages.length > 1) {
                      const selectedVersionLanguages = selectedVersionLanguagesFunction();
                      let languages = "";

                      currentSelectedVersionLanguages.forEach((language) => {
                        const languageCaption = selectedVersionLanguages.filter(
                          (versionLanguage) => versionLanguage.key === language
                        )[0]?.title;

                        languages += languageCaption + ", ";
                      });

                      return languages;
                    } else if (currentSelectedVersionLanguages.length === 0) {
                      return "Select Languages";
                    }
                  }}
                  onChange={(event) => {
                    selectLanguages(event.target.value);
                  }}
                  value={getCurrentSelectedVersionLanguageInfos()}
                >
                  <MenuItem key="all" value="all">
                    <Checkbox
                      checked={allLanguagesSelected}
                      onChange={(event) => {
                        if (event.target.checked === true) {
                          const allLanguages = selectedVersionLanguagesFunction().map((language) => language.key);
                          selectLanguages(allLanguages);
                          setAllLanguagesSelected(true);
                        } else if (event.target.checked === false) {
                          selectLanguages([]);
                          setAllLanguagesSelected(false);
                        }
                      }}
                    />
                    <ListItemText primary={"All Languages"} />
                  </MenuItem>
                  {selectedVersionLanguagesFunction().map((selectedLanguage) => {
                    const languageName = selectedLanguage.title;
                    const key = selectedLanguage.key;
                    return (
                      <MenuItem key={key} value={key}>
                        <Checkbox checked={getCurrentSelectedVersionLanguageInfos().indexOf(key) > -1} />
                        <ListItemText primary={languageName} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </CardContent>
            </ThemeProvider>
            <ThemeProvider theme={darkTheme}>
              <CardContent>
                <InputLabel id="select-network-label" className={classes.inputLabel}>
                  Select Network
                </InputLabel>
                <Select
                  multiple
                  labelId="select-network-label"
                  IconComponent={StyledDopDownIcon}
                  input={<Input />}
                  className={classes.versionSelector}
                  label="Select Network"
                  renderValue={() => {
                    const currentSelectedNetworks = getCurrentSelectedVersionNetworkInfos();
                    if (currentSelectedNetworks.length === 1) {
                      return getNetworkName();
                    } else if (currentSelectedNetworks.length > 1) {
                      const selectedVersionNetworks = selectedVersionNetworksFunction();
                      let networks = "";

                      currentSelectedNetworks.forEach((network) => {
                        const networkCaption = selectedVersionNetworks.filter(
                          (versionNetwork) => versionNetwork.name === network
                        )[0]?.caption;

                        networks += networkCaption + ", ";
                      });

                      return networks;
                    } else if (currentSelectedNetworks.length === 0) {
                      return "Select Network";
                    }
                  }}
                  onChange={(event) => {
                    changeNetwork(event.target.value);
                  }}
                  value={getCurrentSelectedVersionNetworkInfos()}
                >
                  {networkViews.map((selectedNetwork) => {
                    const networkName = selectedNetwork.caption;
                    const key = selectedNetwork.name;
                    return (
                      <MenuItem key={key} value={key}>
                        <Checkbox checked={getCurrentSelectedVersionNetworkInfos().indexOf(key) > -1} />
                        <ListItemText primary={networkName} />
                      </MenuItem>
                    );
                  })}
                </Select>
              </CardContent>
            </ThemeProvider>
            <CardContent
              style={{
                textAlign: "center",
                margin: 0,
                padding: 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  textOverflow: "ellipsis",
                  width: "400px",
                  overflow: "hidden",
                  textAlign: "right",
                  fontFamily: "Helvetica",
                  marginBottom: "5px",
                }}
              >
                {getCurrentVersionSize()}
              </Typography>
            </CardContent>
          </Card>

          <Card className={classes.downloadDialogCard}>
            <CardContent
              style={{
                textAlign: "center",
                margin: 0,
                padding: 5,
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                lineHeight: "0.75",
              }}
            >
              <div className={classes.htmlCreditTypography}>
                <Typography style={{ textAlign: "left", marginLeft: "10px", color: "#9bcec0" }}>
                  {"Current Credit"}
                </Typography>
              </div>
              <div className={classes.htmlCreditTypography}>
                <Typography style={{ textAlign: "center", color: "#9bcec0" }}>{"Html Count"}</Typography>
              </div>
              <div className={classes.htmlCreditTypography}>
                <Typography style={{ textAlign: "right", marginRight: "10px", color: "#9bcec0" }}>
                  {"Credit Left"}
                </Typography>
              </div>
            </CardContent>

            <CardContent
              style={{
                textAlign: "center",
                margin: 0,
                marginTop: "-10px",
                display: "flex",
                padding: 5,
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                lineHeight: "0.75",
              }}
            >
              <div className={classes.htmlCreditTypography}>
                <Typography style={{ textAlign: "left", marginLeft: "10px" }}>{currentPlayableCount}</Typography>
              </div>
              <div className={classes.htmlCreditTypography}>
                <Typography style={{ textAlign: "center" }}>{htmlCount}</Typography>
              </div>
              <div className={classes.htmlCreditTypography}>
                <Typography style={{ textAlign: "right", marginRight: "10px" }}>{creditLeft}</Typography>
              </div>
            </CardContent>
          </Card>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "25px",
              marginTop: "9px",
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
              }}
            >
              <CircularProgress
                color={"secondary"}
                style={{ textAlign: "center", display: isBuilding ? "flex" : "none" }}
              ></CircularProgress>
              <Typography
                style={{
                  textAlign: "center",
                  display: isBuilding ? "flex" : "none",
                  marginTop: "8px",
                  marginLeft: "10px",
                }}
              >
                {getDownloadedVersionCount() + "/" + selectedVersionsInfos.length}
              </Typography>
            </div>

            <StyledButton
              name="download"
              disabled={isBuilding}
              style={{ display: !isBuilding ? "flex" : "none" }}
              onClick={downloadGame}
            >
              <DownloadIcon style={{ width: "35px", height: "50px", marginRight: "5px" }} />
              {buttonText}
            </StyledButton>
          </div>

          <Typography style={{ textAlign: "center" }}>{error}</Typography>
        </div>
      )}
    </Dialog>
  );
}
