import React, { useEffect, useState, useRef } from "react";
import {Typography, MenuItem, Select, IconButton, Button, CircularProgress} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { Remove, Close, Visibility } from "@material-ui/icons";

import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";
import { Dialog, DialogTitle, DialogContent } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DialogImageDelete from "./dialogImageDelete";
import ImagePreview from "./ImagePreviewComponent";
import ReactGA4 from "react-ga4";
import Tooltip from "@mui/material/Tooltip";
ReactGA4.initialize("G-TB46JQ70PR", {
  debug: false,
  gaOptions: {
    userId: localStorage.getItem("user.id"),
  },
});
const useStyles = makeStyles({
  iconButton: {
    padding: 0,
  },

  container: {
    display: "flex",
    alignItems: "center",
    margin: "60px",
    flexDirection: "column",
    maxHeight: "800px",
    "@media (min-width: 780px)": {
      flexDirection: "row",
    },
  },
});

/**
 *
 * @param props
 * @param props.images
 * @param props.selectedImage
 * @param props.classes
 * @param props.setValue
 * @param props.path
 * @param props.destination
 * @param props.bucket
 * @param props.componentType
 * @param props.insertMediaPathValue
 * @param props.componentID
 * @param props.selectedBase
 * @param props.createSnackbar
 * @param props.deleteImageFromMedia
 * @param props.fetchVersions
 * @param props.fetchImages
 * @param props.setIsDialogConfirmRefreshOpen
 * @param props.setIsDialogCircularProgressOpen
 * @returns {JSX.Element|null}
 */
export default function selectorImage(props) {
  const [images, setImages] = useState(imagesToView(_.get(props, "images", [])));
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [newImageSrc, setNewImageSrc] = useState(null);
  const [fileOrginalSize, setOriginalSize] = useState(null);
  const [fileOptimizedSize, setOptimizedSize] = useState(null);
  const [imageLength, setImageLength] = useState(null);
  const [sliderValue, setSliderValue] = useState(0);
  const [compressionRate, setCompressionRate] = useState(0);
  const [previousValue, setPreviousValue] = useState(0); // Önceki değeri saklamak için bir state tanımlandı
  const [circularProgress, setCircularProgress] = useState(false);
  const [key, setKey] = useState(Date.now()); // zaman damgası kullanarak anahtar oluşturuldu
  const [compressText, setCompressText] = useState("Image is already compressed"); // zaman damgası kullanarak anahtar oluşturuldu
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const [selectedImageID, setSelectedImageID] = useState(
    isSelectionValid(_.get(props, "selectedImage._id"))
      ? _.get(props, "selectedImage._id")
      : String(_.get(images, `[0]._id`, ``))
  );
  const [isDeleteImageDialogOpen, setIsDeleteImageDialogOpen] = useState(false);
  const [imageToDelete, setImageToDelete] = useState(null);

  const defaultImageID = String(_.get(images, `[0]._id`, ``));

  const classes = useStyles();
  // const canvasRef = useRef(null);
  useEffect(() => {
    if (isSelectionValid(_.get(props, "selectedImage._id", ""))) {
      setSelectedImageID(_.get(props, "selectedImage._id", ""));
    } else if (isSelectionValid(defaultImageID)) {
      selectImage(defaultImageID);
    }
  }, [_.get(props, "selectedVersion")]);

  function imagesToView(images) {
    return images.filter((image) => {
      return _.get(image, "bucket") === _.get(props, "bucket");
    });
  }
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }

  let fileSelectors = [];
  let [uploadStatus, setUploadStatus] = useState("ready");


  useEffect(() => {
    setImages(imagesToView(_.get(props, "images", [])));
  }, [_.get(props, "images", []), _.size(props.images)]);
  function isSelectionValid(id, newImages) {
    let images2;
    if (_.isNil(newImages)) {
      images2 = images;
    } else {
      images2 = newImages;
    }
    const foundImages = images2.filter((image) => {
      return String(image._id) === String(id);
    });
    return foundImages.length > 0 || id === "";
  }

  const inputID = `${_.get(props, "componentID")}-${_.get(props, "path")}`;

  //Convert the base64-encoded image data to binary
  function webpToFile(webp) {
    const parts = webp.split(",");
    const mime = parts[0].split(":")[1].split(";")[0];
    const bstr = atob(parts[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return u8arr;
  }
  function selectImage(id, newImages) {
    if (!isSelectionValid(id, newImages)) return;
    setSelectedImageID(id);
    let images2;
    if (_.isNil(newImages)) {
      images2 = images;
    } else {
      images2 = newImages;
    }
    const selectedImageRecord = images2.filter((imageRecord) => {
      return String(imageRecord._id) === String(id);
    })[0];
    const newSelectedImageRecord = _.clone(selectedImageRecord);
    _.set(newSelectedImageRecord, "destination", _.get(props, "destination"));
    props.setValue({
      value: [newSelectedImageRecord],
    });
  }
  //it draws a checkerboard pattern on the background.
  const createPatternCanvas = () => {
    const patternCanvas = document.createElement("canvas");
    const patternCtx = patternCanvas.getContext("2d");
    const size = 8;
    patternCanvas.width = size * 2;
    patternCanvas.height = size * 2;
    // Dama deseni oluşturuluyor
    patternCtx.fillStyle = "#bfbfbf";
    patternCtx.fillRect(0, 0, size, size);
    patternCtx.fillRect(size, size, size, size);
    patternCtx.fillStyle = "#fefefe";
    patternCtx.fillRect(size, 0, size, size);
    patternCtx.fillRect(0, size, size, size);

    return patternCanvas;
  };
  function setInputFileListEmpty(inputId) {
    let list = new DataTransfer();
    document.getElementById(inputId).files = list.files;
  }
  function findBase64(base64String) {
    var stringLength = base64String.length - "data:image/png;base64,".length;

    var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    var sizeInKb = sizeInBytes / 1000;
    return sizeInKb;
  }
  //find the compress and return size
  function CompressAndReturnSize(cnvs, file, scale) {
    let webp = null;
    let safariAgent = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (file == "image/png" || file.type == "image/jpeg") {
      if (safariAgent) {
        webp = cnvs.toDataURL("image/jpeg", scale);
      } else {
        webp = cnvs.toDataURL("image/webp", scale);
      }
    } else {
      webp = cnvs.toDataURL("image/jpeg", scale);
    }
    let base64String = findBase64(webp);
    return base64String;
  }
  // function to find the compression ratio
  function GetCompressionRate(cnvs, file, currentValue, currentScale, target, prevScale, counter = 0) {
    let scaleToGo = 0;
    let finalScale;

    if (counter >= 10) {
      if (currentValue < 7) {
        currentScale = -1;
      }

      return currentScale;
    }

    if (prevScale == 0) {
      scaleToGo = currentScale / 2;
      let newSize = CompressAndReturnSize(cnvs, file, scaleToGo);

      prevScale = currentScale;
      finalScale = GetCompressionRate(cnvs, file, newSize, scaleToGo, target, prevScale, counter + 1);
    } else {
      if (currentValue > target) {
        // current buyuk
        scaleToGo = currentScale - Math.abs(-currentScale + prevScale) / 2;
        let newSize = CompressAndReturnSize(cnvs, file, scaleToGo);

        prevScale = currentScale;
        finalScale = GetCompressionRate(cnvs, file, newSize, scaleToGo, target, prevScale, counter + 1);
      } else {
        //current küçük
        if (target - currentValue > 10) {
          scaleToGo = currentScale + Math.abs(-currentScale + prevScale) / 2;
          let newSize = CompressAndReturnSize(cnvs, file, scaleToGo);

          prevScale = currentScale;
          finalScale = GetCompressionRate(cnvs, file, newSize, scaleToGo, target, prevScale, counter + 1);
        } else {
          return currentScale;
        }
      }
    }

    return finalScale;
  }
  //It is used to find the amount of compression of the image at the time it is loaded.
  function CompressImage(file) {
    setCompressText(" ");
    const imgResized = new Image();
    imgResized.src = URL.createObjectURL(file);
    URL.revokeObjectURL(URL.createObjectURL(file));
    //for retina displays on mac computer

    let safariAgent = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    imgResized.onload = () => {
      const cnvs = document.createElement("canvas");
      cnvs.width = imgResized.width;
      cnvs.height = imgResized.height;
      const ctx = cnvs.getContext("2d");
      ctx.drawImage(imgResized, 0, 0, imgResized.width, imgResized.height);
      let webp = null;
      let webpFile;
      if (safariAgent && file.type === "image/png") {
        webp = file;
        webpFile = webp;
        setCompressText(
          "⚠️ Png compress is not supported in Safari.\n You may still upload uncompressed png or Use Chrome. "
        );
        setSliderValue(0);
      } else {
        if (safariAgent) {
          webp = cnvs.toDataURL("image/jpeg", 0.99);
        } else {
          webp = cnvs.toDataURL("image/webp", 0.99);
        }
        webpFile = new File([webpToFile(webp)], file.type, { type: file.type });
        let base64String = findBase64(webp);
        let finalCompressedRatio = 0;
        if (base64String > file.size / 1024) {
          let target = file.size / 1024;
          let initial = base64String;
          let prevScale = 0;

          finalCompressedRatio = GetCompressionRate(cnvs, file.type, initial, 1, target, prevScale);
          setCompressionRate(finalCompressedRatio);
          console.log("finalCompress", finalCompressedRatio);
          if (finalCompressedRatio === -1) {
            setSliderValue(0);
            setCompressText("Image is already compressed");
          } else {
            if (safariAgent) {
              webp = cnvs.toDataURL("image/jpeg", 1 - (1 - finalCompressedRatio + (finalCompressedRatio * 1) / 10));
            } else {
              webp = cnvs.toDataURL("image/webp", 1 - (1 - finalCompressedRatio + (finalCompressedRatio * 1) / 10));
            }

            base64String = findBase64(webp);
            if (base64String.toFixed(2) > (file.size / 1024).toFixed(2)) {
              webpFile = file;
              setSliderValue(0);
              setCompressText("Image is already compressed");
            } else {
              setSliderValue(1);
            }
          }
        } else {
          setCompressionRate(0);
        }
        let compressedSize = 0;
        let nthreshold = 0;
        let prevVal = 0;
        let prevSize = 0;
        for (let i = 0; i < 10; i++) {
          let calculation = 0;
          if (finalCompressedRatio == -1) {
            break;
          }
          if (finalCompressedRatio === 0) {
            calculation = 1 - i / 10;
          } else {
            calculation = 1 - (1 - finalCompressedRatio + (finalCompressedRatio * i) / 10);
          }
          if (safariAgent) {
            webp = cnvs.toDataURL("image/jpeg", calculation);
          } else {
            webp = cnvs.toDataURL("image/webp", calculation);
          }
          base64String = findBase64(webp);
          compressedSize = base64String.toFixed(1);
          if (compressedSize - prevSize >= 0 && compressedSize - prevSize < 1) {
            nthreshold = compressedSize;
            prevVal = i;
            break;
          }

          prevSize = compressedSize;
          prevVal = 999;
        }
        let pVar = 0;
        if (prevVal - 1 >= 0 && prevVal < 100) {
          pVar = prevVal - 1;
        } else if (prevVal == 999) {
          pVar = 999;
        } else {
          pVar = 0;
        }
        if (pVar != 999) {
          setPreviousValue(pVar);
        } else {
          setPreviousValue(0);
        }

        if (pVar <= 0) {
          setSliderValue(0);
          setCompressText("Image is already compressed");
        } else {
          if (prevSize > file.size / 1024) {
            setSliderValue(0);
            setCompressText("Image is already compressed");
          } else {
            setSliderValue(1);
          }
        }
        if (safariAgent) {
          webp = cnvs.toDataURL("image/jpeg", 1 - 1 / 10);
        } else {
          webp = cnvs.toDataURL("image/webp", 1 - 1 / 10);
        }
        webpFile = new File([webpToFile(webp)], file.type, { type: file.type });
        const resizedSizeInMB = webpFile.size / (1024 * 1024);
        if (resizedSizeInMB >= 1) {
          setOptimizedSize(`${resizedSizeInMB.toFixed(1)}MB`);
        } else {
          const fileSizeInKB = webpFile.size / 1024;
          setOptimizedSize(`${fileSizeInKB.toFixed(2)}KB`);
        }
      }

      let compressedImage = new Image();
      setCircularProgress(false);
      compressedImage.src = URL.createObjectURL(webpFile);
      URL.revokeObjectURL(URL.createObjectURL(webpFile));
    };
  }
  const CloseButtonOnclick = () => {
    if (selectedFile != null) {
      setImageUploadOpen(false);
    }

  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    const newFileName = file.name.substring(0, file.name.lastIndexOf(".")) || file.name;

    setUploadedFileName(newFileName);

    //for browser
    setKey(Date.now());
    const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(3);
    //virgülden sonra tree number mb olsun
    ReactGA4.event({
      category: "Preview",
      action: "Image upload page is open",
      label:
        "filename: " +
        file.name +
        "fileSize in mb: " +
        fileSizeInMB +
        " ,base: " +
        props.selectedBase.title +
        " ,originalVersion: " +
        props.selectedVersion.title,
      dimension1: localStorage.getItem("user.id"),
    });
    if (fileSizeInMB > 3) {
      props.createSnackbar("File size should be 3MB ", "warning", false);
      return;
    }

    if (fileSizeInMB >= 1) {
      setOriginalSize(`${fileSizeInMB.toFixed(1)}MB`);
    } else {
      const fileSizeInKB = file.size / 1024;
      setOriginalSize(`${fileSizeInKB.toFixed(2)}KB`);
    }
    setSelectedFile(file);
    const img = new Image();
    setCircularProgress(true);
    //drawing to canvas image to imagePreviewComponent
    img.onload = () => {
      if (img.height > 4096 || img.width > 4096) {
        props.createSnackbar("Height and width must not exceed 4096px", "error");
        setImageUploadOpen(false); // set the state to close the dialog
        return;
      } else {
        setImageUploadOpen(true);
        CompressImage(file);
        setImageLength(`${img.width}px X ${img.height}px`);
        const inputImgRatio = img.width / img.height;
        const patternCanvas = createPatternCanvas();
        const cnvs = document.createElement("canvas");
        const exCtx = cnvs.getContext("2d");

        if (window.devicePixelRatio > 1) {
          cnvs.width = 450 * 4;
          cnvs.height = 450 * 4;
          cnvs.style.width = "450px";
          cnvs.style.height = "450px";
          exCtx.scale(4, 4);
        } else {
          cnvs.width = 450;
          cnvs.height = 450;
        }
        exCtx.globalCompositeOperation = "source-over";
        const pattern = exCtx.createPattern(patternCanvas, "repeat");
        exCtx.fillStyle = pattern;
        exCtx.fillRect(0, 0, cnvs.width, cnvs.height);
        if (img.width < 450 && img.height < 450) {
          exCtx.drawImage(img, (450 - img.width) / 2, (450 - img.height) / 2, img.width, img.height);
        } else {
          if (inputImgRatio > 1) {
            const imgHeight = 450 / inputImgRatio;
            exCtx.drawImage(img, 0, (450 - imgHeight) / 2, 450, 450 / inputImgRatio);
          } else {
            const imgWidth = 450 * inputImgRatio;
            exCtx.drawImage(img, (450 - imgWidth) / 2, 0, 450 * inputImgRatio, 450);
          }
        }

        const originalImageUrl = cnvs.toDataURL("image/png", 1);
        setOriginalImage(originalImageUrl);
      }
    };
    img.src = URL.createObjectURL(file);
    URL.revokeObjectURL(URL.createObjectURL(file));
  };
  const handleClose = () => {
    setImageUploadOpen(false); // set the state to close the dialog
  };
  return (
    <div id={`${props.id}.Div`}>
      {props.isVisible && (
        <>
          {_.get(props, `label`) && (
            <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }} id="discrete-slider" gutterBottom>
              {_.get(props, `label`)}
            </Typography>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            <input
              id={inputID}
              type={"file"}
              key={key}
              hidden={"hidden"}
              multiple={true}
              accept=".jpeg, .png, .jpg"
              onChange={handleFileUpload}
            />

            <Dialog
              open={imageUploadOpen}
              onClose={handleClose}
              id={"SecondDialog"}
              scroll="paper"
              fullWidth={true}
              maxWidth={"md"}
              // classes={{ paper: classes.dialogPaper }}
            >
              <DialogContent style={{ paddingBottom: "20px", position: "relative" }}>
                <Button style={{ float: "right", color: "#4eabb1" }} onClick={CloseButtonOnclick}>
                  <Close> </Close>
                </Button>
                <ImagePreview
                  imageLength={imageLength}
                  inputID={inputID}
                  circularProgress={circularProgress}
                  previousValue={previousValue}
                  compressionRate={compressionRate}
                  sliderValue={sliderValue}
                  compressText={compressText}
                  setSliderValue={(value) => setSliderValue(value)}
                  selectedBase={props.selectedBase}
                  componentID={props.componentID}
                  setIsDialogCircularProgressOpen={(value) => props.setIsDialogCircularProgressOpen(value)}
                  setValue={(value) => props.setValue(value)}
                  setImageUploadOpen={(value) => setImageUploadOpen(value)}
                  fileOrginalSize={fileOrginalSize}
                  fileOptimizedSize={fileOptimizedSize}
                  newImageSrc={newImageSrc}
                  originalImage={originalImage}
                  images={props.images}
                  userPermissions={props.userPermissions}
                  destination={props.destination}
                  selectedVersion={props.selectedVersion}
                  selectedFile={selectedFile}
                  createSnackbar={props.createSnackbar}
                  componentType={props.componentType}
                  selectedImage={props.selectedImage}
                  insertMediaPathValue={props.insertMediaPathValue}
                  path={props.path}
                  bucket={props.bucket}
                  fileName={uploadedFileName}
                ></ImagePreview>

              </DialogContent>

            </Dialog>

            <Select
              onChange={(event) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (isSelectionValid(String(event.target.value))) {
                  selectImage(String(event.target.value));
                }
              }}
              className={props.classes.imageSelector}
              value={String(selectedImageID)}
            >
              {images.map((image) => (
                <MenuItem
                  key={String(image._id)}
                  value={String(image._id)}
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                    }}
                  >
                  <img
                    style={{ height: "40px", maxWidth: "100px", objectFit: "contain" }}
                    src={`images/${image.thumbFile || image.file}`}
                    onError={(e) => {}}
                  />
                  {image.uploadedFileName && (
                    <div
                      style={{
                        justifyContent: "center",
                        display: "flex",
                        alignItems: "center",
                        marginLeft: "15px",
                      }}
                    >
                      <Tooltip
                        key={_.get(image, `_id`)}
                        id="uploadedFileName_tooltip"
                        title={image.uploadedFileName}
                        enterDelay={1000}
                        leaveDelay={200}
                        placement="bottom-start"
                      >
                        <Typography style={{ textOverflow: "ellipsis", width: "170px", overflow: "hidden" }}>
                          {image.uploadedFileName}{" "}
                        </Typography>
                      </Tooltip>
                    </div>
                  )}
                  </div>
                  {selectedImageID !== image._id && (
                    <div
                      style={{
                        float: "right",
                      }}
                    >
                      <IconButton
                        className={classes.iconButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (props.userPermissions["delete"] === false) {
                            props.createSnackbar(
                              "You do not have permission to delete, contact your administrator.",
                              "warning",
                              false
                            );
                            return;
                          }
                          setImageToDelete(image);
                          setIsDeleteImageDialogOpen(true);
                        }}
                      >
                        <Remove />
                      </IconButton>
                    </div>
                  )}
                </MenuItem>
              ))}
            </Select>
            <IconButton
              id={`${props.id}.uploadButton`}
              className={classes.iconButton}
              onClick={(e) => {
                if (props.userPermissions["insert"] === false) {
                  props.createSnackbar(
                    "You are not authorized to insert, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You are not authorized to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                document.getElementById(inputID).click();
                setUploadStatus("ready");
                setIsDialogOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
            {/* <canvas id="canvasID" ref={canvasRef} style={{ display: "none" }} /> */}

            <DialogImageDelete
              isOpen={isDeleteImageDialogOpen}
              setIsOpen={(value) => setIsDeleteImageDialogOpen(value)}
              createSnackbar={props.createSnackbar}
              imageToDelete={imageToDelete}
              selectedImageID={selectedImageID}
              images={images}
              setImages={setImages}
              selectImage={selectImage}
              deleteImageFromMedia={props.deleteImageFromMedia}
            />
          </div>

        </>
      )}
    </div>
  );
}
