import React, { useEffect, useRef, useState, useContext } from "react";
import _, { reject } from "lodash";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import { Redirect } from "react-router-dom";
import { Typography } from "@material-ui/core";
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import Slide from "@material-ui/core/Slide";
import { getServerUri } from "../modules/uriUtility";
import GamePreview from "../components/containerGamePreview";

import GameConfig from "../components/containerGameConfig";
import PlayableGallery from "../components/playableGalleryComponents/playableGallery";
import DialogAccountSettings from "../components/accountSettingsComponents/dialogAccountSettings";
import BasicPopover from "../components/documentationComponents/Help";
import SelectorGame from "../components/selectorGame";
import BasePicker from "../components/selectorBase";
import CustomDragComponent from "../components/customDragComponent";
import ImageAccountItem from "../components/imageAccountItem";
import VersionSelectorOptions from "../components/versionSelectorOptions";
import VersionStorageOptions from "../components/versionStorageOptions";

import DialogVersionInsert from "../components/dialogVersionInsert";
import DialogFolderInsert from "../components/dialogFolderInsert";
import DialogVersionDelete from "../components/dialogVersionDelete";
import DialogDeleteAllVersions from "../components/dialogDeleteAllVersions";
import DialogVersionRename from "../components/dialogVersionRename";
import DialogFolderRename from "../components/dialogFolderRename";
import DialogPlayableErrors from "../components/dialogPlayableErrors";
import DialogVersionDuplicate from "../components/dialogVersionDuplicate";
import DialogFolderDuplicate from "../components/dialogFolderDuplicate";

import DialogConfirmRefresh from "../components/dialogConfirmRefresh";
import DialogCircularProgress from "../components/dialogCircularProgress";
import DialogViewOnlyMode from "../components/dialogViewOnlyMode";
import DialogFetchUpdatesOnVersion from "../components/dialogFetchUpdatesOnVersion";

import { UndoContext } from "../contexts/UndoContext";

ReactGA.initialize("UA-186063268-1", {
  debug: false,
  gaOptions: {
    userId: localStorage.getItem("user.id"),
  },
});
ReactGA4.initialize("G-TB46JQ70PR", {
  debug: false,
  gaOptions: {
    userId: localStorage.getItem("user.id"),
  },
});
const useStyles = makeStyles(() => ({
  editorItem: {
    paddingBottom: "10px",
  },
  dialogEditorItem: {},
  textField: { width: "100%" },
  card: { margin: "5px", backgroundColor: "#474747" },
  card2: { margin: "5px", backgroundColor: "#30302f" },

  paddedBox: { margin: "5px" },
  fullWidthEditor: {
    marginBottom: 5,
    width: "100%",
  },
  imageSelector: {
    flex: "1 0 auto",
    marginBottom: 5,
  },
  textGameObjectContainer: { marginTop: 0, marginBottom: 5 },
  title: {},
  avatarLarge: { width: "300px", height: "300px", padding: "30px" },
  avatarSmall: { width: "50px", height: "50px", margin: 5 },
  circularProgressDiv: {
    position: "fixed",
    top: "50%",
    textAlign: "center",
    transform: "translate(-50%, -50%)",
    left: "50%",
  },
  sliderSize: {
    width: "100%",
    marginBottom: "5px",
  },
  homeDiv: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    flex: 1,
    overflowY: "auto",
    textAlign: "left",
  },
  column1: {
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    borderStyle: "none solid none none",
    borderWidth: "1px",
    borderColor: "black",
  },
  column1Top: {
    display: "flex",
    justifyContent: "center",
    overflowY: "auto",
    borderColor: "black",
    height: "90vh",
    padding: "5px",

    overflowX: "hidden",
    borderStyle: "none none solid none",
    borderWidth: "1px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  column2: {
    width: "339px",
    overflowY: "hidden",
    overflowX: "hidden",

    // position: "sticky",
    // top: "0",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    borderStyle: "none solid none none",
    borderWidth: "1px",
    borderColor: "black",
  },
  column2Top: {
    display: "flex",
    height: "60px",
    justifyContent: "center",
    alignItems: "center",
    padding: "5px",
    borderStyle: "none none solid none",
    borderWidth: "1px",
    borderColor: "black",
  },

  cardContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  imageUploadPreview: {
    width: 125,
    height: 125,
    cursor: "pointer",
  },
  customFont: {
    color: "#9bcec0",
  },
}));
const iFrameId = "gamePreviewIframe";

export const applyPreview = ({ newGameConfig, selectedBase }) => {
  if (!selectedBase) {
    console.warn("Can't apply config when base is not selected. no op.");
    return;
  }
  const gamePreviewIframe = document.getElementById(iFrameId);

  if (!gamePreviewIframe) {
    return;
  }
  if (_.isFunction(gamePreviewIframe.contentWindow.applyGameConfig)) {
    console.dir(newGameConfig);
    try {
      //not allowing scripting errors of games to stop execution of panel's code
      document.getElementById(iFrameId).contentWindow.applyGameConfig(newGameConfig);
    } catch (err) {
      console.trace(err);
    }
  }
};

export const versionChanged = _.debounce(() => {
  const gamePreviewIframe = document.getElementById(iFrameId);

  if (!gamePreviewIframe) {
    return;
  }

  if (_.isFunction(gamePreviewIframe.contentWindow.changeVersion)) {
    //console.debug("Trying to notify game that version changed");

    try {
      document.getElementById(iFrameId).contentWindow.changeVersion();
    } catch (err) {
      console.trace(err);
    }
  }
});

let timeoutID = null;
export default function home(props) {
  let [isTokenValidated, setIsTokenValidated] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [numberOfBases, setNumberOfBases] = useState(0);

  let [games, setGames] = useState([]);
  let [bases, setBases] = useState([]);
  let [baseIDProps, setBaseID] = useState([]);
  let [versions, setVersions] = useState([]);
  let [versionHierarchy, setVersionHierarchy] = useState([]);
  let [treeIsSorted, setSortedTree] = useState("");
  let [sortAgain, setSortAgain] = useState(0);
  let [plans, setPlans] = useState([]);
  const [planIndexes, setPlanIndexes] = useState([]);
  const [galleryGames, setGalleryGames] = useState([]);

  let [media, setMedia] = useState([]);
  let [atlases, setAtlases] = useState([]);
  let [sounds, setSounds] = useState([]);
  let [fonts, setFonts] = useState([]);
  let [videos, setVideos] = useState([]);

  let [areGamesLoaded, setAreGamesLoaded] = useState(false);
  let [areBasesLoaded, setAreBasesLoaded] = useState(false);
  let [areVersionsLoaded, setAreVersionsLoaded] = useState(false);
  let [areMediaLoaded, setAreMediaLoaded] = useState(false);
  let [areAtlasesLoaded, setAreAtlasesLoaded] = useState(false);
  let [areSoundsLoaded, setAreSoundsLoaded] = useState(false);
  let [areFontsLoaded, setAreFontsLoaded] = useState(false);
  let [areVideosLoaded, setAreVideosLoaded] = useState(false);
  let [arePlansLoaded, setArePlansLoaded] = useState(false);
  let [arePermissionsLoaded, setArePermissionsLoaded] = useState(false);
  let [defaultVersionUpdated, setDefaultVersionUpdated] = useState(false);
  let [isPreviewLoaded, setIsPreviewLoaded] = useState(false);
  let [searchBarValue, setSearchBarValue] = useState("");
  let [selectedGame, setSelectedGame] = useState();
  let [selectedBase, setSelectedBase] = useState();
  let [selectedVersion, setSelectedVersion] = useState();
  let [userCompany, setUserCompany] = useState();
  let [userPermissions, setUserPermissions] = useState({});
  let [defaultVersionID, setDefaultVersionID] = useState("");

  let [isAccountInfoVisible, setIsAccountInfoVisible] = useState(false);
  let [isInsertVersionDialogOpen, setIsInsertVersionDialogOpen] = useState(false);
  let [isRenameVersionDialogOpen, setIsRenameVersionDialogOpen] = useState(false);
  let [isRenameFolderDialogOpen, setIsRenameFolderDialogOpen] = useState(false);

  let [isFolderVersionDialogOpen, setIsFolderVersionDialogOpen] = useState(false);
  let [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  let [isDeleteAllDialogOpen, setIsDeleteAllDialogOpen] = useState(false);

  let [isDuplicateDialogOpen, setIsDuplicateDialogOpen] = useState(false);
  let [isFolderDialogOpen, setIsFolderDialogOpen] = useState(false);

  let [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);
  let [selectedFolderId, setSelectedFolderId] = useState(9999999);

  let [isUpdateTreeOpen, setUpdateTreeOpen] = useState("");
  let [isInsertNewVersion, setInsertNewVersion] = useState("");
  let [renameVersionName, setRenameVersionName] = useState("");
  let [newSetPortrait, setPortrait] = useState(true);
  let [versionsAreFetched,setVersionsAreFetched] = useState(false);
  // let [newVersionTitle, setNewVersionTitle] = useState((selectedVersion) ? selectedVersion.title : "");

  let [renameFolderName, setRenameFolderName] = useState("");
  const [localizationLanguage, setLocalizationLanguage] = useState("");

  const [isDialogConfirmRefreshOpen, setIsDialogConfirmRefreshOpen] = useState(false);
  const [isDialogCircularProgressOpen, setIsDialogCircularProgressOpen] = useState(false);
  const [isDialogViewOnlyModeOpen, setIsDialogViewOnlyModeOpen] = useState(false);
  const [isDialogFetchUpdatesOnVersionOpen, setIsDialogFetchUpdatesOnVersionOpen] = useState(false);
  const [lastUpdatingUser, setLastUpdatingUser] = useState();
  const [isDownloadingPlayable, setIsDownloadingPlayable] = useState(false);

  const [totalSizeValue, setTotalSize] = useState(0);
  let [refresh, doRefresh] = useState(0);

  let [isRecorderEnabled, setRecorderEnabled] = useState(false);
  let [maxInnerHeight, setMaxInnerHeight] = useState(null);
  let [playableErrors, setPlayableErrors] = useState(null);
  let [menuLayout, setMenuLayout] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [treeData, setTreeData] = useState([]);
  const [originalTreeData, setOTreeData] = useState([]);
  const [prevSelectedRecorderSettings, setPrevSelectedRecorderSettings] = useState(null);
  const [prevButton, setPrevButton] = useState(null);
  const [heightInPx, setHeightInPx] = useState(null);
  const [defaultVersion, setDefaultVersion] = useState("");

  const column1TopRef = useRef(null);

  let dummyTree = [];

  const undoCtx = useContext(UndoContext);

  const [userPermissionsInitial, setUserPermissionsInitial] = useState({
    "view": false,
    "update": false,
    "insert": false,
    "delete": false,
  });

  const { enqueueSnackbar } = useSnackbar();
  const prevVersion = usePrevious(selectedVersion);

  const classes = useStyles();

  let messageListener = useRef(null);
  window.removeEventListener("message", messageListener.current);
  messageListener.current = window.addEventListener("message", (message) => {
    if (!message) return;
    if (message.data.action === "activeTabPermitted") {
      if (!isRecorderEnabled) {
        setRecorderEnabled(true);
      }
      setMaxInnerHeight(message.data.maxInnerHeight);
    }
    if (_.has(message.data, "missingFiles")) {
      let missingFiles = _.get(message.data, "missingFiles", null);
      setPlayableErrors(missingFiles);
    }
  });

  function validateToken() {
    return new Promise((resolve, reject) => {
      const requestURI = getServerUri("authentication/validateAccessToken");
      const requestInit = {
        method: "POST",
        headers: {
          "content-type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({ accessToken: localStorage.getItem("session.accessToken") }),
      };
      fetch(requestURI, requestInit)
        .then((response) => {
          if (response.status === 200) {
            setIsTokenValidated(true);
            let permissionsArray = localStorage.getItem("user.permissions");
            let newUserPermissionsInitial = _.cloneDeep(userPermissionsInitial);
            if (!_.isNil(permissionsArray)) {
              permissionsArray = permissionsArray.split(",");
              for (let i = 0; i < permissionsArray.length; i++) {
                const item = permissionsArray[i];
                newUserPermissionsInitial[item] = true;
              }
            } else {
              newUserPermissionsInitial = null;
            }
            setUserPermissionsInitial(newUserPermissionsInitial);
            setUserPermissions(newUserPermissionsInitial);
            resolve(true);
          } else {
            reject({ message: "token is invalid" });
          }
        })
        .catch((err) => reject);
    });
  }

  function fetchGames() {
    console.info("fetching games");
    return new Promise((resolve, reject) => {
      fetch(getServerUri("game/find"), {
        method: "POST",
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((fetchedGames) => {
                let fetchedGamesDescending;
                let newfetchedGamesDescending = [];
                if (Array.isArray(fetchedGames) && fetchedGames.length > 0) {
                  for (var i = 0; i < fetchedGames.length; i++) {
                    if (
                      fetchedGames[i]._id == "62b9a830cc0ad8e0a9eaa81c" ||
                      fetchedGames[i]._id == "62a09e2041f6066717fff686"
                    ) {
                      newfetchedGamesDescending.push(fetchedGames[i]);
                      fetchedGames.splice(i, 1);
                      i--;
                    }
                  }
                  fetchedGamesDescending = fetchedGames.reverse();

                  if (newfetchedGamesDescending.length > 0) {
                    fetchedGamesDescending = newfetchedGamesDescending.concat(fetchedGamesDescending);
                  }
                }
                let countNumberOfBases = 0;
                let waiting = fetchedGamesDescending.length;
                fetchedGamesDescending.forEach((game) => {
                  doAsynchronousFunction(game, finish);
                });
                function finish() {
                  waiting -= 1;
                  if (waiting === 0) {
                    setNumberOfBases(countNumberOfBases);
                    const notGalleryGames = fetchedGamesDescending.filter(
                      (game) => !_.has(game, "isInPlayableGallery") || game.isInPlayableGallery === false
                    );
                    const galleryGames = fetchedGamesDescending.filter(
                      (game) => _.has(game, "isInPlayableGallery") && game.isInPlayableGallery === true
                    );
                    setGames(notGalleryGames);
                    setGalleryGames(galleryGames);
                    setAreGamesLoaded(true);
                    if (
                      !_.isNil(localStorage.getItem("selectedGameID")) &&
                      !_.isNil(
                        notGalleryGames.filter(
                          (game) => _.get(game, "_id") === localStorage.getItem("selectedGameID")
                        )[0]
                      )
                    ) {
                      const userSelectedGame = notGalleryGames.filter(
                        (game) => _.get(game, "_id") === localStorage.getItem("selectedGameID")
                      )[0];
                      setSelectedGame(userSelectedGame);
                    } else {
                      if (
                        !_.isNil(localStorage.getItem("selectedGameID")) &&
                        !_.isNil(
                          galleryGames.filter(
                            (game) => _.get(game, "_id") === localStorage.getItem("selectedGameID")
                          )[0]
                        )
                      ) {
                        const userSelectedGame = galleryGames.filter(
                          (game) => _.get(game, "_id") === localStorage.getItem("selectedGameID")
                        )[0];
                        setSelectedGame(userSelectedGame);
                      } else if (!selectedGame && Array.isArray(notGalleryGames) && notGalleryGames.length > 0) {
                        setSelectedGame(notGalleryGames[0]);
                        localStorage.setItem("selectedGameID", _.get(notGalleryGames[0], "_id"));
                      } else if (!selectedGame && Array.isArray(galleryGames) && galleryGames.length > 0) {
                        setSelectedGame(galleryGames[0]);
                        localStorage.setItem("selectedGameID", _.get(galleryGames[0], "_id"));
                      }
                    }
                    resolve(notGalleryGames.concat(galleryGames));
                  }
                }
                function doAsynchronousFunction(game, callback) {
                  getNumberOfBases(game._id).then((res) => {
                    countNumberOfBases += res;
                    callback();
                  });
                }
              })
              .catch((err) => reject);
          } else {
            response
              .text()
              .then((err) => reject)
              .catch((err) => reject);
          }
        })
        .catch((err) => reject);
    });
  }

  function getNumberOfBases(gameID) {
    return new Promise((resolve, reject) => {
      fetch(getServerUri("base/find"), {
        method: "POST",
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          gameID: gameID,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((fetchedBases) => {
                resolve(_.size(fetchedBases));
              })
              .catch((err) => reject);
          } else {
            response
              .text()
              .then((err) => reject)
              .catch((err) => reject);
          }
        })
        .catch((err) => reject);
    });
  }

  function fetchBases(gameID) {
    console.info("fetching bases");
    return new Promise((resolve, reject) => {
      fetch(getServerUri("base/find"), {
        method: "POST",
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          gameID: gameID,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((fetchedBases) => {
                setBases(fetchedBases);
                setAreBasesLoaded(true);
                if (!_.isNil(localStorage.getItem("selectedBaseID"))) {
                  const userSelectedBase = fetchedBases.filter(
                    (base) => _.get(base, "_id") === localStorage.getItem("selectedBaseID")
                  )[0];
                  setVersionHierarchy(dummyTree);

                  setSelectedBase(userSelectedBase);
                  setSelectedFolderId(9999999);
                } else {
                  if (_.size(fetchedBases) > 0 && !selectedBase) {
                    setVersionHierarchy(dummyTree);
                    setSelectedBase(fetchedBases[0]);
                    setSelectedFolderId(9999999);

                    localStorage.setItem("selectedBaseID", _.get(fetchedBases[0], "_id"));
                  }
                }
                resolve(fetchedBases);
              })
              .catch((err) => reject);
          } else {
            response
              .text()
              .then((err) => reject)
              .catch((err) => reject);
          }
        })
        .catch((err) => reject);
    });
  }

  function fetchVersions(baseID, companyID) {
    console.info("fetching versions");
    var versionHierarchy = new Promise(() => {
      fetch(getServerUri("version/getHierarchy"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          baseID: baseID ? baseID : "6140893d93ddb73076a1554e",
          companyID: companyID ? companyID : "5fcde275da672a1d3a53cf0b",
        }),
      }).then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            setVersionHierarchy(json.versionHierarchy);
            setUpdateTreeOpen(json.versionHierarchy);
            setBaseID(baseID);
          });
        }
      });
    });

    return new Promise((resolve, reject) => {
      fetch(getServerUri("version/find"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          baseID: baseID ? baseID : String(selectedBase._id),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((versionRecords) => {
              setAreVersionsLoaded(true);
              if (!selectedVersion && _.size(versionRecords) > 0) {
                if (!versionRecords[0].hasOwnProperty("gameConfig")) {
                  throw {
                    message: "version record doesn't contain gameConfig",
                    versionRecord: versionRecords[0],
                  };
                }
                if (
                  !_.isNil(localStorage.getItem("selectedVersionID")) &&
                  versionRecords.some((version) => _.get(version, "_id") === localStorage.getItem("selectedVersionID"))
                ) {
                  const userSelectedVersion = versionRecords.filter(
                    (version) => _.get(version, "_id") === localStorage.getItem("selectedVersionID")
                  )[0];
                  const currentTime = new Date().getTime();
                  const dateUpdatedTime = new Date(_.get(userSelectedVersion, "dateUpdated", currentTime)).getTime();
                  if (dateUpdatedTime < currentTime - 1000 * 60 * 60) {
                    // 1 hour
                    //_.set(userSelectedVersion, 'isBeingUpdated', false);
                  }
                  setSelectedVersion(userSelectedVersion);
                  for (let i = 0; i < versionRecords.length; i++)
                    if (versionRecords[i]._id === userSelectedVersion._id) {
                      versionRecords[i] = _.cloneDeep(userSelectedVersion);
                    }
                  applyPreview({ newGameConfig: userSelectedVersion.gameConfig, selectedBase });
                } else {
                  const currentTime = new Date().getTime();
                  const dateUpdatedTime = new Date(_.get(versionRecords[0], "dateUpdated", currentTime)).getTime();
                  if (dateUpdatedTime < currentTime - 1000 * 60 * 60) {
                    // 1 hour @TODO
                  }
                  setSelectedVersion(versionRecords[0]);
                  localStorage.setItem("selectedVersionID", _.get(versionRecords[0], "_id"));
                  applyPreview({ newGameConfig: versionRecords[0].gameConfig, selectedBase });
                }
              }
              setVersions(versionRecords);
              resolve(versionRecords);
              setVersionsAreFetched(true);
            });
          } else {
            response
              .text()
              .then((err) => reject)
              .catch((err) => reject);
          }
        })
        .catch((err) => reject);
    });
  }

  function fetchImages() {
    console.info("Fetching Images");
    return new Promise((resolve, reject) => {
      console.info("find media uri: ", getServerUri("media/find"));
      fetch(getServerUri("media/find"), {
        method: "POST",
        body: new URLSearchParams({ accessToken: localStorage.getItem("session.accessToken") }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((fetchedImages) => {
                setMedia(fetchedImages);
                setAreMediaLoaded(true);
                resolve(fetchedImages);
              })
              .catch(reject);
          } else {
            response.text().then(reject).catch(reject);
          }
        })
        .catch(reject);
    });
  }

  function fetchAtlases() {
    console.info("Fetching Atlases");
    return new Promise((resolve, reject) => {
      console.info("find atlas uri: ", getServerUri("atlas/find"));
      fetch(getServerUri("atlas/find"), {
        method: "POST",
        body: new URLSearchParams({ accessToken: localStorage.getItem("session.accessToken") }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((fetchedAtlases) => {
                setAtlases(fetchedAtlases);
                setAreAtlasesLoaded(true);
                resolve(fetchedAtlases);
              })
              .catch(reject);
          } else if (response.status === 401) {
            createSnackbar("You are not authorized to view, contact your administrator.", "warning");
            response
              .text()
              .then((textResponse) => {
                console.log({ message: "error viewing", textResponse });
              })
              .catch(console.error);
          } else {
            response.text().then(reject).catch(reject);
          }
        })
        .catch(reject);
    });
  }

  function fetchSounds() {
    console.info("Fetching Sounds");
    return new Promise((resolve, reject) => {
      console.info("find sound uri: ", getServerUri("sound/find"));
      fetch(getServerUri("sound/find"), {
        method: "POST",
        body: new URLSearchParams({ accessToken: localStorage.getItem("session.accessToken") }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((fetchedSounds) => {
                setSounds(fetchedSounds);
                setAreSoundsLoaded(true);
                resolve(fetchedSounds);
              })
              .catch(reject);
          } else {
            response.text().then(reject).catch(reject);
          }
        })
        .catch(reject);
    });
  }

  function fetchFonts() {
    console.info("Fetching Fonts");
    return new Promise((resolve, reject) => {
      console.info("find font uri: ", getServerUri("font/find"));
      fetch(getServerUri("font/find"), {
        method: "POST",
        body: new URLSearchParams({ accessToken: localStorage.getItem("session.accessToken") }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((fetchedFonts) => {
                setFonts(fetchedFonts);
                setAreFontsLoaded(true);
                resolve(fetchedFonts);
              })
              .catch(reject);
          } else {
            response.text().then(reject).catch(reject);
          }
        })
        .catch(reject);
    });
  }

  function fetchVideos() {
    console.info("Fetching Videos");
    return new Promise((resolve, reject) => {
      console.info("find video uri: ", getServerUri("video/find"));
      fetch(getServerUri("video/find"), {
        method: "POST",
        body: new URLSearchParams({ accessToken: localStorage.getItem("session.accessToken") }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((fetchedVideos) => {
                setVideos(fetchedVideos);
                setAreVideosLoaded(true);
                resolve(fetchedVideos);
              })
              .catch(reject);
          } else {
            response.text().then(reject).catch(reject);
          }
        })
        .catch(reject);
    });
  }

  function fetchPlans() {
    console.info("fetching plans");
    return new Promise((resolve, reject) => {
      fetch(getServerUri("plan/find"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((planRecords) => {
              setPlans(planRecords);

              const initialPlanIndexes = [];
              for (let i = 0; i < planRecords.length; i++) {
                initialPlanIndexes[_.get(planRecords[i], "type", "")] = i;
              }
              setPlanIndexes(initialPlanIndexes);
              setArePlansLoaded(true);

              const planRecordsAndIndexes = {};
              _.set(planRecordsAndIndexes, "planRecords", planRecords);
              _.set(planRecordsAndIndexes, "planIndexes", initialPlanIndexes);
              resolve(planRecordsAndIndexes);
            });
          } else {
            response
              .text()
              .then((err) => reject)
              .catch((err) => reject);
            console.log("errror in fetching plans");
          }
        })
        .catch((err) => reject);
    });
  }

  function fetchCompanyPermissions() {
    return new Promise((resolve, reject) => {
      fetch(getServerUri("company/findOne"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          companyID: localStorage.getItem("user.companyID"),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            setIsAdmin(localStorage.getItem("user.isAdmin") === "true");
            response
              .json()
              .then((company) => {
                setUserCompany(company);
                resolve(true);
              })
              .catch((err) => reject);
          } else {
            response
              .text()
              .then((err) => reject)
              .catch((err) => reject);
          }
        })
        .catch((err) => reject);
    });
  }

  function fetchAndUpdateCompanySubscription(fetchedPlansAndIndexes) {
    const fetchedPlans = fetchedPlansAndIndexes.planRecords;
    const fetchedIndexes = fetchedPlansAndIndexes.planIndexes;
    return new Promise((resolve, reject) => {
      fetch(getServerUri("company/findOne"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          companyID: localStorage.getItem("user.companyID"),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((company) => {
                if (!_.isNil(_.get(company, "subscription.periods")) && company.subscription.periods.length > 0) {
                  const endDate = new Date(
                    company.subscription.periods[company.subscription.periods.length - 1].endDate
                  );
                  const subscriptionEndDate = new Date(company.subscription.subscriptionEndDate);
                  const currentTime = new Date();
                  if (currentTime > endDate) {
                    const nextStartDate = getSubscriptionNextStartDate(endDate);
                    let nextEndDate = getSubscriptionEndDate(nextStartDate);

                    let newCompany = _.cloneDeep(company);
                    let newSubscription = _.cloneDeep(newCompany.subscription);
                    let newPeriod = _.cloneDeep(newSubscription.periods[newSubscription.periods.length - 1]);

                    if (currentTime > subscriptionEndDate) {
                      newSubscription.currentPlan = "FREE";
                    }

                    if (newSubscription.currentPlan !== "FREE" && subscriptionEndDate < nextEndDate) {
                      nextEndDate = subscriptionEndDate;
                    }

                    const planIndex = fetchedIndexes[newSubscription.currentPlan];
                    newPeriod.amount = fetchedPlans[planIndex].monthlyPrice;

                    newPeriod.startDate = nextStartDate;
                    newPeriod.endDate = nextEndDate;
                    newPeriod.monthlyCreatedPlayables = 0;
                    newPeriod.monthlyCreatedVideos = 0;
                    newPeriod.testPlayableCount = 0;
                    newPeriod.isPaid = newPeriod.amount === 0.0;
                    newPeriod.paymentDate = null;

                    newSubscription.periods.push(newPeriod);
                    newCompany.subscription = newSubscription;

                    const fetchUri = getServerUri("company/updateOne");
                    const fetchInit = {
                      method: "POST",
                      headers: { "content-type": "application/x-www-form-urlencoded" },
                      body: new URLSearchParams({
                        accessToken: localStorage.getItem("session.accessToken"),
                        companyID: String(_.get(newCompany, "_id")),
                        subscription: JSON.stringify(newSubscription),
                      }),
                    };
                    fetch(fetchUri, fetchInit)
                      .then((response) => {
                        if (response.status === 200) {
                          setUserCompany(newCompany);
                          resolve(newCompany);
                        } else {
                          response
                            .text()
                            .then((textResponse) => {
                              console.log("subscription update failed: " + textResponse);
                            })
                            .catch(console.error);
                        }
                      })
                      .catch((err) => {
                        console.error(err);
                      });
                  } else {
                    resolve(company);
                  }
                } else {
                  resolve(company);
                }
              })
              .catch((err) => reject);
          } else {
            response
              .text()
              .then((err) => reject)
              .catch((err) => reject);
          }
        })
        .catch((err) => reject);
    });
  }

  //close autoplay on page close event for close autoplay
  function closeAutoplay(version) {
    let isAutoplay;
    if (!_.isNil(version)) {
      isAutoplay = _.get(version, "gameConfig.components.autoplay1.startAutoplay", null);
      if (!_.isNil(isAutoplay) && isAutoplay) {
        setSelectedVersionPathValueWithVersion(
          {
            path: "gameConfig.components.autoplay1.startAutoplay",
            value: false,
          },
          version
        );
      }
    }
    return isAutoplay;
  }

  // for january 2021 params: (1, 2021)
  function daysInMonth(month, year) {
    return new Date(year, month + 1, 0).getDate();
  }

  function getSubscriptionNextStartDate(endDate) {
    let nextStartDate = new Date(endDate);
    nextStartDate.setDate(endDate.getDate() + 1);
    nextStartDate.setHours(0);
    nextStartDate.setMinutes(0);
    nextStartDate.setSeconds(0);
    return nextStartDate;
  }

  function getSubscriptionEndDate(startDate) {
    let endDay = startDate.getDate();
    let endMonth = startDate.getMonth() + 1;
    let endYear = startDate.getFullYear();
    if (
      endDay > daysInMonth(endMonth, endYear) + 1 ||
      startDate.getDate() === daysInMonth(startDate.getMonth(), startDate.getFullYear())
    ) {
      endDay = daysInMonth(endMonth, endYear);
    }

    let endDate = new Date(endYear, endMonth, endDay);
    endDate.setSeconds(endDate.getSeconds() - 1);
    return endDate;
  }

  function getPlanIndex(planType) {
    try {
      return planIndexes[planType];
    } catch (err) {
      console.log(err);
    }
  }
  //trigger the function when refresh or close the page
  window.onunload = window.onbeforeunload = function () {
    closeAutoplay(selectedVersion);
  };

  const circularProgressDiv = function (thicknessVal) {
    return (
      <div id={"circularProgressDiv"} className={classes.circularProgressDiv}>
        <CircularProgress thickness={thicknessVal ? thicknessVal : 3.6} variant={"indeterminate"}></CircularProgress>
      </div>
    );
  };

  const areObjectsEqual = function (obj1, obj2, ignore) {
    if (typeof obj1 !== typeof obj2) return false;

    if (typeof obj1 !== "object") return obj1 === obj2;

    const keys = Object.keys(obj1);
    for (let idx = 0; idx < keys.length; idx++) {
      const value = keys[idx];
      if (_.isNil(obj1[value]) || _.isNil(obj2[value])) {
        return false;
      } else {
        if (typeof obj1[value] === "object") {
          if (!areObjectsEqual(obj1[value], obj2[value], ignore)) return false;
        } else {
          if (value !== ignore) {
            if (!areObjectsEqual(obj1[value], obj2[value], ignore)) return false;
          }
        }
      }
    }
    return true;
  };

  const fetchSelectedVersion = function () {
    if (_.isNil(selectedBaseID) || _.isNil(selectedVersionID)) {
      return new Promise((resolve, reject) => {
        reject({
          selectedBaseID,
          selectedVersionID,
        });
      });
    }
    return new Promise((resolve, reject) => {
      fetch(getServerUri("version/findOne"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          versionID: String(selectedVersionID),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((fetchedVersion) => {
              if (_.isNil(fetchedVersion) || !fetchedVersion.hasOwnProperty("gameConfig")) {
                throw {
                  message: "version doesn't contain gameConfig",
                  versionRecord: version,
                };
              }

              resolve(fetchedVersion);
            });
          } else {
            response
              .text()
              .then((err) => reject(err))
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => reject(err));
    });
  };

  const getDefaultVersion = function (version){
    setDefaultVersion(version);
  };

  const insertNewVersion = function (newSelectedVersion, oldVersion) {

    setIsVersionBeingUpdatedFalse(oldVersion)
      .then((_newVersions) => {
        const newVersions = _.clone(_newVersions);
        console.log({
          messages: "version added. the array is: ",
          newVersions,
        });
        let defaultGameConfig = addDefaultImagesToGameConfig(_.get(newSelectedVersion, "gameConfig"));
        setSelectedVersionPathValueWithVersion({ path: "gameConfig", value: defaultGameConfig }, newSelectedVersion);

        _.set(newSelectedVersion, "gameConfig", defaultGameConfig);
        newVersions.push(newSelectedVersion);
        setVersions(newVersions);

        ReactGA.event({
          category: "Version",
          action: "Version Created",
          label:
            "game: " + selectedGame.title + ", base: " + selectedBase.title + ", version: " + newSelectedVersion.title,
          dimension1: localStorage.getItem("user.id"),
        });
        ReactGA4.event({
          category: "Version",
          action: "Version Created",
          label:
            "game: " + selectedGame.title + ", base: " + selectedBase.title + ", version: " + newSelectedVersion.title,
          dimension1: localStorage.getItem("user.id"),
        });
        setSelectedVersion(newSelectedVersion);
        applyPreview({ newGameConfig: _.get(newSelectedVersion, "gameConfig", {}), selectedBase });
      })
      .catch(console.err);
  };

  const setIsVersionBeingUpdatedFalse = function (version) {
    return new Promise((resolve, reject) => {
      if (_.isNil(version) || _.get(version, "lastUpdatingUserID", "") !== localStorage.getItem("user.id")) {
        resolve(versions);
      } else {
        const createdLanguages = _.get(version, "createdLanguages") ? _.get(version, "createdLanguages"): ["en"];

        fetch(getServerUri("version/updateOne"), {
          method: "POST",
          headers: { "content-type": "application/x-www-form-urlencoded" },
          body: new URLSearchParams({
            accessToken: localStorage.getItem("session.accessToken"),
            versionID: String(_.get(version, "_id")),
            gameConfig: JSON.stringify(_.get(version, "gameConfig")),
            recorderSettings: JSON.stringify(_.get(version, "recorderSettings")),
            createdLanguages: JSON.stringify(createdLanguages)

            //isBeingUpdated: String(false)
          }),
        })
          .then((response) => {
            if (response.status === 200) {
              console.log({ message: "version updated" });
              const newVersion = _.cloneDeep(version);
              //_.set(newVersion, 'isBeingUpdated', false);
              const newVersions = _.cloneDeep(versions);
              for (let i = 0; i < newVersions.length; i++)
                if (newVersions[i]._id === version._id) {
                  newVersions[i] = newVersion;
                }
              setVersions(newVersions);
              resolve(newVersions);
            } else if (response.status === 401) {
              response
                .text()
                .then((textResponse) => {
                  console.log({ message: "error updating", textResponse });
                  reject(textResponse);
                })
                .catch(console.error);
            }
          })
          .catch((err) => {
            console.error(err);
          });
      }
    });
  };
  useEffect(() => {
    findDefaultVersionID();
  }, [defaultVersionUpdated]);


  useEffect(() => {
    // İlk yüklenme için event listener ekleme
    handleResize();
    window.addEventListener("resize", handleResize);

    // Component kaldırıldığında event listener'ı temizleme
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [heightInPx]);
  useEffect(() => {
    //getRecordingPermissionEveryTime
    if (column1TopRef.current) {
      const height = column1TopRef.current.clientHeight;
      setHeightInPx(height); // Component yüklendikten sonra  yüksekliği alıyo
    }
    validateToken()
      .then(() => {
        setArePermissionsLoaded(true);
        fetchPlans().then((fetchedPlansAndIndexes) => {
          fetchAndUpdateCompanySubscription(fetchedPlansAndIndexes).then((fetchedCompany) => {
            fetchCompanyPermissions().then(() => {
              fetchGames()
                .then((fetchedGames) => {
                  let initialSelectedGame = fetchedGames[0];
                  if (!_.isNil(localStorage.getItem("selectedGameID"))) {
                    const lastSelectedGame = fetchedGames.filter(
                      (game) => _.get(game, "_id") === localStorage.getItem("selectedGameID")
                    )[0];
                    if (!_.isNil(lastSelectedGame)) {
                      initialSelectedGame = lastSelectedGame;
                    }
                  }
                  fetchBases(String(initialSelectedGame._id))
                    .then((fetchedBases) => {
                      let initialSelectedBase = fetchedBases[0];
                      if (!_.isNil(localStorage.getItem("selectedBaseID"))) {
                        const lastSelectedBase = fetchedBases.filter(
                          (base) => _.get(base, "_id") === localStorage.getItem("selectedBaseID")
                        )[0];
                        if (!_.isNil(lastSelectedBase)) {
                          initialSelectedBase = lastSelectedBase;
                          setVersionHierarchy(dummyTree);
                          setSelectedBase(initialSelectedBase);
                          setSelectedFolderId(9999999);
                        }
                      }

                      fetchVersions(String(initialSelectedBase._id), localStorage.getItem("user.companyID")).then(
                        (fetchedVersions) => {
                          if (_.size(fetchedVersions) === 0) {
                            setIsInsertVersionDialogOpen(true);
                          }
                        }
                      );

                    })
                    .catch(console.error);
                })
                .catch(console.error);
              fetchImages()
                .then((fetchedImages) => {
                  setAreMediaLoaded(true);
                })
                .catch(console.error);
              fetchAtlases()
                .then((fetchedAtlases) => {
                  setAreAtlasesLoaded(true);
                })
                .catch(console.error);
              fetchSounds()
                .then((fetchedSounds) => {
                  setAreSoundsLoaded(true);
                })
                .catch(console.error);
              fetchFonts()
                .then((fetchedFonts) => {
                  setAreFontsLoaded(true);
                })
                .catch(console.error);
              fetchVideos()
                .then((fetchedVideos) => {
                  setAreVideosLoaded(true);
                })
                .catch(console.error);
            });
          });
        });
      })
      .catch((err) => {
        console.error(err);
        window.location = "/login";
      });

  }, []);

  const selectedGameID = _.get(selectedGame, "_id");
  useEffect(() => {
    setAreBasesLoaded(false);
    if (selectedGameID) {
      setVersionHierarchy(dummyTree);
      setSelectedBase(null);

      setSelectedVersion(null);
      fetchBases(selectedGameID).then((fetchedBases) => {
        setBases(fetchedBases);
        setAreBasesLoaded(true);
        if (_.size(fetchedBases) > 0) {
          if (!_.isNil(localStorage.getItem("selectedBaseID"))) {
            const userSelectedBase = fetchedBases.filter(
              (base) => _.get(base, "_id") === localStorage.getItem("selectedBaseID")
            )[0];
            if (!_.isNil(userSelectedBase)) {
              setVersionHierarchy(dummyTree);
              setSelectedBase(userSelectedBase);
              setSelectedFolderId(9999999);
            } else {
              setVersionHierarchy(dummyTree);
              setSelectedBase(fetchedBases[0]);
              setSelectedFolderId(9999999);

              if (!_.isNil(_.get(fetchedBases[0], "_id"))) {
                localStorage.setItem("selectedBaseID", _.get(fetchedBases[0], "_id"));
              }
            }
          } else {
            setVersionHierarchy(dummyTree);
            setSelectedBase(fetchedBases[0]);
            setSelectedFolderId(9999999);

            if (!_.isNil(_.get(fetchedBases[0], "_id"))) {
              localStorage.setItem("selectedBaseID", _.get(fetchedBases[0], "_id"));
            }
          }


          ReactGA.event({
            category: "Game",
            action: "Game Changed",
            label: "game: " + selectedGame.title,
            dimension1: localStorage.getItem("user.id"),
          });
          ReactGA4.event({
            category: "Game",
            action: "Game Changed",
            label: "game: " + selectedGame.title,
            dimension1: localStorage.getItem("user.id"),
          });


        }
      });
    }
  }, [selectedGameID]);

  const selectedBaseID = _.get(selectedBase, "_id");

  useEffect(() => {
    setAreVersionsLoaded(false);
    setSelectedVersion(null);
    if (selectedBaseID) {
      fetchVersions(selectedBaseID, localStorage.getItem("user.companyID")).then((fetchedVersions) => {
        setSelectedFolderId(9999999);
        if (_.size(fetchedVersions) === 0) {
          setIsInsertVersionDialogOpen(true);
        } else {
          if (!_.isNil(localStorage.getItem("selectedVersionID"))) {
            const userSelectedVersion = fetchedVersions.filter(
              (version) => _.get(version, "_id") === localStorage.getItem("selectedVersionID")
            )[0];
            if (!_.isNil(userSelectedVersion)) {
              setSelectedVersion(userSelectedVersion);
            } else {
              setSelectedVersion(fetchedVersions[0]);
              if (!_.isNil(_.get(fetchedVersions[0], "_id"))) {
                localStorage.setItem("selectedVersionID", _.get(fetchedVersions[0], "_id"));
              }
            }
          } else {
            setSelectedVersion(fetchedVersions[0]);
            if (!_.isNil(_.get(fetchedVersions[0], "_id"))) {
              localStorage.setItem("selectedVersionID", _.get(fetchedVersions[0], "_id"));
            }
          }
          ReactGA.event({
            category: "Game",
            action: "Base Changed",
            label: "game: " + selectedGame.title + "base: " + selectedBase.title,
            dimension1: localStorage.getItem("user.id"),
          });
          ReactGA4.event({
            category: "Game",
            action: "Base Changed",
            label: "game: " + selectedGame.title + "base: " + selectedBase.title,
            dimension1: localStorage.getItem("user.id"),
          });
        }
      });
      fetchImages()
        .then((fetchedImages) => {
          setAreMediaLoaded(true);
        })
        .catch(console.error);
      fetchAtlases()
        .then((fetchedAtlases) => {
          setAreAtlasesLoaded(true);
        })
        .catch(console.error);
      fetchSounds()
        .then((fetchedSounds) => {
          setAreSoundsLoaded(true);
        })
        .catch(console.error);
      fetchFonts()
        .then((fetchedFonts) => {
          setAreFontsLoaded(true);
        })
        .catch(console.error);
      fetchVideos()
        .then((fetchedFonts) => {
          setAreVideosLoaded(true);
        })
        .catch(console.error);
    }
  }, [selectedBaseID]);

  const selectedVersionID = _.get(selectedVersion, "_id");
  useEffect(() => {
    if (selectedVersion) {
      ReactGA.event({
        category: "Version",
        action: "Version Changed",
        label: "game: " + selectedGame.title + "base: " + selectedBase.title + "version: " + selectedVersion.title,
        dimension1: localStorage.getItem("user.id"),
      });
      ReactGA4.event({
        category: "Version",
        action: "Version Changed",
        label: "game: " + selectedGame.title + "base: " + selectedBase.title + "version: " + selectedVersion.title,
        dimension1: localStorage.getItem("user.id"),
      });
    }
    let isAutoplay = closeAutoplay(prevVersion);
    //notify game that version changed
    if (isAutoplay) {
      doRefresh((prev) => prev + 1);
    } else {
      versionChanged();
    }
    setPrevSelectedRecorderSettings(JSON.stringify(_.get(selectedVersion, "recorderSettings", {})));
    setPrevButton(_.get(selectedVersion, "gameConfig.components.autoplay1.startAutoplay"));
    applyPreview({ newGameConfig: _.get(selectedVersion, "gameConfig", {}), selectedBase });
    findDefaultVersionID();

  }, [selectedVersionID]);
  useEffect(() => {
    if (selectedFolderId == 9999999) {
      setMenuLayout(true);
    } else {
      setMenuLayout(false);
    }
  }, [selectedFolderId]);

  useEffect(() => {
    if (!_.isNil(_.get(selectedGame, "_id"))) {
      localStorage.setItem("selectedGameID", _.get(selectedGame, "_id"));
    }
  }, [selectedGame]);

  useEffect(() => {
    if (!_.isNil(_.get(selectedBase, "_id"))) {
      setPortrait(true);
      localStorage.setItem("selectedBaseID", _.get(selectedBase, "_id"));
    }
  }, [selectedBase]);
  useEffect(() => {
    if (versionHierarchy != null) {
      setOTreeData(versionHierarchy);
      setTreeData(versionHierarchy);
    }
  }, [versionHierarchy]);

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  //FEAT-2
  function getVersionIndexToShowAfterDeletion(versionID) {
    let versionIndexToSelect;
    versions.forEach((value, index, array) => {
      if (String(value._id) === String(versionID)) {
        if (array[index - 1] !== undefined && index !== 0) {
          versionIndexToSelect = index - 1;
        } else if (array[index + 1] !== undefined) {
          versionIndexToSelect = index + 1;
        }
      }
    });
    return versionIndexToSelect;
  }
  //FEAT-2
  const handleResize = () => {
    if (column1TopRef.current) {
      const height = column1TopRef.current.clientHeight;
      setHeightInPx(height);
    }
  };
  function getVersionIndexToShowAfterDeletionCustom(versionID, versionArray) {
    let versionIndexToSelect;
    versionArray.forEach((value, index, array) => {
      if (String(value._id) === String(versionID)) {
        if (array[index - 1] !== undefined && index !== 0) {
          versionIndexToSelect = index - 1;
        } else if (array[index + 1] !== undefined) {
          versionIndexToSelect = index + 1;
        }
      }
    });
    return versionIndexToSelect;
  }
  function deleteVersionFromView(versionID, isMultiple = false, multipleVersion = []) {
    //find the version

    let isVersionFound = false;
    versions.forEach((versionRecord) => {
      if (String(_.get(versionRecord, "_id")) === versionID) {
        isVersionFound = true;
        ReactGA.event({
          category: "Version",
          action: "Version Deleted",
          label: "game: " + selectedGame.title + "base: " + selectedBase.title + "version: " + versionRecord.title,
          dimension1: localStorage.getItem("user.id"),
        });
        ReactGA4.event({
          category: "Version",
          action: "Version Deleted",
          label: "game: " + selectedGame.title + "base: " + selectedBase.title + "version: " + versionRecord.title,
          dimension1: localStorage.getItem("user.id"),
        });
      }
    });
    if (!isVersionFound) throw "VERSION_NOT_FOUND";

    if (isMultiple) {
      let newVersions = _.clone(versions);
      multipleVersion.map((ver) => {
        let filteredVersions = newVersions.filter((versionRecord) => {
          return String(_.get(versionRecord, "_id")) !== ver;
        });

        setVersions(filteredVersions);
        if (_.size(filteredVersions) === 0) {
          setIsInsertVersionDialogOpen(true);
          setSelectedVersion(null);
        } else {
          let notSelected = true;
          for (let i = 0; i < filteredVersions.length; i++) {
            if (filteredVersions[i]._id == selectedVersionID) {
              notSelected = false;
              break;
            }
          }
          if (notSelected) {
            const newSelectedVersion = filteredVersions[0];
            //FEAT-2
            setSelectedVersion(newSelectedVersion);
          }
          newVersions = filteredVersions;
        }
      });
    } else {
      let newVersions = _.clone(versions);

      let filteredVersions = newVersions.filter((versionRecord) => {
        return String(_.get(versionRecord, "_id")) !== versionID;
      });

      if (_.size(filteredVersions) === 0) {
        setVersions(filteredVersions);
        setIsInsertVersionDialogOpen(true);
        setSelectedVersion(null);
      } else {
        const newSelectedVersion = filteredVersions[0];
        setVersions(filteredVersions);

        //FEAT-2
        setSelectedVersion(newSelectedVersion);
      }
    }
  }

  async function saveVersionAndApplyPreview({ newSelectedVersion }, isApplyPreview, disableUndoStack) {
    if (!_.has(newSelectedVersion, "_id")) {
      console.warn(
        "skipping saving version because versionid is not selected. " +
          "version will not be saved until you select a version " +
          "or a version is selected automatically"
      );
      return;
    }
    if (!_.has(newSelectedVersion, "gameConfig")) {
      console.warn("Selected Version has no gameConfig, won't save, won't apply preview");
      return;
    }

    if (!disableUndoStack) {
      if (typeof newSelectedVersion.gameConfig.components.autoplay1 !== "undefined") {
        if (prevButton !== newSelectedVersion.gameConfig.components.autoplay1.startAutoplay) {
          setPrevButton(newSelectedVersion.gameConfig.components.autoplay1.startAutoplay);

          return;
        } else if (prevSelectedRecorderSettings !== JSON.stringify(newSelectedVersion.recorderSettings)) {
          undoCtx.pushUndoStack(JSON.stringify(selectedVersion.recorderSettings));
          setPrevSelectedRecorderSettings(JSON.stringify(newSelectedVersion.recorderSettings));
        } else {
          undoCtx.pushUndoStack(selectedVersion.gameConfig);
        }
      } else {
        undoCtx.pushUndoStack(selectedVersion.gameConfig);
      }
    }

    let createdLanguages = newSelectedVersion.createdLanguages;

    if(!createdLanguages.some(language => language === newSelectedVersion.gameConfig.options.language)){

      createdLanguages.push(newSelectedVersion.gameConfig.options.language);

    }


    const fetchUri = getServerUri("version/updateOne");
    const fetchInit = {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        versionID: String(_.get(newSelectedVersion, "_id")),
        gameConfig: JSON.stringify(_.get(newSelectedVersion, "gameConfig", [])),
        recorderSettings: JSON.stringify(_.get(newSelectedVersion, "recorderSettings", null)),
        lastUpdatingUserID: String(_.get(newSelectedVersion, "lastUpdatingUserID", "")),
        createdLanguages: JSON.stringify(_.get(newSelectedVersion, "createdLanguages", createdLanguages)),
      }),
    };
    await fetch(fetchUri, fetchInit)
      .then((response) => {
        if (response.status === 200) {

          fetchVersions(newSelectedVersion.baseID, newSelectedVersion.companyID);
          //console.debug("saved version");
        } else if (response.status === 401) {
          createSnackbar("You are not authorized to update, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error updating", textResponse });
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log("version update failed: " + textResponse);
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
    // if (isApplyPreview) {

    //         applyPreview({
    //             newGameConfig: _.get(newSelectedVersion, `gameConfig`, []),
    //             selectedBase
    //         });

    // }
  }

  /**
   * Generic version update method
   * @param p.path
   * @param p.value
   */
  function setSelectedVersionPathValue(p, resetValue) {
    let filteredPath = p.path.substr(p.path.indexOf(".") + 1);
    if (_.has(p, "isLanguageChanged") && _.get(p, "isLanguageChanged")) {
      ReactGA.event({
        category: "Version",
        action: "Language Changed",
        label:
          "game: " +
          selectedGame.title +
          ", base: " +
          selectedBase.title +
          ", version: " +
          selectedVersion.title +
          ", path: " +
          filteredPath,
        dimension1: localStorage.getItem("user.id"),
      });
      ReactGA4.event({
        category: "Version",
        action: "Language Changed",
        label:
          "game: " +
          selectedGame.title +
          ", base: " +
          selectedBase.title +
          ", version: " +
          selectedVersion.title +
          ", path: " +
          filteredPath,
        dimension1: localStorage.getItem("user.id"),
      });
    }
    else {
      ReactGA.event({
        category: "Version",
        action: "Version Attribute Changed",
        label:
          "game: " +
          selectedGame.title +
          ", base: " +
          selectedBase.title +
          ", version: " +
          selectedVersion.title +
          ", path: " +
          filteredPath,
        dimension1: localStorage.getItem("user.id"),
      });
      ReactGA4.event({
        category: "Version",
        action: "Version Attribute Changed",
        label:
          "game: " +
          selectedGame.title +
          ", base: " +
          selectedBase.title +
          ", version: " +
          selectedVersion.title +
          ", path: " +
          filteredPath,
        dimension1: localStorage.getItem("user.id"),
      });
    }
    const newSelectedVersion = _.cloneDeep(selectedVersion);
    _.set(newSelectedVersion, p.path, p.value);
    const versionIndex = versions.findIndex((value) => {
      return _.get(value, "_id") === _.get(selectedVersion, "_id");
    });
    versions[versionIndex] = newSelectedVersion;

    applyPreview({
      newGameConfig: _.get(newSelectedVersion, "gameConfig", []),
      selectedBase,
    });

    if (timeoutID !== null) {
      clearTimeout(timeoutID);
    }

    timeoutID = setTimeout(() => {
      setSelectedVersion(newSelectedVersion);

      saveVersionAndApplyPreview({ newSelectedVersion }, true, resetValue);
    }, 200);
  }

  /**
   * Close autoplay of previous version method
   * @param p.path
   * @param p.value
   * @param version previous version
   */
  function setSelectedVersionPathValueWithVersion(p, version) {
    const newSelectedVersion = _.cloneDeep(version);
    _.set(newSelectedVersion, p.path, p.value);
    const versionIndex = versions.findIndex((value) => {
      return _.get(value, "_id") === _.get(version, "_id");
    });
    versions[versionIndex] = newSelectedVersion;
    saveVersionAndApplyPreview({ newSelectedVersion }, false);
  }

  /**
   * @param {String} p.path
   * @param {Array} p.value
   */
  function insertMediaPathValue(p) {
    let newMedia = _.cloneDeep(media);
    if (!_.has(newMedia, p.path)) {
      _.set(newMedia, p.path, []);
    }
    _.get(newMedia, p.path).push(...p.value);
    setMedia(newMedia);
  }

  function deleteImageFromMedia(imageID) {
    let newMedia = _.cloneDeep(media);
    if (_.isNil(newMedia)) {
      console.error("newMedia is null");
      return;
    }
    if (!_.has(newMedia, "images")) {
      _.set(newMedia, "images", []);
    }
    newMedia.images = newMedia.images.filter((image) => image._id !== imageID);
    setMedia(newMedia);
  }

  function deleteImageSetFromMedia(imageSetID) {
    let newMedia = _.cloneDeepWith(media);
    if (!_.has(newMedia, "images")) {
      _.set(newMedia, "images", []);
    }
    newMedia.images = newMedia.images.filter((image) => image.imageSet !== imageSetID);
    setMedia(newMedia);
  }

  function insertAtlasPathValue(value) {
    let newAtlases = _.cloneDeep(atlases);
    newAtlases.push(...value);

    setAtlases(newAtlases);
  }

  function insertSoundPathValue(value) {
    let newSounds = _.cloneDeep(sounds);
    newSounds.push(...value);

    setSounds(newSounds);
  }

  function insertFontPathValue(value) {
    let newFonts = _.cloneDeep(fonts);
    newFonts.push(...value);

    setFonts(newFonts);
  }

  function insertVideoPathValue(value) {
    let newVideos = _.cloneDeep(videos);
    newVideos.push(...value);

    setVideos(newVideos);
  }

  function deleteFontFromFonts(fontID) {
    let newFonts = _.cloneDeep(fonts);
    if (_.isNil(newFonts)) {
      console.error("newFonts is null");
      return;
    }
    newFonts = newFonts.filter((font) => font._id !== fontID);
    setFonts(newFonts);
  }

  function deleteSoundFromSounds(soundID) {
    let newSounds = _.cloneDeep(sounds);
    if (_.isNil(newSounds)) {
      console.error("newSounds is null");
      return;
    }
    newSounds = newSounds.filter((sound) => sound._id !== soundID);
    setSounds(newSounds);
  }

  function deleteVideoFromVideos(videoID) {
    let newVideos = _.cloneDeep(videos);
    if (_.isNil(newVideos)) {
      console.error("newVideos is null");
      return;
    }
    newVideos = newVideos.filter((video) => video._id !== videoID);
    setVideos(newVideos);
  }

  function deleteAnimationSetFromAtlases(animationSetID) {
    let newAtlases = _.cloneDeep(atlases);
    if (_.isNil(newAtlases)) {
      console.error("newAtlases is null");
      return;
    }
    newAtlases = newAtlases.filter((atlas) => atlas.animationSet !== animationSetID);
    setAtlases(newAtlases);
  }

  function findDefaultVersionID(){

    if(!selectedBase){
      return; 
    }
    return new Promise((resolve, reject) => {
      fetch(getServerUri("version/findDefaultVersion"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          baseID: String(selectedBase?._id),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((fetchedVersion) => {
              setDefaultVersion(fetchedVersion);
              setDefaultVersionID(fetchedVersion?.versionID);
              resolve(fetchedVersion);
            });
          } else {
            response
              .text()
              .then((err) => reject(err))
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => reject(err));
    });
  }

  function addDefaultVersionImagesToGameConfig(gameConfig, defaultVersionInfo) {
    let newGameConfig = _.cloneDeep(gameConfig);

    selectedBase.versionSettings.components.forEach((component,index) => {

      component.settings.forEach((setting) => {
        const childComponents = _.get(setting, "components", []);
        childComponents.forEach((childComponent) => {

          childComponent.settings.forEach((childSetting) => {
            if (childSetting.editorType === "selectorImage") {
              let images = media.images.filter((image) => image.bucket === childSetting.bucket);

              const defaultVersionImageId = _.get(defaultVersionInfo, `gameConfig.components.${childComponent.id}.${childSetting.path}`)[0]?._id;
              let imageIndex = images.indexOf(images.filter((image) => image._id == defaultVersionImageId)[0]);

              imageIndex = imageIndex > 0 ? imageIndex : 0;


              let firstImage = _.clone(images[imageIndex]);
              if (images.length > 0) {
                _.set(firstImage, "destination", _.get(childSetting, "destination"));
                _.set(newGameConfig, `components.${childComponent.id}.${childSetting.path}[0]`, firstImage);
              }
            }
            else if (childSetting.editorType === "selectorAtlas") {

              let filteredAtlases = atlases.filter((atlas) => atlas.bucket === childSetting.bucket);

              const defaultVersionAnimationSet = _.get(defaultVersionInfo, `gameConfig.components.${childComponent.id}.${childSetting.path}`)[0].animationSet;
              let animationIndex = filteredAtlases.indexOf(filteredAtlases.filter((atlas) => atlas.animationSet== defaultVersionAnimationSet)[0]);
              animationIndex = animationIndex > 0 ? animationIndex : 0;

              let defaultAnimationSet = filteredAtlases.filter(
                (atlas) => atlas.animationSet === filteredAtlases[animationIndex].animationSet
              );
              if (defaultAnimationSet.length > 0) {
                //we have to deeply clone first animation set, since last selectorAtlases that has same buckets info,
                // manipulates destination info of other selectorAtlases that has same buckets info

                //deep clone of first animation set
                const firstAnimationSetDeepClone = _.cloneDeep(defaultAnimationSet);
                firstAnimationSetDeepClone.forEach((atlas) => {
                  _.set(atlas, "destination", childSetting.destinations[atlas.variant]);
                });
                _.set(newGameConfig, `components.${childComponent.id}.${childSetting.path}`, firstAnimationSetDeepClone);
              }

            }
          });
        });

        // for decision maker settings
        const childSettings = _.get(setting, "settings", []);
        childSettings.forEach((childSetting) => {
          if (childSetting.editorType === "selectorImage") {

            let images = media.images.filter((image) => image.bucket === childSetting.bucket);

            const defaultVersionImageId = _.get(defaultVersionInfo, `gameConfig.components.${component.id}.${childSetting.path}`)[0]?._id;
            let imageIndex = images.indexOf(images.filter((image) => image._id == defaultVersionImageId)[0]);

            imageIndex = imageIndex > 0 ? imageIndex : 0;


            let firstImage = _.clone(images[imageIndex]);
            if (images.length > 0) {
              _.set(firstImage, "destination", _.get(childSetting, "destination"));
              _.set(newGameConfig, `components.${component.id}.${childSetting.path}[0]`, firstImage);
            }
          }
        });


        if (setting.editorType === "selectorImage") {
          let images = media.images.filter((image) => image.bucket === setting.bucket);

          const defaultVersionImageId = _.get(defaultVersionInfo, `gameConfig.components.[${component.id}].${setting.path}`)[0]._id;
          let imageIndex = images.indexOf(images.filter((image) => image._id == defaultVersionImageId)[0]);
          imageIndex = imageIndex > 0 ? imageIndex : 0;
          let defaultImage = _.clone(images[imageIndex]);

          if (images.length > 0) {
            _.set(defaultImage, "destination", _.get(setting, "destination"));
            _.set(newGameConfig, `components.${component.id}.${setting.path}[0]`, defaultImage);
          }
        } else if (setting.editorType === "selectorImageFullPath") {

          let images = media.images.filter((image) => image.bucket === setting.bucket);

          const defaultVersionImageId = _.get(defaultVersionInfo, `gameConfig.components.${setting.path}`)[0]?._id;
          let imageIndex = images.indexOf(images.filter((image) => image._id == defaultVersionImageId)[0]);
          imageIndex = imageIndex > 0 ? imageIndex : 0;

          let defaultImage = _.clone(images[imageIndex]);
          if (images.length > 0) {
            _.set(defaultImage, "destination", _.get(setting, "destination"));
            _.set(newGameConfig, `components.${setting.path}[0]`, defaultImage);
          }
        } else if (setting.editorType === "selectorSound") {
          let filteredSounds = sounds.filter((sound) => sound.bucket === setting.bucket);

          const defaultVersionSoundId = _.get(defaultVersionInfo, `gameConfig.components.${setting.path}`)[0]?._id;
          let soundIndex = filteredSounds.indexOf(filteredSounds.filter((sound) => sound._id == defaultVersionSoundId)[0]);
          soundIndex = soundIndex > 0 ? soundIndex : 0;
          let defaultSound = _.clone(filteredSounds[soundIndex]);

          if (filteredSounds.length > 0) {
            _.set(defaultSound, "destination", _.get(setting, "destination"));
            _.set(newGameConfig, `components.${setting.path}[0]`, defaultSound);
          }
        } else if (setting.editorType === "selectorFont") {
          let filteredFonts = _.clone(fonts);
          const defaultVersionFontId = _.get(defaultVersionInfo, `gameConfig.components.${component.id}.${setting.path}`)?._id;
          let fontIndex = filteredFonts.indexOf(filteredFonts.filter((font) => font._id == defaultVersionFontId)[0]);
          fontIndex = fontIndex > 0 ? fontIndex : 0;

          let font = !_.isNil(filteredFonts[index]) ? filteredFonts[index] : filteredFonts[fontIndex];
          const defaultFont = _.cloneDeep(font);
          if (filteredFonts.length > 0) {
            _.set(defaultFont, "destination", _.get(setting, "destination"));
            _.set(newGameConfig, `components.${component.id}.${setting.path}`, defaultFont);
          }
        } else if (setting.editorType === "selectorImageSet") {
          let images = media.images.filter((image) => image.bucket === setting.bucket);

          const defaultVersionImageSet = _.get(defaultVersionInfo, `gameConfig.components.${component.id}.${setting.path}`)[0]?.imageSet;
          let imageIndex = images.indexOf(images.filter((image) => image.imageSet== defaultVersionImageSet)[0]);
          imageIndex = imageIndex > 0 ? imageIndex : 0;

          let defaultImageSet = images.filter((image) => image.imageSet === images[imageIndex].imageSet);


          if (defaultImageSet.length > 0) {
            //we have to deeply clone first image set, since last selectorImageSet that has same buckets info,
            // manipulates destination info of other selectorImageSets that has same buckets info

            //deep clone of first image set
            const firstImageSetDeepClone = _.cloneDeep(defaultImageSet);
            firstImageSetDeepClone.forEach((image) => {
              _.set(image, "destination", setting.destinations[image.variant]);
            });
            _.set(newGameConfig, `components.${component.id}.${setting.path}`, firstImageSetDeepClone);
          }
        } else if (setting.editorType === "selectorAtlas") {
          let filteredAtlases = atlases.filter((atlas) => atlas.bucket === setting.bucket);

          const defaultVersionAnimationSet = _.get(defaultVersionInfo, `gameConfig.components.${component.id}.${setting.path}`)[0]?.animationSet;
          let animationIndex = filteredAtlases.indexOf(filteredAtlases.filter((atlas) => atlas.animationSet== defaultVersionAnimationSet)[0]);
          animationIndex = animationIndex > 0 ? animationIndex : 0;

          let defaultAnimationSet = filteredAtlases.filter(
            (atlas) => atlas.animationSet === filteredAtlases[animationIndex].animationSet
          );
          if (defaultAnimationSet.length > 0) {
            //we have to deeply clone first animation set, since last selectorAtlases that has same buckets info,
            // manipulates destination info of other selectorAtlases that has same buckets info

            //deep clone of first animation set
            const firstAnimationSetDeepClone = _.cloneDeep(defaultAnimationSet);
            firstAnimationSetDeepClone.forEach((atlas) => {
              _.set(atlas, "destination", setting.destinations[atlas.variant]);
            });
            _.set(newGameConfig, `components.${component.id}.${setting.path}`, firstAnimationSetDeepClone);
          }
        } else if (setting.editorType === "selectorVideo") {
          const filteredVideos = videos.filter((video) => video.bucket === setting.bucket);

          const defaultVersionVideoId = _.get(defaultVersionInfo, `gameConfig.components.${component.id}.${setting.path}`)[0]?._id;
          let videoIndex = filteredVideos.indexOf(filteredVideos.filter((video) => video._id == defaultVersionVideoId)[0]) ;
          videoIndex = videoIndex > 0 ? videoIndex : 0;

          const defaultVideo = _.clone(filteredVideos[videoIndex]);
          if (filteredVideos.length > 0) {
            _.set(defaultVideo, "destination", _.get(setting, "destination"));
            _.set(newGameConfig, `components.${component.id}.${setting.path}[0]`, defaultVideo);
          }
        }
      });
    });

    //autoplay hand initial value
    if (!_.isNil(selectedBase.recorderSettings)) {
      selectedBase.recorderSettings.components.forEach((component) => {
        component.settings.forEach((setting) => {
          if (setting.editorType === "selectorImage") {
            let images = media.images.filter((image) => image.bucket === setting.bucket);
            const defaultVersionImageId = _.get(defaultVersionInfo, `gameConfig.components.${component.id}.${setting.path}`)[0]._id;
            let imageIndex = images.indexOf(images.filter((image) => image._id == defaultVersionImageId)[0]);
            imageIndex = imageIndex > 0 ? imageIndex : 0;
            let defaultImage = _.clone(images[imageIndex]);

            if (images.length > 0) {
              _.set(defaultImage, "destination", _.get(setting, "destination"));
              _.set(newGameConfig, `components.${component.id}.${setting.path}[0]`, defaultImage);
            }
          } else if (setting.editorType === "selectorImageFullPath") {
            let images = media.images.filter((image) => image.bucket === setting.bucket);
            const defaultVersionImageId = _.get(defaultVersionInfo, `gameConfig.components.${setting.path}`)[0]._id;
            let imageIndex = images.indexOf(images.filter((image) => image._id == defaultVersionImageId)[0]);
            imageIndex = imageIndex > 0 ? imageIndex : 0;
            let defaultImage = _.clone(images[imageIndex]);
            if (images.length > 0) {
              _.set(defaultImage, "destination", _.get(setting, "destination"));
              _.set(newGameConfig, `components.${setting.path}[0]`, defaultImage);
            }
          }
        });
      });
    }

    return newGameConfig;
  }


  function addDefaultImagesToGameConfig(gameConfig) {
    let newGameConfig = _.cloneDeep(gameConfig);



    selectedBase.versionSettings.components.forEach((component,index) => {

      component.settings.forEach((setting) => {
        const childComponents = _.get(setting, "components", []);
        childComponents.forEach((childComponent) => {

          childComponent.settings.forEach((childSetting) => {
            if (childSetting.editorType === "selectorImage") {
              let images = media.images.filter((image) => image.bucket === childSetting.bucket);

              let firstImage = _.clone(images[0]);
              if (images.length > 0) {
                _.set(firstImage, "destination", _.get(childSetting, "destination"));
                _.set(newGameConfig, `components.${childComponent.id}.${childSetting.path}[0]`, firstImage);
              }
            }
          });
        });

        // for decision maker settings
        const childSettings = _.get(setting, "settings", []);
        childSettings.forEach((childSetting) => {
          if (childSetting.editorType === "selectorImage") {

            let images = media.images.filter((image) => image.bucket === childSetting.bucket);

            let firstImage = _.clone(images[0]);
            if (images.length > 0) {
              _.set(firstImage, "destination", _.get(childSetting, "destination"));
              _.set(newGameConfig, `components.${component.id}.${childSetting.path}[0]`, firstImage);
            }
          }
        });


        if (setting.editorType === "selectorImage") {
          let images = media.images.filter((image) => image.bucket === setting.bucket);

          let firstImage = _.clone(images[0]);
          if (images.length > 0) {
            _.set(firstImage, "destination", _.get(setting, "destination"));
            _.set(newGameConfig, `components.${component.id}.${setting.path}[0]`, firstImage);
          }
        } else if (setting.editorType === "selectorImageFullPath") {

          let images = media.images.filter((image) => image.bucket === setting.bucket);

          let firstImage = _.clone(images[0]);
          if (images.length > 0) {
            _.set(firstImage, "destination", _.get(setting, "destination"));
            _.set(newGameConfig, `components.${setting.path}[0]`, firstImage);
          }
        } else if (setting.editorType === "selectorSound") {
          let filteredSounds = sounds.filter((sound) => sound.bucket === setting.bucket);

          let firstSound = _.clone(filteredSounds[0]);
          if (filteredSounds.length > 0) {
            _.set(firstSound, "destination", _.get(setting, "destination"));
            _.set(newGameConfig, `components.${setting.path}[0]`, firstSound);
          }
        } else if (setting.editorType === "selectorFont") {
          let filteredFonts = _.clone(fonts);
          let font = !_.isNil(filteredFonts[index]) ? filteredFonts[index] : filteredFonts[0];
          const firstFont = _.cloneDeep(font);
          if (filteredFonts.length > 0) {
            _.set(firstFont, "destination", _.get(setting, "destination"));
            _.set(newGameConfig, `components.${component.id}.${setting.path}`, firstFont);
          }
        } else if (setting.editorType === "selectorImageSet") {
          let images = media.images.filter((image) => image.bucket === setting.bucket);

          let firstImageSet = images.filter((image) => image.imageSet === images[0].imageSet);

          if (firstImageSet.length > 0) {
            //we have to deeply clone first image set, since last selectorImageSet that has same buckets info,
            // manipulates destination info of other selectorImageSets that has same buckets info

            //deep clone of first image set
            const firstImageSetDeepClone = _.cloneDeep(firstImageSet);
            firstImageSetDeepClone.forEach((image) => {
              _.set(image, "destination", setting.destinations[image.variant]);
            });
            _.set(newGameConfig, `components.${component.id}.${setting.path}`, firstImageSetDeepClone);
          }
        } else if (setting.editorType === "selectorAtlas") {
          let filteredAtlases = atlases.filter((atlas) => atlas.bucket === setting.bucket);

          let firstAnimationSet = filteredAtlases.filter(
            (atlas) => atlas.animationSet === filteredAtlases[0].animationSet
          );
          if (firstAnimationSet.length > 0) {
            //we have to deeply clone first animation set, since last selectorAtlases that has same buckets info,
            // manipulates destination info of other selectorAtlases that has same buckets info

            //deep clone of first animation set
            const firstAnimationSetDeepClone = _.cloneDeep(firstAnimationSet);
            firstAnimationSetDeepClone.forEach((atlas) => {
              _.set(atlas, "destination", setting.destinations[atlas.variant]);
            });
            _.set(newGameConfig, `components.${component.id}.${setting.path}`, firstAnimationSetDeepClone);
          }
        } else if (setting.editorType === "selectorVideo") {
          const filteredVideos = videos.filter((video) => video.bucket === setting.bucket);

          const firstVideo = _.clone(filteredVideos[0]);
          if (filteredVideos.length > 0) {
            _.set(firstVideo, "destination", _.get(setting, "destination"));
            _.set(newGameConfig, `components.${component.id}.${setting.path}[0]`, firstVideo);
          }
        }
      });
    });

    //autoplay hand initial value
    if (!_.isNil(selectedBase.recorderSettings)) {
      selectedBase.recorderSettings.components.forEach((component) => {
        component.settings.forEach((setting) => {
          if (setting.editorType === "selectorImage") {
            let images = media.images.filter((image) => image.bucket === setting.bucket);
            let firstImage = _.clone(images[0]);
            if (images.length > 0) {
              _.set(firstImage, "destination", _.get(setting, "destination"));
              _.set(newGameConfig, `components.${component.id}.${setting.path}[0]`, firstImage);
            }
          } else if (setting.editorType === "selectorImageFullPath") {
            let images = media.images.filter((image) => image.bucket === setting.bucket);
            let firstImage = _.clone(images[0]);
            if (images.length > 0) {
              _.set(firstImage, "destination", _.get(setting, "destination"));
              _.set(newGameConfig, `components.${setting.path}[0]`, firstImage);
            }
          }
        });
      });
    }

    return newGameConfig;
  }

  function makeid(length) {
    var result = "";
    var characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  /**
   * Creates snackbar for notifications
   * Max count is 3
   * @param {string} message text written in snackbar label
   * @param {string} variant success|error|warning|info
   * @returns snackbar object
   */
  function createSnackbar(message, variant, isPersist) {
    let vertical = "bottom";
    let horizontal = "center";

    let opts = {
      key: !isPersist ? "sameKey" : makeid(10),
      variant,
      anchorOrigin: {
        vertical,
        horizontal,
      },
      preventDuplicate: true,
      autoHideDuration: 4000,
      TransitionComponent: Slide,
      persist: isPersist,
    };
    return enqueueSnackbar(message, opts);
  }

  let isLoading =
    !isTokenValidated ||
    !areGamesLoaded ||
    !areBasesLoaded ||
    !areVersionsLoaded ||
    !arePlansLoaded ||
    !arePermissionsLoaded;
  if (areVersionsLoaded && _.size(versions) > 0) {
    isLoading &= isPreviewLoaded;
  }
  if (isLoading) {
    return circularProgressDiv();
  } else if (_.isNil(userPermissions)) {
    return <Redirect to="/login" />;
  } else
    return (
      <div id={"homeDiv"} className={classes.homeDiv}>
        <div id={"column1"} className={classes.column1}>
          <div id={"column1Top"} ref={column1TopRef} className={classes.column1Top}>
            <div id="gamesOuter_div" style={{ flex: 1 }}>
              <ImageAccountItem
                classes={classes}
                onClick={() => {
                  setIsAccountInfoVisible(true);
                }}
              />
              <DialogAccountSettings
                isAccountInfoVisible={isAccountInfoVisible}
                setIsAccountInfoVisible={setIsAccountInfoVisible}
                plans={plans}
                games={games.concat(galleryGames)}
                classes={classes}
                userCompany={userCompany}
                userName={localStorage.getItem("user.username")}
                userEmail={localStorage.getItem("user.emailAddress")}
                numberOfBases={numberOfBases}
                onClose={() => {
                  setIsAccountInfoVisible(false);
                }}
                isAdmin={isAdmin}
                userPermissions={userPermissions}
                createSnackbar={createSnackbar}
                getPlanIndex={getPlanIndex}
                selectedVersion={selectedVersion}
                setIsVersionBeingUpdatedFalse={setIsVersionBeingUpdatedFalse}
              />
              {_.get(userCompany, "hasPlayableGallery", false) && (
                <PlayableGallery
                  classes={classes}
                  galleryGames={galleryGames}
                  selectedGame={selectedGame}
                  setSelectedGame={setSelectedGame}
                  selectedVersion={selectedVersion}
                  versions={versions}
                  setVersions={setVersions}
                  userPermissions={userPermissions}
                  createSnackbar={createSnackbar}
                />
              )}
              <SelectorGame
                shouldLog={true}
                items={games}
                classes={classes}
                value={selectedGameID}
                setValue={setSelectedGame}
                selectedVersion={selectedVersion}
                versions={versions}
                setVersions={setVersions}
                userPermissions={userPermissions}
              />
            </div>
          </div>
          <BasicPopover height="50px" />
        </div>
        <div id={"column2"} className={classes.column2}>
          <div id={"column2Top"} className={classes.column2Top}>
            <div style={{ flex: 1 }}>
              <Typography id="plusIconTypography" style={{ fontSize: "15px" }}>
                {_.get(selectedGame, "title", "")}
              </Typography>
              <BasePicker
                items={bases}
                classes={classes}
                selectedItem={selectedBase}
                setSelectedItem={setSelectedBase}
                shouldLog={true}
                selectedVersion={selectedVersion}
                versions={versions}
                setVersions={setVersions}
                userPermissions={userPermissions}
              ></BasePicker>
            </div>
            <VersionStorageOptions
              setIsInsertVersionDialogOpen={setIsInsertVersionDialogOpen}
              setIsFolderVersionDialogOpen={setIsFolderVersionDialogOpen}
              userPermissions={userPermissions}
              createSnackbar={createSnackbar}
            />
            <VersionSelectorOptions
              setIsRenameVersionDialogOpen={setIsRenameVersionDialogOpen}
              setIsRenameFolderDialogOpen={setIsRenameFolderDialogOpen}
              userID={localStorage.getItem("user.id")}
              setIsDeleteDialogOpen={setIsDeleteDialogOpen}
              setIsDuplicateDialogOpen={setIsDuplicateDialogOpen}
              setIsDownloadDialogOpen={setIsDownloadDialogOpen}
              setIsDeleteAllDialogOpen={setIsDeleteAllDialogOpen}
              setIsFolderDialogOpen={setIsFolderDialogOpen}
              userPermissions={userPermissions}
              menuLayout={menuLayout}
              createSnackbar={createSnackbar}
            />
            <DialogVersionInsert
              open={isInsertVersionDialogOpen}
              treeIsSorted={treeIsSorted}
              selectedGame={selectedGame}
              selectedBase={selectedBase}
              selectedVersion={selectedVersion}
              companyID={localStorage.getItem("user.companyID")}
              setSearchTerm={(value) => {
                setSearchTerm(value);
              }}
              setSelectedVersion={(value) => {
                setSelectedVersion(value);
              }}
              setSortAgain={(value) => {
                setSortAgain(value);
              }}
              setSelectedFolderId={(value) => {
                setSelectedFolderId(value);
              }}
              setOpen={(value) => {
                setIsInsertVersionDialogOpen(value);
              }}
              setDefaultVersion={(value) => {
                setDefaultVersion(value);
              }}
              setInsertNewVersion={(value) => {
                setInsertNewVersion(value);
              }}
              selectedFolderId={selectedFolderId}
              fetchVersions={fetchVersions}
              insertNewVersion={insertNewVersion}
              createSnackbar={createSnackbar}
              defaultVersionUpdated={defaultVersionUpdated}
              defaultVersion={defaultVersion ? defaultVersion : versions.filter((version) => version._id == defaultVersionID)[0]}
            />
            <DialogFolderInsert
              open={isFolderVersionDialogOpen}
              selectedGame={selectedGame}
              selectedBase={selectedBase}
              selectedVersion={selectedVersion}
              companyID={localStorage.getItem("user.companyID")}
              setSearchTerm={(value) => {
                setSearchTerm(value);
              }}
              setOpen={(value) => {
                setIsFolderVersionDialogOpen(value);
              }}
              createSnackbar={createSnackbar}
              //folderları renderlamak için oluşturulan global
              setUpdateTreeOpen={(value) => {
                setUpdateTreeOpen(value);
              }}
              insertNewVersion={insertNewVersion}
            />
            <DialogVersionDelete
              isOpen={isDeleteDialogOpen}
              deleteVersionFromView={(versionID, isMultiple, multipleVersion) => {
                try {
                  deleteVersionFromView(versionID, isMultiple, multipleVersion);
                } catch (err) {
                  console.error(err);
                }
              }}
              setSearchTerm={(value) => {
                setSearchTerm(value);
              }}
              companyID={localStorage.getItem("user.companyID")}
              selectedVersion={selectedVersion}
              setRenameFolderName={renameFolderName}
              setSelectedFolderId={(value) => {
                setSelectedFolderId(value);
              }}
              setIsOpen={(value) => {
                setIsDeleteDialogOpen(value);
              }}
              setInsertNewVersion={(value) => {
                setInsertNewVersion(value);
              }}
              setVersionHierarchy={(value) => {
                setUpdateTreeOpen(value);
              }}
              setDefaultVersionUpdated={(value) => {
                setDefaultVersionUpdated(value);
              }}
              selectedFolderId={selectedFolderId}
              selectedBase={selectedBase}
              createSnackbar={createSnackbar}
              userPermissions={userPermissions}
              defaultVersion={defaultVersion ? defaultVersion : versions.filter((version) => version._id == defaultVersionID)[0]}
              defaultVersionUpdated={defaultVersionUpdated}
            ></DialogVersionDelete>
            <DialogDeleteAllVersions
              isOpen={isDeleteAllDialogOpen}
              deleteVersionFromView={(versionID, isMultiple, multipleVersion) => {
                try {
                  deleteVersionFromView(versionID, isMultiple, multipleVersion);
                } catch (err) {
                  console.error(err);
                }
              }}
              companyID={localStorage.getItem("user.companyID")}
              userID={localStorage.getItem("user.id")}
              selectedVersion={selectedVersion}
              setRenameFolderName={renameFolderName}
              setSelectedFolderId={(value) => {
                setSelectedFolderId(value);
              }}
              setInsertNewVersion={(value) => {
                setInsertNewVersion(value);
              }}
              setVersionHierarchy={(value) => {
                setUpdateTreeOpen(value);
              }}
              setIsOpen={(value) => {
                setIsDeleteAllDialogOpen(value);
              }}
              selectedFolderId={selectedFolderId}
              selectedBase={selectedBase}
              createSnackbar={createSnackbar}
              userPermissions={userPermissions}
            ></DialogDeleteAllVersions>
            {menuLayout ? (
              <DialogVersionDuplicate
                onClose={(value) => {
                  setIsDuplicateDialogOpen(value);
                }}
                isOpen={isDuplicateDialogOpen}
                setVersionHierarchy={(value) => {
                  setUpdateTreeOpen(value);
                }}
                setSearchTerm={(value) => {
                  setSearchTerm(value);
                }}
                companyID={localStorage.getItem("user.companyID")}
                menuLayout={menuLayout}
                selectedVersion={selectedVersion}
                selectedBase={selectedBase}
                userCompany={userCompany}
                setUserCompany={setUserCompany}
                fetchVersions={fetchVersions}
                setRenameFolderName={renameFolderName}
                setSelectedVersion={setSelectedVersion}
                selectedGame={selectedGame}
                classes={props.classes}
                createSnackbar={createSnackbar}
              ></DialogVersionDuplicate>
            ) : (
              <DialogFolderDuplicate
                onClose={(value) => {
                  setIsFolderDialogOpen(value);
                }}
                setRenameFolderName={renameFolderName}
                setVersionHierarchy={(value) => {
                  setUpdateTreeOpen(value);
                }}
                setSearchTerm={(value) => {
                  setSearchTerm(value);
                }}
                menuLayout={menuLayout}
                selectedFolderId={selectedFolderId}
                updateHierarchyFolder={(value) => {
                  setVersionHierarchy(value);
                }}
                setSelectedFolderId={(value) => {
                  setSelectedFolderId(value);
                }}
                companyID={localStorage.getItem("user.companyID")}
                //baseID={baseIDProps}
                isOpen={isFolderDialogOpen}
                //selecteFolderId={selectedFolderId}
                selectedVersion={selectedVersion}
                selectedBase={selectedBase}
                userCompany={userCompany}
                setUserCompany={setUserCompany}
                fetchVersions={fetchVersions}
                selectedGame={selectedGame}
                classes={props.classes}
                createSnackbar={createSnackbar}
              ></DialogFolderDuplicate>
            )}

            <DialogVersionRename
              open={isRenameVersionDialogOpen}
              selectedGame={selectedGame}
              setSearchTerm={(value) => {
                setSearchTerm(value);
              }}
              selectedBase={selectedBase}
              selectedVersion={selectedVersion}
              companyID={localStorage.getItem("user.companyID")}
              setOpen={(value) => {
                setIsRenameVersionDialogOpen(value);
              }}
              setRenameVersionName={(value) => {
                setRenameVersionName(value);
              }}
              renameVersion={(newVersionName) => {
                const newVersions = _.clone(versions);
                newVersions[newVersions.indexOf(newVersions.filter((e) => e._id === selectedVersion._id)[0])].title =
                  newVersionName;
                newVersions[
                  newVersions.indexOf(newVersions.filter((e) => e._id === selectedVersion._id)[0])
                ].dateUpdated = new Date();
                console.log({
                  messages: "version renamed. the array is: ",
                  newVersions,
                });

                setVersions(newVersions);

                ReactGA.event({
                  category: "Version",
                  action: "Version Updated",
                  label:
                    "game: " +
                    selectedGame.title +
                    ", base: " +
                    selectedBase.title +
                    ", version: " +
                    selectedVersion.title,
                  dimension1: localStorage.getItem("user.id"),
                });
                ReactGA4.event({
                  category: "Version",
                  action: "Version Updated",
                  label:
                    "game: " +
                    selectedGame.title +
                    ", base: " +
                    selectedBase.title +
                    ", version: " +
                    selectedVersion.title,
                  dimension1: localStorage.getItem("user.id"),
                });
              }}
              createSnackbar={createSnackbar}
              setSelectedVersionPathValue={setSelectedVersionPathValue}
            />
            <DialogFolderRename
              selectedFolderId={selectedFolderId}
              open={isRenameFolderDialogOpen}
              selectedGame={selectedGame}
              selectedBase={selectedBase}
              selectedVersion={selectedVersion}
              companyID={localStorage.getItem("user.companyID")}
              isOpen={isRenameFolderDialogOpen}
              setOpen={(value) => {
                setIsRenameFolderDialogOpen(value);
              }}
              setVersionHierarchy={(value) => {
                setUpdateTreeOpen(value);
              }}
              //get props.node from custom node
              setRenameFolderName={renameFolderName}
              setUpdateTreeOpen={(value) => {
                setUpdateTreeOpen(value);
              }}
              renameVersion={(newVersionName) => {
                const newVersions = _.clone(versions);
                newVersions[newVersions.indexOf(newVersions.filter((e) => e._id === selectedVersion._id)[0])].title =
                  newVersionName;
                newVersions[
                  newVersions.indexOf(newVersions.filter((e) => e._id === selectedVersion._id)[0])
                ].dateUpdated = new Date();
                console.log({
                  messages: "version renamed. the array is: ",
                  newVersions,
                });

                setVersions(newVersions);

                ReactGA.event({
                  category: "Version",
                  action: "Version Updated",
                  label:
                    "game: " +
                    selectedGame.title +
                    ", base: " +
                    selectedBase.title +
                    ", version: " +
                    selectedVersion.title,
                  dimension1: localStorage.getItem("user.id"),
                });
                ReactGA4.event({
                  category: "Version",
                  action: "Version Updated",
                  label:
                    "game: " +
                    selectedGame.title +
                    ", base: " +
                    selectedBase.title +
                    ", version: " +
                    selectedVersion.title,
                  dimension1: localStorage.getItem("user.id"),
                });
              }}
              createSnackbar={createSnackbar}
              setSelectedVersionPathValue={setSelectedVersionPathValue}
            />
          </div>
          <CustomDragComponent
            versionHierarchy={versionHierarchy}
            setSortedTree={(value) => {
              setSortedTree(value);
            }}
            heightInPx={heightInPx}
            searchTerm={searchTerm}
            sortAgain={sortAgain}
            baseID={baseIDProps}
            newVersionHierarchy={props.versionHierarchy}
            versions={versions}
            treeUpdate={isUpdateTreeOpen}
            selectedBase={selectedBase}
            selectedGame={selectedGame}
            createSnackbar={createSnackbar}
            companyID={localStorage.getItem("user.companyID")}
            versionUpdate={isInsertNewVersion}
            versionRename={renameVersionName}
            updateHierarchyFolder={(value) => {
              setVersionHierarchy(value);
            }}
            selectedFolder={(value) => {
              setSelectedFolderId(value);
            }}
            setVersions={setVersions}
            selectedVersion={selectedVersion}
            selectedFolderId={selectedFolderId}
            setValue={setSelectedVersion}
            setSelectedVersionPathValueWithVersion={setSelectedVersionPathValueWithVersion}
            userPermissions={userPermissions}
            defaultVersion={defaultVersion}
            defaultVersionUpdated={defaultVersionUpdated}
            setIsDialogCircularProgressOpen={setIsDialogCircularProgressOpen}
            searchBarValue = {searchBarValue}
            setSearchBarValue={(value) => {
              setSearchBarValue(value);
            }}
          ></CustomDragComponent>
        </div>
        <GamePreview
          selectedGame={selectedGame}
          selectedBase={selectedBase}
          totalSizeValue={totalSizeValue}
          selectedVersion={selectedVersion}
          userCompany={userCompany}
          localizationLanguage={localizationLanguage}
          setUserCompany={setUserCompany}
          applyPreview={applyPreview}
          iFrameId={iFrameId}
          versionsAreFetched={versionsAreFetched}
          prevButton={prevButton}
          setValue={setSelectedVersion}
          versionUpdate={isInsertNewVersion}
          versions={versions}
          setPortrait={(value) => {
            setPortrait(value);
          }}
          fetchVersions={fetchVersions}
          setSelectedVersion={setSelectedVersion}
          classes={classes}
          deleteVersionFromView={(versionID) => {
            try {
              deleteVersionFromView(versionID);
            } catch (err) {
              console.error(err);
            }
          }}
          versionRename={renameVersionName}
          isPreviewLoaded={isPreviewLoaded}
          setIsPreviewLoaded={setIsPreviewLoaded}
          isPreviewFullScreen={props.isPreviewFullScreen}
          shouldLog={true}
          setSelectedVersionPathValue={setSelectedVersionPathValue}
          saveVersionAndApplyPreview={saveVersionAndApplyPreview}
          refresh={refresh}
          createSnackbar={createSnackbar}
          plans={plans}
          getPlanIndex={getPlanIndex}
          userPermissions={userPermissions}
          setIsDownloadingPlayable={setIsDownloadingPlayable}
          insertNewVersion={insertNewVersion}
        ></GamePreview>
        <GameConfig
          selectedGame={selectedGame}
          selectedBase={selectedBase}
          setTotalSize={(value) => {
            setTotalSize(value);
          }}
          setDefaultVersionUpdated={(value) => {
            setDefaultVersionUpdated(value);
          }}
          selectedVersion={selectedVersion}
          userCompany={userCompany}
          setUserCompany={setUserCompany}
          media={media}
          areMediaLoaded={areMediaLoaded}
          atlases={atlases}
          newSetPortrait={newSetPortrait}
          setLocalizationLanguage={(value) => {
            setLocalizationLanguage(value);
          }}
          setSearchBarValue={(value) => {
            setSearchBarValue(value);
          }}
          areAtlasesLoaded={areAtlasesLoaded}
          sounds={sounds}
          areSoundsLoaded={areSoundsLoaded}
          fonts={fonts}
          areFontsLoaded={areFontsLoaded}
          videos={videos}
          areVideosLoaded={areVideosLoaded}
          isPreviewLoaded={isPreviewLoaded}
          classes={classes}
          iFrameId={iFrameId}
          versionRename={renameVersionName}
          setIsPreviewFullScreen={props.setIsPreviewFullScreen}
          setSelectedVersionPathValue={setSelectedVersionPathValue}
          addDefaultImagesToGameConfig={addDefaultImagesToGameConfig}
          addDefaultVersionImagesToGameConfig={addDefaultVersionImagesToGameConfig}
          insertMediaPathValue={insertMediaPathValue}
          insertAtlasPathValue={insertAtlasPathValue}
          insertSoundPathValue={insertSoundPathValue}
          insertFontPathValue={insertFontPathValue}
          insertVideoPathValue={insertVideoPathValue}
          defaultVersion={defaultVersion ? defaultVersion : versions.filter((version) => version._id == defaultVersionID)[0]}
          doRefresh={doRefresh}
          isRecordingStarted={props.isRecordingStarted}
          setIsRecordingStarted={props.setIsRecordingStarted}
          isRecorderEnabled={isRecorderEnabled}
          createSnackbar={createSnackbar}
          maxInnerHeight={maxInnerHeight}
          plans={plans}
          deleteImageFromMedia={deleteImageFromMedia}
          deleteImageSetFromMedia={deleteImageSetFromMedia}
          deleteFontFromFonts={deleteFontFromFonts}
          deleteSoundFromSounds={deleteSoundFromSounds}
          deleteVideoFromVideos={deleteVideoFromVideos}
          deleteAnimationSetFromAtlases={deleteAnimationSetFromAtlases}
          userPermissions={userPermissions}
          getPlanIndex={getPlanIndex}
          setIsDialogConfirmRefreshOpen={setIsDialogConfirmRefreshOpen}
          setIsDialogCircularProgressOpen={setIsDialogCircularProgressOpen}
          getDefaultVersion={getDefaultVersion}
          setDefaultVersion={(value) => {
            setDefaultVersion(value);
          }}
          defaultVersionUpdated={defaultVersionUpdated}
          searchBarValue = {searchBarValue}
          versions={versions}
        />
        {!_.isNil(playableErrors) && (
          <DialogPlayableErrors
            playableErrors={playableErrors}
            doRefresh={doRefresh}
            onRefresh={() => {
              setPlayableErrors(null);
            }}
          />
        )}
        <DialogConfirmRefresh isOpen={isDialogConfirmRefreshOpen} setIsOpen={setIsDialogConfirmRefreshOpen} />
        <DialogCircularProgress isOpen={isDialogCircularProgressOpen} setIsOpen={setIsDialogCircularProgressOpen} />
        <DialogViewOnlyMode
          isOpen={isDialogViewOnlyModeOpen}
          setIsOpen={setIsDialogViewOnlyModeOpen}
          lastUpdatingUser={lastUpdatingUser}
        />
        <DialogFetchUpdatesOnVersion
          isOpen={isDialogFetchUpdatesOnVersionOpen}
          setIsOpen={setIsDialogFetchUpdatesOnVersionOpen}
          lastUpdatingUser={lastUpdatingUser}
        />
      </div>
    );
}
