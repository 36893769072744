import React, { useEffect, useState } from "react";
import { Button, TextField } from "@material-ui/core";
import { SkipNext, SkipPrevious } from "@material-ui/icons";

/**
 *
 * @param props
 * @param props.value
 * @param props.classes
 * @param props.selectedGame
 * @param props.selectedBase
 * @param props.setValue
 * @param props.seperator
 * @returns {JSX.Element}
 */
export default function editorWordManager(props) {
  let [value, setValue] = useState(_.get(props, `value`, ""));
  useEffect(() => {
    setValue(_.get(props, `value`, ""));
  }, [props.value]);
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }
  const getCardOrderByKey = (key) => {
    return props.cardOrders.filter((orders) => orders.key == key)[0];
  };
  function manipulateWordsWithChar(direction) {
    let wordsString = props.value;
    let char = getSplitCharWithGame();
    let words = wordsString.split(char);

    if (direction === "prev") {
      let first = words.shift();
      words.push(first);
    } else if (direction === "next") {
      let last = words.pop();
      words.unshift(last);
    }

    words = words.join(char);
    props.setValue(words);
  }

  function getSplitCharWithGame() {
    if (props.seperator === "") {
      switch (props.selectedGame.title) {
        case "WOW":
          return "|";
        case "WOW Search":
          return "-";
        case "WOW Guru":
          return "#";
        case "Crossword Jam Playable":
          return "#";
        default:
          return "-";
      }
    } else {
      return props.seperator;
    }
  }

  return (
    <div id={`${props.id}.Div`}>
      {" "}
      {props.isVisible && (
        <div className={props.classes.editorItem}>
          <TextField
            className={props.classes.textField}
            style={{ marginTop: gettingMargin(props.tooltipLength) }}
            label={_.get(props, `label`)}
            value={value}
            onChange={(event) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setValue(event.target.value);
              props.setValue(event.target.value);
            }}
          ></TextField>
          <div style={{ display: "flex", justifyContent: "center", alignItems: "center", margin: "1.5rem 1rem 0" }}>
            {((props.selectedBase.title === "CwdJam2" && props.id !== "wordManager1.words") ||
              (props.selectedGame.title === "WOW" && props.id !== "wordManager1.words") ||
              (props.selectedGame.title !== "WOW" && props.selectedBase.title !== "CwdJam2")) && (
              <Button
                id={`${props.id}.prevWordButton`}
                color="secondary"
                className={props.classes.autoplayButton}
                style={{ marginRight: "0.5rem" }}
                variant="contained"
                onClick={() => {
                  if (props.userPermissions["update"] === false) {
                    props.createSnackbar(
                      "You do not have permission to update, contact your administrator.",
                      "warning",
                      false
                    );
                    return;
                  }
                  manipulateWordsWithChar("prev");
                }}
                startIcon={<SkipPrevious />}
              >
                Prev. Word
              </Button>
            )}
            {((props.selectedBase.title === "CwdJam2" && props.id !== "wordManager1.words") ||
              (props.selectedGame.title === "WOW" && props.id !== "wordManager1.words") ||
              (props.selectedGame.title !== "WOW" && props.selectedBase.title !== "CwdJam2")) && (
              <Button
                id={`${props.id}.nextWordButton`}
                color="secondary"
                className={props.classes.autoplayButton}
                variant="contained"
                onClick={() => {
                  if (props.userPermissions["update"] === false) {
                    props.createSnackbar(
                      "You do not have permission to update, contact your administrator.",
                      "warning",
                      false
                    );
                    return;
                  }
                  manipulateWordsWithChar("next");
                }}
                endIcon={<SkipNext />}
              >
                Next Word
              </Button>
            )}
          </div>
        </div>
      )}
    </div>
  );
}
