import React, { useState, useEffect, useRef, useContext } from "react";
import ReactDOM from "react-dom";
import { MenuItem, Typography, Tab, Tabs, Box, Card, CardContent, TextField, Button } from "@material-ui/core";
import SwipeableViews from "react-swipeable-views";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import SettingsIcon from "@material-ui/icons/Settings";
import LanguageIcon from "@material-ui/icons/Language";
import TuneIcon from "@material-ui/icons/Tune";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
import PropTypes from "prop-types";
import VersionSettingsContainer from "./containerVersionSettings";
import TextGameObjectEditorContainer from "./containerTextGameObject";
import LocalizationContainer from "./containerLocalization";
import GeneralSettingsEditor from "./editorGeneralSettings";
import RecordAndAutoPlayContainer from "./containerRecordAndAutoplay";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

import { UndoContext } from "../contexts/UndoContext";
import _ from "lodash";
/**
 * @returns {JSX.Element}
 * @constructor
 */
ReactGA.initialize("UA-186063268-1", {
  debug: false,
  gaOptions: {
    userId: localStorage.getItem("user.id"),
  },
});
ReactGA4.initialize("G-TB46JQ70PR", {
  debug: false,
  gaOptions: {
    userId: localStorage.getItem("user.id"),
  },
});
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography component={"span"}>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  gameConfigContainerDiv: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "400px",
    borderStyle: "none none none solid",
    borderWidth: "1px",
    borderColor: "black",
  },
  tabPanel: {
    "& .MuiBox-root": { padding: "0px" },
    height: "100%",
    overflowY: "auto",

    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));

/**
 *
 * @param props.selectedGame {Object}
 * @param props.selectedBase {Object}
 * @param props.selectedBase.supportedLanguages
 * @param props.setSelectedVersionPathValue
 * @param props.selectedVersion {Object}
 * @param props.media
 * @param props.areMediaLoaded
 * @param props.atlases
 * @param props.areAtlasesLoaded
 * @param props.sounds
 * @param props.areSoundsLoaded
 * @param props.fonts
 * @param props.areFontsLoaded
 * @param props.videos
 * @param props.areVideosLoaded
 * @param props.insertMediaPathValue
 * @param props.insertAtlasPathValue
 * @param props.insertSoundPathValue
 * @param props.insertFontPathValue
 * @param props.insertVideoPathValue
 * @param props.setSelectedVersionPathValue {function}
 * @param props.addDefaultImagesToGameConfig {function}
 * @param props.classes
 * @param props.setIsPreviewFullScreen {function}
 * @param props.doRefresh {function}
 * @param props.maxInnerHeight
 * @param props.isRecordingStarted
 * @param props.setIsRecordingStarted {function}
 * @param props.deleteImageFromMedia
 * @param props.deleteImageSetFromMedia
 * @param props.deleteFontFromFonts
 * @param props.deleteSoundFromSounds
 * @param props.deleteVideoFromVideos
 * @param props.deleteAnimationSetFromAtlases
 * @param props.createSnackbar
 * @param props.userPermissions
 * @param props.setIsDialogConfirmRefreshOpen
 * @param props.setIsDialogCircularProgressOpen
 * @returns {JSX.Element}
 */

export default function containerGameConfig(props) {
  if (!_.has(props, "selectedVersion.gameConfig") || !props.areMediaLoaded || !props.areAtlasesLoaded) return null;
  const classes = useStyles();
  const [newTabPanel, setTabPanel] = useState(classes.tabPanel);

  const theme = useTheme();
  const [tabValue, setTabValue] = useState(0);
  const [selectedLanguage, setSelectedLanguage] = useState(_.get(props, "gameConfig.options.language"));
  const swipeableViewRef = useRef(null);
  const undoCtx = useContext(UndoContext);
  function isLocalizationLinkHas(){
    return _.has(props, "selectedVersion.gameConfig.options.localizationLink");
  }

  function isLocalizationLinkExistCurrentLanguage(path){

    return !(_.get(props, `selectedVersion.gameConfig.options.localizationLink.${path}`) == undefined || _.get(props, `selectedVersion.gameConfig.options.localizationLink.${path}`) == "");
  }

  var isSafari =
    /constructor/i.test(window.HTMLElement) ||
    (function (p) {
      return p.toString() === "[object SafariRemoteNotification]";
    })(!window["safari"] || (typeof safari !== "undefined" && safari.pushNotification));

  useEffect(() => {
    setSelectedLanguage(_.get(props, "selectedVersion.gameConfig.options.language"));
    props.setLocalizationLanguage(_.get(props, "selectedVersion.gameConfig.options.language"));
  }, [props.selectedVersion.gameConfig]);


  useEffect(() => {
    console.log("customDrag", props.selectedVersion.title);
  }, []);
  useEffect(() => {
    // figured out safari scroll problem between tabs.
    if (swipeableViewRef && swipeableViewRef.current) {
      const swipeableViewRefNode = ReactDOM.findDOMNode(swipeableViewRef.current);
      const swipeableVievContainer = swipeableViewRefNode.querySelector(".react-swipeable-view-container");
      swipeableVievContainer.style.height = "100%";
    }
  });

  if (_.isNil(props.selectedGame) || _.isNil(props.selectedBase) || _.isNil(props.selectedVersion)) {
    return null;
  }

  function resetVersionSettings() {
    undoCtx.setUndoStack([]);
    undoCtx.setCursor(0);
    console.info("Resetting version settings");
    let newGameConfig = _.clone(props.selectedVersion.gameConfig);

    if (_.has(newGameConfig, "selectedCardOrder")) {
      _.set(newGameConfig, "selectedCardOrder", 0);
    }

    let destination;
    let defaultValue;

    const defaultVersion = props.versions.filter((version) => version._id == props.defaultVersion?.versionID)[0];

    _.get(props, "selectedBase.versionSettings.components", []).forEach((component) => {
      //if base does not have enemy1 and sounds component
      if (component.id !== "enemy1" && component.id !== "sounds") {
        _.get(component, "settings").forEach((setting) => {
          //we know our component id and what path to set

          //lets find our destination
          destination = `components.${component.id}.${setting.path}`;
          //lets find our default value
          if(defaultVersion){
            defaultValue = _.get(defaultVersion, `gameConfig.components.[${component.id}].${setting.path}`);
          }else{
            defaultValue = _.get(props, `selectedBase.gameConfig.components.[${component.id}].${setting.path}`);
          }

          console.debug(
            `Resetting values. Destination: ${destination} Current Value: ${JSON.stringify(
              _.get(newGameConfig, destination)
            )} Default Value: ${JSON.stringify(defaultValue)}`
          );
          _.set(newGameConfig, destination, defaultValue);
        });
      }
    });

    //if base has enemy1 component
    if (!_.isNil(_.get(props, "selectedBase.gameConfig.components.enemy1"))) {
      destination = "components.enemy1.enemySoldiers";
      if(defaultVersion){
        defaultValue = _.get(defaultVersion, "gameConfig.components.enemy1.enemySoldiers.enemySoldier1");

      }else{
        defaultValue = _.get(props, "selectedBase.gameConfig.components.enemy1.enemySoldiers.enemySoldier1");

      }
      _.set(newGameConfig, destination, { "enemySoldier1": defaultValue });
    }
    let defaultGameConfig;
    if(defaultVersion){
      defaultGameConfig= props.addDefaultVersionImagesToGameConfig(newGameConfig, defaultVersion);

    }else{
      defaultGameConfig =  props.addDefaultImagesToGameConfig(newGameConfig);

    }
    props.setSelectedVersionPathValue({ path: "gameConfig", value: defaultGameConfig }, true);
  }

  function resetLocalizationSettings() {
    undoCtx.setUndoStack([]);
    undoCtx.setCursor(0);
    const defaultLanguage = "en";
    console.info("Resetting localization settings");

    let newGameConfig = _.clone(props.selectedVersion.gameConfig);
    const defaultVersion = props.versions.filter((version) => version._id == props.defaultVersion?.versionID)[0];
    _.get(props, "selectedBase.localizationSettings.components", []).forEach((component) => {
      _.get(component, "settings", []).forEach((setting) => {
        //we know our component id and what path to set

        //TODO: component.id can be used instead of gameComponentId
        //lets find our destination
        let destination = "";
        let defaultValue = null;

        // this condition is placed just for selectorRibbonType which is a parent component of components.
        if (_.has(component, "gameComponentId")) {
          destination = `components.${component.gameComponentId}.localization.${selectedLanguage}.${setting.path}`;

          if(defaultVersion){
            defaultValue = _.get(
              defaultVersion,
              `gameConfig.components.[${component.gameComponentId}].localization.${selectedLanguage}.${setting.path}`);

          }else{
            defaultValue = _.get(
              props,
              `selectedBase.gameConfig.components.[${component.gameComponentId}].localization.${defaultLanguage}.${setting.path}`
            );
          }

        } else {
          destination = `components.${component.id}.localization.${selectedLanguage}.${setting.path}`;
          console.log("defaultVersionLocalization",_.get(defaultVersion, `gameConfig.components.[${component.id}].localization`), defaultLanguage, selectedLanguage);

          if(defaultVersion){
            defaultValue = _.get(defaultVersion, `gameConfig.components.[${component.id}].localization.${selectedLanguage}.${setting.path}`);
          }else{
            defaultValue = _.get(
              props,
              `selectedBase.gameConfig.components.[${component.id}].localization.${defaultLanguage}.${setting.path}`
            );
          }
        }
        console.debug(
          `Resetting values. Destination: ${destination} Current Value: ${JSON.stringify(
            _.get(newGameConfig, destination)
          )} Default Value: ${JSON.stringify(defaultValue)}`
        );
        _.set(newGameConfig, destination, defaultValue);
      });
    });

    props.setSelectedVersionPathValue({ path: "gameConfig", value: newGameConfig }, true);
  }

  function resetGeneralSettings() {
    undoCtx.setUndoStack([]);
    undoCtx.setCursor(0);
    console.info("Resetting general settings");

    let newGameConfig = _.clone(props.selectedVersion.gameConfig);

    console.info("Resetting options");

    let optionsValue;
    const defaultVersion = props.versions.filter((version) => version._id == props.defaultVersion?.versionID)[0];

    if(defaultVersion){
      optionsValue = defaultVersion.gameConfig.options;
    }else{
      optionsValue = props.selectedBase.gameConfig.options;
    }

    _.set(newGameConfig, "options", _.cloneDeep(optionsValue));
    props.setSelectedVersionPathValue({ path: "gameConfig", value: newGameConfig }, true);
  }

  if (_.isNil(props.selectedVersion.gameConfig)) return null;
  return (
    <div id={"gameConfigContainerDiv"} className={classes.gameConfigContainerDiv}>
      <Tabs
        id={"tabsComponent"}
        indicatorColor={"primary"}
        style={{
          height: "60px",
          borderColor: "black",
          borderWidth: "1px",
          borderStyle: "none none solid none",
        }}
        value={tabValue}
        onChange={(event, newValue) => {
          if (newValue == 3) {
            // recorder tab
            if (isSafari) {
              props.createSnackbar("Recorder is not available on Safari", "info");
              return;
            }
          }
          setTabValue(newValue);
          // ReactGA.event({
          //   category: "Tabs",
          //   action: "Tab Changed",
          //   label:
          //     "game: " +
          //     props.selectedGame.title +
          //     ", base: " +
          //     props.selectedBase.title +
          //     ", version: " +
          //     props.selectedVersion.title +
          //     ", tabIndex: " +
          //     newValue,
          //   dimension1: localStorage.getItem("user.id"),
          // });
        }}
        variant={"fullWidth"}
      >
        <Tab
          wrapped
          style={{
            height: "60px",
            minHeight: "0px",
            minWidth: "0px",
            fontSize: "9px",
            padding: "0px",
            flex: "1",
          }}
          label="Version Settings"
          icon={<SettingsIcon />}
          {...a11yProps(0)}
        />
        <Tab
          wrapped
          style={{
            minWidth: "0px",
            minHeight: "0px",
            height: "60px",
            fontSize: "9px",
            padding: "0px",
            flex: "1",
          }}
          label="Localization"
          icon={<LanguageIcon />}
          {...a11yProps(1)}
        />
        <Tab
          wrapped
          style={{
            minWidth: "0px",
            minHeight: "0px",
            height: "60px",
            fontSize: "9px",
            padding: "0px",
            flex: "1",
          }}
          label="General Settings"
          icon={<TuneIcon />}
          {...a11yProps(2)}
        />
        {props.userCompany.isPermittedRecording && (
          <Tab
            wrapped
            style={{
              minWidth: "0px",
              minHeight: "0px",
              height: "60px",
              fontSize: "9px",
              padding: "0px",
              flex: "1",
            }}
            label="Recorder"
            icon={<FiberManualRecord />}
            {...a11yProps(3)}
          />
        )}
      </Tabs>
      <SwipeableViews
        style={{ flex: 1, overflow: "hidden" }}
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={tabValue}
        onChangeIndex={(index) => {
          setTabValue(index);
        }}
        ref={swipeableViewRef}
      >
        <TabPanel id={"tabPanel1"} value={tabValue} index={0} className={classes.tabPanel}>
          <VersionSettingsContainer
            selectedVersion={props.selectedVersion}
            selectedBase={props.selectedBase}
            setTotalSize={props.setTotalSize}
            selectedGame={props.selectedGame}
            classes={props.classes}
            media={props.media}
            atlases={props.atlases}
            sounds={props.sounds}
            newSetPortrait={props.newSetPortrait}
            videos={props.videos}
            fonts={props.fonts}
            areMediaLoaded={props.areMediaLoaded}
            areAtlasesLoaded={props.areAtlasesLoaded}
            areSoundsLoaded={props.areSoundsLoaded}
            areVideosLoaded={props.areVideosLoaded}
            areFontsLoaded={props.areFontsLoaded}
            setSelectedVersionPathValue={props.setSelectedVersionPathValue}
            insertMediaPathValue={props.insertMediaPathValue}
            insertAtlasPathValue={props.insertAtlasPathValue}
            insertSoundPathValue={props.insertSoundPathValue}
            insertVideoPathValue={props.insertVideoPathValue}
            insertFontPathValue={props.insertFontPathValue}
            deleteImageFromMedia={props.deleteImageFromMedia}
            deleteImageSetFromMedia={props.deleteImageSetFromMedia}
            deleteFontFromFonts={props.deleteFontFromFonts}
            deleteSoundFromSounds={props.deleteSoundFromSounds}
            deleteVideoFromVideos={props.deleteVideoFromVideos}
            deleteAnimationSetFromAtlases={props.deleteAnimationSetFromAtlases}
            createSnackbar={props.createSnackbar}
            userPermissions={props.userPermissions}
            versionRename={props.versionRename}
            userCompany={props.userCompany}
            setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
            setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
            setDefaultVersion={(value) => {
              props.setDefaultVersion(value);
            }}
            setDefaultVersionUpdated={(value) => {
              props.setDefaultVersionUpdated(value);
            }}
            defaultVersion={props.defaultVersion}
            getDefaultVersion={props.getDefaultVersion}
            defaultVersionUpdated={props.defaultVersionUpdated}
            searchBarValue = {props.searchBarValue}
            setSearchBarValue={(value) => {
              props.setSearchBarValue(value);
            }}
          ></VersionSettingsContainer>
          <Card className={props.classes.card}>
            <CardContent className={props.classes.cardContent}>
              <Button
                id={"resetVersionSettings"}
                style={{ marginTop: "10px" }}
                onClick={() => {
                  if (props.userPermissions["update"] === false) {
                    props.createSnackbar(
                      "You do not have permission to update, contact your administrator.",
                      "warning",
                      false
                    );
                    return;
                  }
                  resetVersionSettings();
                  if(props.defaultVersion?.versionID == props.selectedVersion._id){
                    props.createSnackbar("There are no changes in the version","warning");

                  }
                  // ReactGA.event({
                  //   category: "Config",
                  //   action: "Reset Version Settings",
                  //   label:
                  //     "game: " +
                  //     props.selectedGame.title +
                  //     ", base: " +
                  //     props.selectedBase.title +
                  //     ", version: " +
                  //     props.selectedVersion.title,
                  //   dimension1: localStorage.getItem("user.id"),
                  // });

                }}
              >
                RESET VERSION SETTINGS
              </Button>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={tabValue} index={1} className={classes.tabPanel}>
          <Card className={props.classes.card}>
            <CardContent>
              <Typography className={props.classes.title} variant={"h6"} gutterBottom>
                {
                  props.selectedVersion?.createdLanguages?.length === undefined ?
                    "Language" : "Language - Active (" + props.selectedVersion?.createdLanguages?.length + ")"
                }
              </Typography>
              <TextField
                select
                name={"selectedLanguage"}
                className={props.classes.textField}
                onChange={(event) => {
                  if (props.userPermissions["update"] === false) {
                    props.createSnackbar(
                      "You do not have permission to update, contact your administrator.",
                      "warning",
                      false
                    );
                    return;
                  }
                  const newGameConfig = _.cloneDeep(_.get(props, "selectedVersion.gameConfig"));
                  //add default values to the config


                  if(isLocalizationLinkHas() && !isLocalizationLinkExistCurrentLanguage(event.target.value)){

                    const path = `options.localizationLink.${event.target.value}`;

                    const englishSettings = _.get(
                      props,
                      "selectedVersion.gameConfig.options.localizationLink.en",
                      undefined
                    );

                    _.set(newGameConfig, path, englishSettings);

                  }

                  Object.entries(_.get(props, "selectedVersion.gameConfig.components", {})).forEach(
                    ([componentID, detail]) => {
                      //does the component support the selected language? if not, use values from the english
                      const path = `components.${componentID}.localization.${event.target.value}`;
                      if (_.has(detail, "localization") && !_.has(detail, `localization.${event.target.value}`)) {
                        const englishSettings = _.get(
                          props,
                          `selectedVersion.gameConfig.components.${componentID}.localization.en`,
                          undefined
                        );
                        //newGameConfig.components[componentID].localization[event.target.value] = englishSettings;
                        _.set(newGameConfig, path, englishSettings);
                      }
                    }
                  );
                  setSelectedLanguage(event.target.value);
                  _.set(newGameConfig, "options.language", event.target.value);
                  props.setSelectedVersionPathValue({
                    path: "gameConfig",
                    value: newGameConfig,
                    isLanguageChanged: true,
                  });
                }}
                value={selectedLanguage}
              >
                {Object.entries(props.selectedBase.supportedLanguages).map(([languageKey, languageCaption]) => {
                  let color = props.selectedVersion?.createdLanguages?.includes(languageKey) ? "#9bcec0" : "#eeeeee";
                  return (
                    <MenuItem key={languageKey} value={languageKey} style={{color: color}}>
                      {languageCaption}
                    </MenuItem>
                  );
                })}
              </TextField>
            </CardContent>
          </Card>
          <TextGameObjectEditorContainer
            selectedBase={props.selectedBase}
            selectedVersion={props.selectedVersion}
            selectedLanguage={selectedLanguage}
            classes={props.classes}
            setSelectedVersionPathValue={props.setSelectedVersionPathValue}
            userPermissions={props.userPermissions}
          ></TextGameObjectEditorContainer>
          <LocalizationContainer
            selectedBase={props.selectedBase}
            selectedVersion={props.selectedVersion}
            selectedGame={props.selectedGame}
            selectedLanguage={selectedLanguage}
            classes={props.classes}
            setSelectedVersionPathValue={props.setSelectedVersionPathValue}
            userPermissions={props.userPermissions}
            createSnackbar={props.createSnackbar}
          ></LocalizationContainer>
          <Card className={props.classes.card}>
            <CardContent className={props.classes.cardContent}>
              <Button
                id={"resetLocalizationSettings"}
                style={{ marginTop: "10px" }}
                onClick={() => {
                  if (props.userPermissions["update"] === false) {
                    props.createSnackbar(
                      "You do not have permission to update, contact your administrator.",
                      "warning",
                      false
                    );
                    return;
                  }
                  resetLocalizationSettings();
                  if(props.defaultVersion?.versionID == props.selectedVersion._id){
                    props.createSnackbar("There are no changes in the version","warning");

                  }
                  // ReactGA.event({
                  //     category: "Config",
                  //     action: "Reset Localization Settings",
                  //     label: "game: " + props.selectedGame.title + ", base: " + props.selectedBase.title + ", version: " + props.selectedVersion.title
                  //     dimension1: localStorage.getItem("user.id")
                  // })

                }}
              >
                RESET LOCALIZATION SETTINGS
              </Button>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={tabValue} index={2} className={classes.tabPanel}>
          <GeneralSettingsEditor
            selectedVersion={props.selectedVersion}
            selectedBase={props.selectedBase}
            classes={props.classes}
            setSelectedVersionPathValue={props.setSelectedVersionPathValue}
            isPreviewLoaded={props.isPreviewLoaded}
            iFrameId={props.iFrameId}
            userPermissions={props.userPermissions}
            createSnackbar={props.createSnackbar}
          ></GeneralSettingsEditor>
          <Card className={props.classes.card}>
            <CardContent className={props.classes.cardContent}>
              <Button
                style={{ marginTop: "10px" }}
                onClick={() => {
                  if (props.userPermissions["update"] === false) {
                    props.createSnackbar(
                      "You do not have permission to update, contact your administrator.",
                      "warning",
                      false
                    );
                    return;
                  }
                  resetGeneralSettings();
                  if(props.defaultVersion?.versionID == props.selectedVersion._id){
                    props.createSnackbar("There are no changes in the version","warning");

                  }
                  ReactGA.event({
                    category: "Config",
                    action: "Reset General Settings",
                    label:
                      "game: " +
                      props.selectedGame.title +
                      ", base: " +
                      props.selectedBase.title +
                      ", version: " +
                      props.selectedVersion.title,
                    dimension1: localStorage.getItem("user.id"),
                  });
                  ReactGA4.event({
                    category: "Config",
                    action: "Reset General Settings",
                    label:
                      "game: " +
                      props.selectedGame.title +
                      ", base: " +
                      props.selectedBase.title +
                      ", version: " +
                      props.selectedVersion.title,
                    dimension1: localStorage.getItem("user.id"),
                  });

                }}

              >
                RESET GENERAL SETTINGS
              </Button>
            </CardContent>
          </Card>
        </TabPanel>
        <TabPanel value={tabValue} index={3} className={classes.tabPanel}>
          <RecordAndAutoPlayContainer
            setIsPreviewFullScreen={props.setIsPreviewFullScreen}
            selectedGame={props.selectedGame}
            selectedVersion={props.selectedVersion}
            selectedBase={props.selectedBase}
            userCompany={props.userCompany}
            setUserCompany={props.setUserCompany}
            selectedLanguage={props.selectedLanguage}
            classes={props.classes}
            newSetPortrait={props.newSetPortrait}
            setSelectedVersionPathValue={props.setSelectedVersionPathValue}
            isPreviewLoaded={props.isPreviewLoaded}
            iFrameId={props.iFrameId}
            doRefresh={props.doRefresh}
            isRecorderEnabled={props.isRecorderEnabled}
            media={props.media}
            insertMediaPathValue={props.insertMediaPathValue}
            createSnackbar={props.createSnackbar}
            maxInnerHeight={props.maxInnerHeight}
            isRecordingStarted={props.isRecordingStarted}
            setIsRecordingStarted={props.setIsRecordingStarted}
            plans={props.plans}
            userPermissions={props.userPermissions}
            getPlanIndex={props.getPlanIndex}
            setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
            setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
          ></RecordAndAutoPlayContainer>
        </TabPanel>
      </SwipeableViews>
    </div>
  );
}
