import React, { useEffect, useState, useRef } from "react";
import {Refresh, GetApp} from "@material-ui/icons";
import { PlayArrow, Pause } from "@material-ui/icons";
import {Button, CircularProgress} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { getServerUri } from "../modules/uriUtility";
import DialogMultipleVersionDownload from "./dialogMultipleVersionDownload";
import DialogVersionDownload from "./dialogVersionDownload";

import PlayOutIconDashboard from "../../images/playin/playOutIcon_dashboard.svg";

import _, { countBy } from "lodash";
import ToolBarGamePreviewOrientation from "./toolbarGamePreviewOrientation";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";
import Popover from "@material-ui/core/Popover";
import DownloadIcon from "@mui/icons-material/Download";


const useStyles = makeStyles({
  middleDiv: { textAlign: "center", flex: 1, padding: "10px", lineHeight: "100%" },
  leftDiv: { textAlign: "left", flex: 1, padding: "10px", lineHeight: "100%" },
  rightDiv: { textAlign: "right", flex: 1, padding: "10px", lineHeight: "100%" },
  visible: { display: "inline" },
  invisible: { display: "none" },
  downloadDialogCard: { margin: "15px", padding: 0, width: 440, backgroundColor: "#363636" },
  gamePreviewToolbarDiv: {
    height: "60px",
    minHeight: "60px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderStyle: "none none solid none",
    borderWidth: "1px",
    borderColor: "black",
  },
  downloadPlayable: {
    textTransform: "none",
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
  },
});

/**
 *
 * @param props.selectedVersion
 * @param props.setSelectedVersion {function}
 * @param props.selectedGame
 * @param props.selectedBase
 * @param props.setAspectRatio
 * @param props.aspectRatio
 * @param props.isPortrait
 * @param props.setIsPortrait
 * @param props.iFrameId
 * @param props.setIsSaveDialogOpen
 * @param props.deleteVersionFromView
 * @param props.fetchVersions
 * @param props.isPreviewLoaded
 * @param props.setIsPreviewLoaded
 * @param props.refresh
 * @param props.userPermissions
 * @param props.createSnackbar
 * @param props.userCompany
 * @param props.setUserCompany
 * @param props.plans
 * @param props.classes
 * @param props.setIsDownloadingPlayable
 * @param props.insertNewVersion
 * @returns {JSX.Element|null}
 */
export default function toolbarGamePreview(props) {
  let [isDownloadDialogOpen, setIsDownloadDialogOpen] = useState(false);
  let [newVersionTitle, setNewVersionTitle] = useState(props.selectedVersion ? props.selectedVersion.title : "");
  let [isPaused, setIsPaused] = useState(false);
  const selectedVersionIDString = String(_.get(props, "selectedVersion._id"));
  const classes = useStyles();

  const [isBuilding, setIsBuilding] = useState(false);
  const [isDownloadBuilding, setIsDownloadBuilding] = useState(false);
  const [isMultipleVersionActive,setIsMultipleVersionActive] = useState(false);

  const [createdPlayable, setCreatedPlayable] = useState("");

  const [newNetworks, setNewNetworks] = useState("default");
  const [error, setError] = useState("");
  const [downloadIconColor, setDownloadIconColor] = useState("#ffffff1f");

  let [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    refresh();
  }, [props.refresh]);
  useEffect(() => {
    setNewVersionTitle(props.selectedVersion.title);
  }, [props.selectedVersion]);

  useEffect(() =>{

    let color = "#eeeeee";
    if(!props.versionsAreFetched){
      color = "#ffffff1f";
    }

    setDownloadIconColor(color);
  },[props.versionsAreFetched]);

  const setValue = _.debounce((p) => {
    props.setSelectedVersionPathValue(p);
  }, 200);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);

  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open=Boolean(anchorEl);

  function closeAutoplay() {
    const isAutoplay = _.get(props, "selectedVersion.gameConfig.components.autoplay1.startAutoplay", null);
    if (!_.isNil(isAutoplay) && isAutoplay) {
      setValue({
        path: "gameConfig.components.autoplay1.startAutoplay",
        value: false,
      });
    }
  }

  function downloadTestPlayable(){

    if (
      props.userCompany.subscription.periods[props.userCompany.subscription.periods.length - 1]
        .testPlayableCount +
      1 >
      props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfTests
    ) {
      props.createSnackbar(
        `Your company's current plan allows up to ${
          props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfTests
        } playables`,
        "warning"
      );
      return;
    }

    const language = [props.selectedVersion?.gameConfig.options.language];

    setIsDownloadBuilding(true);

    fetch(getServerUri("version/downloadGame"), {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        versionID: String(props.selectedVersion._id),
        networks: ["default"],
        languages: language,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((object) => {
              if (_.has(object, "completion")) {
                console.info("Progress Received: ", object);
              } else if (_.has(object, "file")) {
                setIsDownloadBuilding(false);
                window.location = object.file;
                props.setIsDownloadingPlayable(false);

                updateTestPlayableCounter().catch((err) => {
                  console.log(err);
                });

              }
            })
            .catch((err) => {
              props.setIsDownloadingPlayable(false);
              setError("Server did not process the request");
              setError(err);
              console.log(err);
            });
        } else {
          props.setIsDownloadingPlayable(false);
          response
            .json()
            .then((object) => {
              setError("Server did not process the request");
              console.log("non 200 response:");
              console.dir(object);
            })
            .catch((err) => {
              console.log("can't parse response as json");
            });
        }
      })
      .catch((err) => {
        props.setIsDownloadingPlayable(false);
        setError("Could not send request to server");
        console.error("FETCH ERROR");
        console.error(err);
      });


  }

  function updateTestPlayableCounter(){

    return fetchCompany(_.get(props.userCompany, "_id")).then((company) => {
      const newCompany = _.cloneDeep(company);
      let oldValCount = _.get(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "testPlayableCount"
      );

      if (_.isNil(oldValCount)) oldValCount = 0;
      _.set(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "testPlayableCount",
        parseInt(oldValCount) + 1
      );
      const fetchUri = getServerUri("company/updateOne");
      const fetchInit = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          companyID: String(_.get(newCompany, "_id")),
          subscription: JSON.stringify(_.get(newCompany, "subscription")),
          //testPlayableCount: _.get(newCompany, "testPlayableCount")
        }),
      };
      fetch(fetchUri, fetchInit)
        .then((response) => {
          if (response.status === 200) {
            props.setUserCompany(newCompany);
            //console.debug("createdPlayables updated");
          } else {
            response
              .text()
              .then((textResponse) => {
                console.log("createdPlayables update failed: " + textResponse);
              })
              .catch(console.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });


  }

  function exportPlayable(){

    if (newNetworks != "default") {
      setError("Network was not correct");
      return;
    }

    if (
      props.userCompany.subscription.periods[props.userCompany.subscription.periods.length - 1]
        .monthlyCreatedPlayables >
      props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfPlayables
    ) {
      props.createSnackbar(
        `Your company's current plan allows up to ${
          props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfPlayables
        } playables`,
        "warning"
      );
      return;
    }
    if (
      props.userCompany.subscription.periods[props.userCompany.subscription.periods.length - 1]
        .testPlayableCount +
      1 >
      props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfTests
    ) {
      console.log(
        props.userCompany.subscription.periods[props.userCompany.subscription.periods.length - 1]
          .testPlayableCount + 1
      );
      props.createSnackbar(
        `Your company's current plan allows up to ${
          props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfTests
        } playables`,
        "warning"
      );
      return;
    }

    setError(null);
    setIsBuilding(true);
    console.info("sending download request");
    props.setIsDownloadingPlayable(true);
    const abortController = new AbortController();
    const myTimeout = setTimeout(() => {
      abortController.abort();
      if (abortController.signal.aborted === true) {
        props.createSnackbar("Please check your network connection!", "warning");
        setIsBuilding(false);
      }
    }, 45000);
    fetch(getServerUri("version/openPlayable"), {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        versionID: String(props.selectedVersion._id),
        networks: "default",
        language: props.localizationLanguage,
        gameName: props.selectedGame.title,
        baseName: props.selectedBase.title,
        companyID: props.userCompany._id

      }),
      signal: abortController.signal,
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((object) => {
              if (_.has(object, "completion")) {
                console.info("Progress Received: ", object);
              } else if (_.has(object, "file")) {
                setIsBuilding(false);
                const rondomNumber = Math.floor(Math.random() * 10000) + 100;
                const siteDt = new Date().getTime();
                const newObject = {
                  versionName: String(props.selectedVersion.title),
                  siteLink: siteDt,
                  amazonLink: object.file.Location,
                  amazonKey: object.file.key,
                  gameName: props.selectedGame.title,
                  baseName: props.selectedBase.title,
                  companyID: props.userCompany._id

                };
                sendLink(newObject);

                setCreatedPlayable(object.newObject);
                const tmpLinkToOpen = "https://playin.com.tr/display/?l=" + siteDt;
                // const tmpLinkToOpen = "http://localhost:8000/display/?l=" + siteDt;

                window.open(tmpLinkToOpen, "_blank");
                clearTimeout(myTimeout);

                updatePlayableCounter().catch((err) => {
                  console.log(err);
                });
              }
            })
            .catch((err) => {
              setError("Server did not process the request 1");
              setError(err);
              console.log(err);
            });
        } else {
          response
            .json()
            .then((object) => {
              setError("Server did not process the request 2");
              console.log("non 200 response:");
              console.dir(object);
            })
            .catch((err) => {
              console.log("can't parse response as json");
            });
        }
      })
      .catch((err) => {
        setError("Could not send request to server");
        console.error("FETCH ERROR");
        console.error(err);
      });
  }

  function refresh() {
    closeAutoplay();

    setIsPaused(false);
    props.setIsPreviewLoaded(false);
    document.getElementById(props.iFrameId).src += "";

    try {
      document.getElementById(props.iFrameId).focus();
    } catch (err) {
      console.error(err);
    }
  }
  function sendLink(myObject) {
    fetch(getServerUri("version/insertLink"), {
      method: "POST",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        versionName: myObject.versionName,
        siteLink: myObject.siteLink,
        amazonLink: myObject.amazonLink,
        amazonKey: myObject.amazonKey,
        gameName: myObject.gameName,
        baseName: myObject.baseName,
        companyID: myObject.companyID
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          response
            .json()
            .then((object) => {
              if (_.has(object, "completion")) {
                console.info("Progress Received: ", object);
              } else if (_.has(object, "file")) {
                setCreatedPlayable(object.newObject);
              }
            })
            .catch((err) => {
              setError("Server did not process the request 1");
              setError(err);
              console.log(err);
            });
        } else {
          response
            .json()
            .then((object) => {
              setError("Server did not process the request 2");
              console.log("non 200 response:");
              console.dir(object);
            })
            .catch((err) => {
              console.log("can't parse response as json");
            });
        }
      })
      .catch((err) => {
        setError("Could not send request to server");
        console.error("FETCH ERROR");
        console.error(err);
      });
  }
  function fetchCompany(companyId) {
    return new Promise((resolve, reject) => {
      fetch(getServerUri("company/findOne"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          companyID: companyId,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((company) => {
                props.setUserCompany(company);
                resolve(company);
              })
              .catch((err) => reject);
          } else {
            response
              .text()
              .then((err) => reject)
              .catch((err) => reject);
          }
        })
        .catch((err) => reject);
    });
  }
  function updatePlayableCounter() {
    return fetchCompany(_.get(props.userCompany, "_id")).then((company) => {
      const newCompany = _.cloneDeep(company);
      // let oldVal = _.get(
      //   newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
      //   "monthlyCreatedPlayables"
      // );
      let oldValCount = _.get(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "testPlayableCount"
      );
      if (_.isNil(oldValCount)) oldValCount = 0;
      _.set(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "testPlayableCount",
        parseInt(oldValCount) + 1
      );
      const fetchUri = getServerUri("company/updateOne");
      const fetchInit = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          companyID: String(_.get(newCompany, "_id")),
          subscription: JSON.stringify(_.get(newCompany, "subscription")),
          //testPlayableCount: _.get(newCompany, "testPlayableCount")
        }),
      };
      fetch(fetchUri, fetchInit)
        .then((response) => {
          if (response.status === 200) {
            props.setUserCompany(newCompany);
          } else {
            response
              .text()
              .then((textResponse) => {
                console.log("createdPlayables update failed: " + textResponse);
              })
              .catch(console.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }
  if (_.isNil(selectedVersionIDString)) return null;
  return (
    <div id={"gamePreviewToolbarDiv"} className={classes.gamePreviewToolbarDiv}>
      <div id={"leftDiv"} className={classes.leftDiv}>
        <Pause
          className={isPaused ? classes.invisible : classes.visible}
          onClick={(event) => {
            setIsPaused(true);
            ReactGA.event({
              category: "Preview",
              action: "Pause Clicked",
              label:
                "game: " +
                props.selectedGame.title +
                ", base: " +
                props.selectedBase.title +
                ", version: " +
                props.selectedVersion.title,
              dimension1: localStorage.getItem("user.id"),
            });
            ReactGA4.event({
              category: "Preview",
              action: "Pause Clicked",
              label:
                "game: " +
                props.selectedGame.title +
                ", base: " +
                props.selectedBase.title +
                ", version: " +
                props.selectedVersion.title,
              dimension1: localStorage.getItem("user.id"),
            });
            document.getElementById("gamePreviewIframe").contentWindow.setIsPaused(true);
          }}
        ></Pause>
        <PlayArrow
          className={!isPaused ? classes.invisible : classes.visible}
          onClick={(event) => {
            setIsPaused(false);
            ReactGA.event({
              category: "Preview",
              action: "Play Clicked",
              label:
                "game: " +
                props.selectedGame.title +
                ", base: " +
                props.selectedBase.title +
                ", version: " +
                props.selectedVersion.title,
              dimension1: localStorage.getItem("user.id"),
            });
            ReactGA4.event({
              category: "Preview",
              action: "Play Clicked",
              label:
                "game: " +
                props.selectedGame.title +
                ", base: " +
                props.selectedBase.title +
                ", version: " +
                props.selectedVersion.title,
              dimension1: localStorage.getItem("user.id"),
            });
            document.getElementById("gamePreviewIframe").contentWindow.setIsPaused(false);
            try {
              document.getElementById(props.iFrameId).focus();
            } catch (err) {
              console.error(err);
            }
          }}
        ></PlayArrow>

        <Refresh
          onClick={(event) => {
            refresh();
            ReactGA.event({
              category: "Preview",
              action: "Refresh Clicked",
              label:
                "game: " +
                props.selectedGame.title +
                ", base: " +
                props.selectedBase.title +
                ", version: " +
                props.selectedVersion.title,
              dimension1: localStorage.getItem("user.id"),
            });
            ReactGA4.event({
              category: "Preview",
              action: "Refresh Clicked",
              label:
                "game: " +
                props.selectedGame.title +
                ", base: " +
                props.selectedBase.title +
                ", version: " +
                props.selectedVersion.title,
              dimension1: localStorage.getItem("user.id"),
            });
          }}
        ></Refresh>
      </div>
      <div className={classes.middleDiv}>
        <ToolBarGamePreviewOrientation
          selectedGame={props.selectedGame}
          selectedVersion={props.selectedVersion}
          selectedBase={props.selectedBase}
          isPortrait={props.isPortrait}
          setIsPortrait={props.setIsPortrait}
          setPortrait={props.setPortrait}
          aspectRatio={props.aspectRatio}
          setAspectRatio={props.setAspectRatio}
        ></ToolBarGamePreviewOrientation>
      </div>
      <div className={classes.rightDiv}>
        {/* {process.env.NODE_ENV === "production" && ( */}
        <>
          <CircularProgress
            size="1.2rem"
            color={"inherit"}
            style={{
              textAlign: "center",
              display: isBuilding ? "inline-block" : "none",
              marginRight: "8px",
              marginBottom: "-3px",
            }}
          ></CircularProgress>
          <button
            disabled={isBuilding}
            style={{
              // marginLeft:"-2rem",
              background: "none",
              border: "none",
              marginRight: "6px",
              // TODO: visibility hidden will be removed.
              display: isBuilding ? "none" : "inline-block",
            }}
            onClick={() => {
              exportPlayable();
            }}
          >
            <img src={PlayOutIconDashboard} alt={"Your SVG"} style={{ marginBottom: "-2px" }} />
          </button>
        </>
        {/* // )} */}

        <GetApp
          style={{
            marginBottom: "-5.5px",
            display: isDownloadBuilding ? "none" : "inline-block",
            color: downloadIconColor
          }}
          onClick={(event) => {

            if(!props.versionsAreFetched){
              return;
            }

            if(_.has(props.userCompany, "isPermittedMultipleVersionDownload")
            && props.userCompany.isPermittedMultipleVersionDownload === true){
              setIsMultipleVersionActive(true);
              handleClick(event);
            }else{
              setIsDownloadDialogOpen(true);
            }

          }}

        >
        </GetApp>
        {isMultipleVersionActive && <CircularProgress
          size="1.2rem"
          color={"inherit"}
          style={{
            marginRight: "8px",
            marginBottom: "-3px",
            textAlign: "center",
            display: isDownloadBuilding ? "inline-block" : "none",
          }}
        ></CircularProgress>
        }
        {isMultipleVersionActive &&
            <Popover
              id="simplePopper_downloadVersion"
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
            >
              <div>
                <Button
                  id="downloadVersions_button"
                  className={classes.downloadPlayable}
                  style={{
                    width: "200px",
                    justifyContent: "flex-start",
                    fontSize: "16px"
                  }}
                  onClick={() => {
                    setAnchorEl(false);
                    setIsDownloadDialogOpen(true);
                  }}>
                  <DownloadIcon
                    style={{ width: "20px", height: "20px", marginRight: "4px" }}
                  />
                  Production Playable</Button>
                <Button
                  id="downloadDefaultNetwork_button"
                  className={classes.downloadPlayable}
                  style={{
                    width: "200px",
                    justifyContent: "flex-start",
                    fontSize: "16px",
                    display: "flex",

                  }}
                  onClick={() => {
                    setAnchorEl(false);

                    downloadTestPlayable();
                  }
                  }>
                  <DownloadIcon
                    style={{ width: "20px", height: "20px", marginRight: "4px" }}
                  />
                  Test Playable</Button>

              </div>

            </Popover>
          
        }
        {isMultipleVersionActive && <DialogMultipleVersionDownload
          onClose={() => {
            setIsDownloadDialogOpen(false);
          }}
          setIsDownloadDialogOpen={(value) => {
            setIsDownloadDialogOpen(value);
          }}
          isOpen={isDownloadDialogOpen}
          totalSizeValue={props.totalSizeValue}
          versionUpdate={props.versionUpdate}
          selectedVersion={props.selectedVersion}
          selectedBase={props.selectedBase}
          userCompany={props.userCompany}
          setUserCompany={props.setUserCompany}
          classes={props.classes}
          createSnackbar={props.createSnackbar}
          plans={props.plans}
          versions={props.versions}
          versionRename={props.versionRename}
          getPlanIndex={props.getPlanIndex}
          setIsDownloadingPlayable={props.setIsDownloadingPlayable}
          localizationLanguage={props.localizationLanguage}
        ></DialogMultipleVersionDownload>
        }

        {!isMultipleVersionActive
          &&
          <DialogVersionDownload
            onClose={() => {
              setIsDownloadDialogOpen(false);
            }}
            setIsDownloadDialogOpen={(value) => {
              setIsDownloadDialogOpen(value);
            }}
            isOpen={isDownloadDialogOpen}
            totalSizeValue={props.totalSizeValue}
            versionUpdate={props.versionUpdate}
            selectedVersion={props.selectedVersion}
            selectedBase={props.selectedBase}
            userCompany={props.userCompany}
            setUserCompany={props.setUserCompany}
            classes={props.classes}
            createSnackbar={props.createSnackbar}
            plans={props.plans}
            versions={props.versions}
            versionRename={props.versionRename}
            getPlanIndex={props.getPlanIndex}
            setIsDownloadingPlayable={props.setIsDownloadingPlayable}
          ></DialogVersionDownload>
        }
      </div>
    </div>
  );
}
