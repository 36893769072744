import { Slider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

/**
 *
 * @param props
 * @param props.classes
 * @param props.setValue
 * @param props.value
 * @returns {JSX.Element}
 */
export default function sliderScale(props) {
  const [portrait, setPortrait] = useState(_.get(props, `value.portrait`, 0.5));
  const [landscape, setLandscape] = useState(_.get(props, `value.landscape`, 0.5));

  useEffect(() => {
    setPortrait(_.get(props, `value.portrait`, 0.5));
    setLandscape(_.get(props, `value.landscape`, 0.5));
  }, [props.value]);
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (5).toString() + "px";
  }
  return (
    <>
      {" "}
      {props.isVisible && (
        <div>
          <Typography style={{ marginTop: gettingMargin(props.tooltipLength) }}>{props.label || "Scale"}</Typography>
          <Typography>Portrait</Typography>
          <Slider
            className={props.classes.sliderSize}
            value={portrait}
            aria-labelledby="labelOffsetX"
            valueLabelDisplay="auto"
            step={0.001}
            min={0.001}
            max={1}
            onChange={(event, newValue) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setPortrait(newValue);
              props.setValue({
                portrait: newValue,
                landscape,
              });
            }}
          />
          <Typography>Landscape</Typography>
          <Slider
            className={props.classes.sliderSize}
            value={landscape}
            aria-labelledby="labelOffsetX"
            valueLabelDisplay="auto"
            step={0.001}
            min={0.001}
            max={1}
            onChange={(event, newValue) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setLandscape(newValue);
              props.setValue({
                portrait,
                landscape: newValue,
              });
            }}
          />
        </div>
      )}
    </>
  );
}
