import { Slider, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";

/**
 *
 * @param props
 * @param props.classes
 * @param props.label
 * @param props.value
 * @param props.setValue
 * @returns {JSX.Element|null}
 */

export default function SliderGoToMarketAfterXTaps(props) {
  if (_.isNil(props.value)) {
    return null;
  }
  const [value, setValue] = useState(props.value);

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);
  return (
    <>
      {" "}
      {props.isVisible && (
        <div className={props.classes.editorItem}>
          <Typography id="discrete-slider" gutterBottom>
            {_.get(props, `label`)}
          </Typography>
          <Slider
            style={{ width: "100%" }}
            value={value}
            aria-labelledby="discrete-slider"
            valueLabelDisplay="auto"
            step={1}
            min={0}
            max={120}
            onChange={(event, newValue) => {
              if (props.userPermissions["update"] === false) {
                props.createSnackbar(
                  "You do not have permission to update, contact your administrator.",
                  "warning",
                  false
                );
                return;
              }
              setValue(newValue);
              props.setValue(newValue);
            }}
          />
        </div>
      )}
    </>
  );
}
