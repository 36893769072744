import React, { useEffect, useState, useRef, useContext } from "react";
import {
  CardContent,
  Card,
  Typography,
  TextField,
  Slider,
  FormControlLabel,
  ListItemText,
  FormControl,
  Checkbox,
  FormGroup,
  Switch,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  InputLabel,
  Select,
  Input,
  MenuItem,
  Chip,
  ListItemIcon,
  IconButton,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import {
  FiberManualRecord,
  PlayCircleOutline,
  Stop,
  PlayArrow,
  Add,
  Clear,
  Remove,
  ArrowForward,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import LinearProgress from "@material-ui/core/LinearProgress";
import LinearIndeterminate from "./progressBar";
import SelectorImage from "./selectorImage";
import SliderScale from "./sliderScale";
import SliderOrigin from "./sliderOrigin";
import SliderOffset from "./sliderOffset";
import SliderGeneric from "./sliderGeneric";
import SliderMaxLength from "./sliderMaxLength";
import SliderDelayOnEnd from "./sliderDelayOnEnd";
import ReactGA from "react-ga";
import ReactGA4 from "react-ga4";

import { getServerUri } from "../modules/uriUtility";
import { UndoContext } from "../contexts/UndoContext";

const constants = {
  maxHeight: 4000,
  maxWidth: 4000,
};

const useStyles = makeStyles((theme) => ({
  card: { margin: "5px" },
  progressBar: {
    width: "100%",
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
    position: "sticky",
    top: 0,
  },
  title: {
    marginTop: "0.3rem",
  },
  addConfigButton: {
    float: "right",
    margin: "1rem 0.7rem 0",
  },
  removeConfigButton: {
    color: "#CC736E",
    padding: "0.5rem 0.5rem",
  },
  icon: {
    marginTop: "0.5rem",
  },
  recordButton: {
    margin: "1rem 1rem 0rem 0",
  },
  stopButton: {
    backgroundColor: "#CC736E",
    "&:hover": {
      backgroundColor: "#a25c58",
    },
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  selectedChip: {
    background: "#4eabb1",
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#50ADB6",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(255, 255, 255, 0.16)",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08) !important",
    },
  },
}));

/**
 * @param props.isPreviewLoaded
 * @param props.iFrameId
 * @param props.selectedBase
 * @param props.selectedVersion
 * @param props.setKeyValue
 * @param props.classes
 * @param props.setSelectedVersionPathValue
 * @param props.setIsPreviewFullScreen {function}
 * @param props.doRefresh {function}
 * @param props.maxInnerHeight
 * @param props.isRecordingStarted
 * @param props.setIsRecordingStarted
 * @param props.userPermissions
 * @param props.createSnackbar
 * @param props.setIsDialogConfirmRefreshOpen
 * @param props.setIsDialogCircularProgressOpen
 * @returns {JSX.Element|null}
 */
let timeoutID = null;
export default function recordAndAutoPlayContainer(props) {
  const classes = useStyles();
  const undoCtx = useContext(UndoContext);

  const setValue = (p) => {
    props.setSelectedVersionPathValue(p);
  };
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        paddingLeft: "16px",
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  const [isRecording, setRecording] = useState(false);
  const [isAutoplay, setAutoplay] = useState(false);
  const [configs, setConfigs] = useState(_.get(props, "selectedVersion.recorderSettings.configs", null));
  const configsRef = useRef(configs);
  const configsForRecord = useRef(configs);
  const isAutoplayClicked = useRef(false);
  const selectedIndex = useRef(0);
  const arrowIndex = useRef(0);
  const [isLoading, setLoading] = useState(false);
  const [manuelRecordWidth, setManuelRecordWidth] = useState("");
  const [manuelRecordHeight, setManuelRecordHeight] = useState("");
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const isAllSelected = useRef(false);
  const currentLanguage = useRef();
  const [delayOnEnd, setDelayOnEnd] = useState(0);

  useEffect(() => {
    let configList = _.get(props, "selectedVersion.recorderSettings.configs", []);
    prepareConfigsForRecordWithLanguages(configList);
    isAllSelected.current = languages.length > 0 && selectedLanguages.length === languages.length;
  }, [selectedLanguages]);

  useEffect(() => {
    if (props.isRecordingStarted) startRecording();
  }, [props.isRecordingStarted]);

  useEffect(() => {
    props.setIsRecordingStarted(false);

    let selectedLanguagesFromDb = _.get(props, "selectedVersion.recorderSettings.selectedLanguages", []);
    setSelectedLanguages(selectedLanguagesFromDb);

    setAutoplay(_.get(props, "selectedVersion.gameConfig.components.autoplay1.startAutoplay", false));

    if (props.selectedVersion.recorderSettings) {
      updateConfigState(props.selectedVersion.recorderSettings.configs, true);
    } else {
      updateConfigState(null, true);
    }

    let createdLanguages = getCreatedLanguages();
    setLanguages(createdLanguages);
  }, [props.selectedVersion]);

  function isAutoplayExist() {
    return !_.isNil(_.get(props, "selectedBase.gameConfig.components.autoplay1", null));
  }

  async function handleMessageFromExtension(message) {
    if (!message) return;
    switch (message.data.action) {
      // case "startRecording":

      //     break;
      case "stopRecording":
        updateVideoCounter(1);
        setRecording(false);
        if (message.data.isAutoplay) {
          await toggleAutoplay(true);
        }
        props.setIsPreviewFullScreen(false);
        break;
      case "conversionStarted":
        setLoading(true);
        props.doRefresh((prev) => prev + 1);
        break;
      case "completed":
        setLoading(false);
        if (!message.data.isSuccess) {
          props.createSnackbar("Error occured while recording. Please try again", "error");
        }
        startNextRecord();
        break;
      default:
        break;
    }
    if (message.data.action === "stopRecording") {
      //handled in extension
      return;
    }
  }

  function getCreatedLanguages() {
    let components = Object.values(_.get(props, "selectedVersion.gameConfig.components", {}));
    let componentWithLocalization = components.find((component) => !_.isNil(component.localization));
    let createdLanguages = [];
    if (!_.isNil(componentWithLocalization)) {
      Object.keys(componentWithLocalization.localization).forEach((language) => {
        Object.entries(props.selectedBase.supportedLanguages).forEach(([key, value]) => {
          if (language === key) {
            createdLanguages.push({ key, value });
          }
        });
      });
    }
    return createdLanguages;
  }

  function toggleAutoplay(isAutoplay) {
    const childDocument = document.querySelector("iframe").contentWindow.document;
    var canvas = childDocument.querySelector("canvas");
    if (!canvas) return;

    console.info("toggle autoplay on this version", props.selectedVersion);
    setValue({
      path: `gameConfig.components.autoplay1.startAutoplay`,
      value: !isAutoplay,
    });

    setAutoplay(!isAutoplay);
  }

  function focusOnIFrame(isPreviewFullScreen) {
    try {
      // setTimeout(() => {
      //     window.postMessage({
      //         action: "startRecording"
      //     }, "*");
      // }, 6000)

      props.setIsPreviewFullScreen(isPreviewFullScreen);
      document.getElementById(props.iFrameId).focus();
    } catch (err) {
      console.error(err);
    }
  }

  function setGameLanguage(language) {
    const newGameConfig = _.cloneDeep(_.get(props, `selectedVersion.gameConfig`));
    _.set(newGameConfig, "options.language", language);
    setValue({
      path: `gameConfig`,
      value: newGameConfig,
    });
    currentLanguage.current = language;
  }

  function startNextRecord(manuelConfig) {
    let selectedConfig;
    let configList;
    if (manuelConfig) {
      // Manuel Recording
      selectedConfig = _.cloneDeep(manuelConfig);
      configList = [manuelConfig];
    } else {
      // Batch Recording
      selectedConfig = configsForRecord.current[selectedIndex.current];
      configList = [...configs];
      if (selectedConfig) {
        setGameLanguage(selectedConfig.language);
      }
    }

    if (!checkConfigsIsValid(configList) && selectedIndex.current < 1) {
      props.createSnackbar("Width or height value is empty or invalid", "warning");
      return;
    }

    if (selectedConfig) {
      let prevSelectedConfig = configsForRecord.current[selectedIndex.current - 1];
      if (prevSelectedConfig) {
        if (selectedConfig.id !== prevSelectedConfig.id) {
          arrowIndex.current = arrowIndex.current + 1;
        }
      } else if (manuelConfig) {
        arrowIndex.current = -1;
      } else {
        arrowIndex.current = 1;
      }
      selectedIndex.current = selectedIndex.current + 1;
      prepareRecorder(selectedConfig.width, selectedConfig.height, selectedConfig.language);
    } else {
      stopRecording();
      props.createSnackbar("Recording completed successfully", "success");
      window.postMessage(
        {
          action: "recordQueueFinished",
        },
        "*"
      );
      window.removeEventListener("message", handleMessageFromExtension);
    }
  }

  function stopRecording() {
    setRecording(false);
    isAutoplayClicked.current = false;
    selectedIndex.current = -1;
    arrowIndex.current = -1;
    currentLanguage.current = "";
    setConfigs((prevConfigs) => [...prevConfigs]); // for reflect selectedIndex change above
    window.postMessage(
      {
        action: "recordQueueFinished",
      },
      "*"
    );
    window.removeEventListener("message", handleMessageFromExtension);
  }

  function checkConfigsIsValid(configs) {
    let isValid = true;
    configs.some((config) => {
      if (!config.width || !config.height || !isNumeric(config.width) || !isNumeric(config.height)) {
        isValid = false;
        return true;
      }
    });
    return isValid;
  }

  function prepareRecorder(width, height, language) {
    window.postMessage(
      {
        action: "prepareRecorder",
        data: {
          width,
          height,
          language,
          delayOnEnd: delayOnEnd,
        },
        isAutoplay: isAutoplayClicked.current,
      },
      "*"
    );
    focusOnIFrame(true); // also starts the record with make iframe fullstack to trigger dialogCountDown counter, it will start record when countdown is zero
    setRecording(true);
  }

  function startRecording() {
    if (isAutoplayClicked.current) {
      toggleAutoplay();
    }
    window.postMessage(
      {
        action: "startRecording",
      },
      "*"
    );
  }

  function updateConfig(index, key, newValue) {
    if (!isNumeric(newValue) && !(!newValue || newValue.length === 0)) return;
    if (configs) {
      if (key === "height" && newValue > constants.maxHeight) {
        props.createSnackbar(
          ["Height value should be under ", <strong>&nbsp;{constants.maxHeight}</strong>],
          "warning"
        );
        return;
      }
      if (key === "width" && newValue > constants.maxWidth) {
        props.createSnackbar(["Width value should be under ", <strong>&nbsp;{constants.maxWidth}</strong>], "warning");
        return;
      }
      if (key === "height" && newValue > props.maxInnerHeight) {
        props.createSnackbar(
          [
            "Available maximum window height is ",
            <strong>&nbsp;{props.maxInnerHeight}</strong>,
            ", zooming will be applied.",
          ],
          "warning"
        );
      }
      //copy the array first
      const updatedArray = [...configs];
      let config = updatedArray[index];
      config[key] = newValue;
      updateConfigState(updatedArray, false);
    }
  }

  function addNewConfig() {
    let newConfig = {
      id: configs.length + 1,
      width: "",
      height: "",
    };
    updateConfigState([...configs, newConfig], false);
  }

  function removeConfig(configId) {
    updateConfigState(
      configsRef.current.filter((config) => config.id !== configId),
      false
    );
  }

  // Keeps the state and ref equal
  function updateConfigState(newState, isEffect) {
    if (newState) {
      //let configList = [...newState];
      let configList = JSON.parse(JSON.stringify(newState));

      //cache state of configs
      configsRef.current = configList;
      prepareConfigsForRecordWithLanguages(configList);

      setConfigs(configList);
      if (!isEffect) {
        setValue({
          path: "recorderSettings.configs",
          value: [...configList],
        });
      }
    }
  }

  function prepareConfigsForRecordWithLanguages(configList) {
    //multiply by selectedLanguage
    let configsWithLanguages = [];
    configList.forEach((config) => {
      selectedLanguages.forEach((selectedLanguage) => {
        let newConfig = _.cloneDeep(config);
        newConfig["language"] = selectedLanguage;

        configsWithLanguages.push(newConfig);
      });
    });
    configsForRecord.current = configsWithLanguages;
  }

  const handleChange = (event) => {
    if (props.userPermissions["update"] === false) {
      props.createSnackbar("You are not authorized to update, contact your administrator.", "warning", false);
      return;
    }
    const value = event.target.value;
    let languagesKeys = languages.map((language) => language.key);
    let selectedLanguageList = [];

    if (value[value.length - 1] === "all") {
      selectedLanguageList = selectedLanguages.length === languages.length ? [] : languagesKeys;
      setSelectedLanguages(selectedLanguageList);
      setValue({
        path: "recorderSettings.selectedLanguages",
        value: [...selectedLanguageList],
      });
      return;
    }

    setSelectedLanguages(value);
    setValue({
      path: "recorderSettings.selectedLanguages",
      value: [...value],
    });
  };

  function isNumeric(str) {
    if (typeof str != "string") return false;
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  function renderAutoplayHand() {
    let components = _.get(props, "selectedBase.recorderSettings.components", []);

    return (
      <div>
        {!_.isNil(components) &&
          components.map((component) => (
            <Card className={props.classes.card} key={`${component.id}-${component.caption.replace(/\s/g, "")}`}>
              <CardContent>
                <div style={{ "display": "flex", "justifyContent": "space-between" }}>
                  <Typography className={classes.title} variant={"h6"}>
                    {_.get(component, "caption")}
                  </Typography>
                </div>
                {_.get(component, "settings").map((setting) => {
                  if (setting.editorType === "selectorImage")
                    return (
                      <SelectorImage
                        key={`${component.id}.${setting.path}`}
                        selectedBase={props.selectedBase}
                        componentID={component.id}
                        label={setting.caption}
                        classes={props.classes}
                        images={_.get(props, "media.images", [])}
                        componentType={component.type}
                        path={setting.path}
                        destination={setting.destination}
                        bucket={setting.bucket}
                        selectedImage={_.get(
                          props,
                          `selectedVersion.gameConfig.components.${component.id}.${setting.path}[0]`
                        )}
                        setValue={(p) => {
                          setValue({
                            path: `gameConfig.components.${component.id}.${setting.path}`,
                            value: p.value,
                          });
                        }}
                        insertMediaPathValue={props.insertMediaPathValue}
                        userPermissions={props.userPermissions}
                        selectedVersion={props.selectedVersion}
                        createSnackbar={props.createSnackbar}
                        isVisible={true}
                        setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                        setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                      ></SelectorImage>
                    );

                  if (setting.editorType === "sliderGeneric")
                    return (
                      <SliderGeneric
                        id={`${component.id}.${setting.path}`}
                        key={`${component.id}.${setting.path}`}
                        classes={props.classes}
                        tooltipLength={-28}
                        tooltip={setting.tooltip}
                        newSetPortrait={props.newSetPortrait}
                        min={setting.min}
                        max={setting.max}
                        step={setting.step}
                        value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 0)}
                        path={`gameConfig.components.${component.id}.${setting.path}`}
                        y_axis={!!setting.y_axis}
                        editorSubType={setting.editorSubType}
                        label={setting.caption}
                        setValue={(pathToSet, value) => {
                          setValue({
                            path: pathToSet,
                            value,
                          });
                        }}
                        // setValue={(value,pathToSet) => {
                        //     setValue({
                        //         path: `gameConfig.components.${component.id}.${setting.path}`,
                        //         value
                        //     });
                        // }}
                        userPermissions={props.userPermissions}
                        createSnackbar={props.createSnackbar}
                        isVisible={true}
                      ></SliderGeneric>
                    );
                })}
              </CardContent>
            </Card>
          ))}
      </div>
    );
  }

  function fetchCompany(companyId) {
    return new Promise((resolve, reject) => {
      fetch(getServerUri("company/findOne"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          companyID: companyId,
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response
              .json()
              .then((company) => {
                props.setUserCompany(company);
                resolve(company);
              })
              .catch((err) => reject);
          } else {
            response
              .text()
              .then((err) => reject)
              .catch((err) => reject);
          }
        })
        .catch((err) => reject);
    });
  }

  function updateVideoCounter(createdVideos) {
    fetchCompany(_.get(props.userCompany, "_id")).then((company) => {
      const newCompany = _.cloneDeep(company);
      if (!_.has(newCompany.subscription.periods[newCompany.subscription.periods.length - 1], "monthlyCreatedVideos")) {
        _.set(newCompany.subscription.periods[newCompany.subscription.periods.length - 1], "monthlyCreatedVideos", 0);
      }
      let oldVal = _.get(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "monthlyCreatedVideos"
      );
      _.set(
        newCompany.subscription.periods[newCompany.subscription.periods.length - 1],
        "monthlyCreatedVideos",
        parseInt(oldVal) + parseInt(createdVideos)
      );
      props.setUserCompany(newCompany);
      const fetchUri = getServerUri("company/updateOne");
      const fetchInit = {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          companyID: String(_.get(newCompany, "_id")),
          subscription: JSON.stringify(_.get(newCompany, "subscription")),
        }),
      };
      fetch(fetchUri, fetchInit)
        .then((response) => {
          if (response.status === 200) {
            props.setUserCompany(newCompany);
            console.debug("createdVideos updated");
          } else {
            response
              .text()
              .then((textResponse) => {
                console.log("createdVideos update failed: " + textResponse);
              })
              .catch(console.error);
          }
        })
        .catch((err) => {
          console.error(err);
        });
    });
  }

  return (
    <>
      {configs && isAutoplayExist() && (
        <FormGroup>
          {isLoading ? (
            <div className={classes.progressBar}>
              <LinearProgress />
            </div>
          ) : (
            ""
          )}
          {!props.isRecorderEnabled && (
            <Card className={props.classes.card}>
              <CardContent>
                <Typography variant="caption" display="block" gutterBottom>
                  * Please tap playin extension to activate recorder feature
                </Typography>
              </CardContent>
            </Card>
          )}
          <Card className={props.classes.card}>
            <CardContent>
              <Typography className={classes.title} variant="h6" gutterBottom>
                Manual Recording
              </Typography>
              <div style={{ padding: "10px", textAlign: "center", marginTop: "1rem" }}>
                <Grid container spacing={1}>
                  <Grid item xs={4} sm={4}>
                    <Input
                      type="text"
                      value={manuelRecordWidth}
                      disabled={!props.isRecorderEnabled || isRecording}
                      placeholder={"width"}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      onChange={(event) => {
                        if (props.userPermissions["update"] === false) {
                          props.createSnackbar(
                            "You do not have permission to update, contact your administrator.",
                            "warning",
                            false
                          );
                          return;
                        }
                        if (isNumeric(event.target.value) || !event.target.value || event.target.value.length === 0) {
                          if (event.target.value > constants.maxWidth)
                            props.createSnackbar(
                              ["Width can not exceed", <strong>&nbsp;{constants.maxWidth}</strong>],
                              "warning"
                            );
                          else setManuelRecordWidth(event.target.value);
                        }
                      }}
                    ></Input>
                  </Grid>
                  <Grid item xs={2} sm={2}>
                    <Clear className={classes.icon} />
                  </Grid>
                  <Grid item xs={4} sm={4}>
                    <Input
                      type="text"
                      value={manuelRecordHeight}
                      disabled={!props.isRecorderEnabled || isRecording}
                      placeholder={"height"}
                      inputProps={{ min: 0, style: { textAlign: "center" } }}
                      onChange={(event) => {
                        if (props.userPermissions["update"] === false) {
                          props.createSnackbar(
                            "You do not have permission to update, contact your administrator.",
                            "warning",
                            false
                          );
                          return;
                        }
                        if (isNumeric(event.target.value) || !event.target.value || event.target.value.length === 0) {
                          if (event.target.value > constants.maxHeight)
                            props.createSnackbar(
                              ["Height can not exceed", <strong>&nbsp;{constants.maxHeight}</strong>],
                              "warning"
                            );
                          else {
                            setManuelRecordHeight(event.target.value);
                            if (event.target.value > props.maxInnerHeight) {
                              props.createSnackbar(
                                [
                                  "Available maximum window height is",
                                  <strong>&nbsp;{props.maxInnerHeight}</strong>,
                                  ". If the higher than possible height, zooming will be applied.",
                                ],
                                "warning"
                              );
                            }
                          }
                        }
                      }}
                    ></Input>
                  </Grid>
                  <Grid item xs={2} sm={2}></Grid>
                </Grid>
              </div>
              {!isRecording ? (
                <Button
                  color="secondary"
                  className={classes.recordButton}
                  variant="contained"
                  disabled={isAutoplay || isRecording || isLoading || !props.isRecorderEnabled}
                  onClick={(event) => {
                    ReactGA.event({
                      category: "Config",
                      action: "Manual Recording - Record Clicked",
                      label:
                        "game: " +
                        props.selectedGame.title +
                        ", base: " +
                        props.selectedBase.title +
                        ", version: " +
                        props.selectedVersion.title,
                      dimension1: localStorage.getItem("user.id"),
                    });
                    // size bilgisi eklencek
                    ReactGA4.event({
                      category: "Config",
                      action: "Manual Recording - Record Clicked",
                      label:
                        "game: " +
                        props.selectedGame.title +
                        ", base: " +
                        props.selectedBase.title +
                        ", version: " +
                        props.selectedVersion.title,
                      dimension1: localStorage.getItem("user.id"),
                    });
                    event.preventDefault();
                    selectedIndex.current = -2; // next index will be -1 and invalid index
                    window.addEventListener("message", handleMessageFromExtension);
                    startNextRecord({
                      width: manuelRecordWidth,
                      height: manuelRecordHeight,
                    });
                  }}
                  startIcon={<FiberManualRecord />}
                >
                  Record
                </Button>
              ) : null}
            </CardContent>
          </Card>
          <Card className={props.classes.card}>
            <CardContent>
              <Typography className={classes.title} variant="h6" gutterBottom>
                Batch Recording
              </Typography>
              {isAutoplayExist() && (
                <div>
                  <div style={{ marginTop: "1rem" }}>
                    <Typography variant="body1">Autoplay Settings</Typography>
                  </div>
                  <div style={{ marginTop: "1rem" }}>{renderAutoplayHand()}</div>
                  <div>
                    <Button
                      color="secondary"
                      variant="contained"
                      className={classes.recordButton}
                      style={{ marginBottom: "1rem" }}
                      onClick={() => {
                        props.setSelectedVersionPathValue({
                          path: `gameConfig.options.isTutorialEnabled`,
                          value: isAutoplay,
                        });
                        if (isAutoplay) {
                          props.doRefresh((prev) => prev + 1);
                          ReactGA.event({
                            category: "Config",
                            action: "Autoplay Stop Clicked",
                            label:
                              "game: " +
                              props.selectedGame.title +
                              ", base: " +
                              props.selectedBase.title +
                              ", version: " +
                              props.selectedVersion.title,
                            dimension1: localStorage.getItem("user.id"),
                          });
                          ReactGA4.event({
                            category: "Config",
                            action: "Autoplay Stop Clicked",
                            label:
                              "game: " +
                              props.selectedGame.title +
                              ", base: " +
                              props.selectedBase.title +
                              ", version: " +
                              props.selectedVersion.title,
                            dimension1: localStorage.getItem("user.id"),
                          });
                        } else {
                          ReactGA.event({
                            category: "Config",
                            action: "Autoplay Preview Clicked",
                            label:
                              "game: " +
                              props.selectedGame.title +
                              ", base: " +
                              props.selectedBase.title +
                              ", version: " +
                              props.selectedVersion.title,
                            dimension1: localStorage.getItem("user.id"),
                          });
                          ReactGA4.event({
                            category: "Config",
                            action: "Autoplay Preview Clicked",
                            label:
                              "game: " +
                              props.selectedGame.title +
                              ", base: " +
                              props.selectedBase.title +
                              ", version: " +
                              props.selectedVersion.title,
                            dimension1: localStorage.getItem("user.id"),
                          });
                        }
                        toggleAutoplay(isAutoplay);
                        focusOnIFrame(false);
                      }}
                      // disabled={isRecording || isLoading}
                      startIcon={!isAutoplay ? <PlayArrow /> : <Stop />}
                    >
                      {!isAutoplay ? "Preview" : "Stop"}
                    </Button>
                  </div>
                </div>
              )}
              <hr style={{ backgroundColor: "gray", border: "none", height: "1px" }} />
              <div style={{ marginTop: "1.25rem" }}>
                <Typography>Autoplay & Record</Typography>
                <FormControl className={classes.formControl}>
                  <InputLabel id="mutiple-select-label">Select Multiple Language</InputLabel>
                  <Select
                    labelId="mutiple-select-label"
                    multiple
                    disabled={isAutoplay || isRecording || isLoading || !props.isRecorderEnabled}
                    value={selectedLanguages}
                    onChange={handleChange}
                    renderValue={(selected) => (
                      <div className={classes.chips}>
                        {selected.map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={
                              classes.chip + " " + (currentLanguage.current === value ? classes.selectedChip : "")
                            }
                          />
                        ))}
                      </div>
                    )}
                    MenuProps={MenuProps}
                  >
                    <MenuItem
                      value="all"
                      classes={{
                        root: isAllSelected.current ? classes.selectedAll : "",
                      }}
                    >
                      <ListItemIcon>
                        <Checkbox checked={isAllSelected.current} />
                      </ListItemIcon>
                      <ListItemText classes={{ primary: classes.selectAllText }} primary="Select All" />
                    </MenuItem>
                    {languages.map((option) => (
                      <MenuItem key={option.key} value={option.key} classes={{ root: classes.menuItem }}>
                        <ListItemIcon>
                          <Checkbox checked={selectedLanguages.indexOf(option.key) > -1} />
                        </ListItemIcon>
                        <ListItemText primary={option.value} />
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <div className={classes.addConfigButton}>
                  <IconButton
                    color="secondary"
                    disabled={isAutoplay || isRecording || isLoading || !props.isRecorderEnabled}
                    onClick={(event) => {
                      if (props.userPermissions["update"] === false) {
                        props.createSnackbar(
                          "You do not have permission to update, contact your administrator.",
                          "warning",
                          false
                        );
                        return;
                      }
                      ReactGA.event({
                        category: "Config",
                        action: "New Resolution Added",
                        label:
                          "game: " +
                          props.selectedGame.title +
                          ", base: " +
                          props.selectedBase.title +
                          ", version: " +
                          props.selectedVersion.title,
                        dimension1: localStorage.getItem("user.id"),
                      });
                      ReactGA4.event({
                        category: "Config",
                        action: "New Resolution Added",
                        label:
                          "game: " +
                          props.selectedGame.title +
                          ", base: " +
                          props.selectedBase.title +
                          ", version: " +
                          props.selectedVersion.title,
                        dimension1: localStorage.getItem("user.id"),
                      });
                      event.preventDefault();
                      addNewConfig();
                    }}
                  >
                    <Add />
                  </IconButton>
                </div>
              </div>
              <div style={{ marginTop: "1rem" }}>
                <Typography variant="subtitle2" style={{ paddingLeft: "0.5rem" }}>
                  Resolutions
                </Typography>
                <div style={{ padding: "10px", textAlign: "center" }}>
                  {configs.map((config, index) => (
                    <Grid container spacing={1} key={index}>
                      <Grid item xs={1} sm={1}>
                        {index === arrowIndex.current - 1 && (
                          <ArrowForward className={classes.icon} style={{ color: "#4eabb1" }} />
                        )}
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Input
                          type="text"
                          value={config.width}
                          placeholder={"width"}
                          disabled={!props.isRecorderEnabled || isRecording}
                          inputProps={{ min: 0, style: { textAlign: "center" } }}
                          onChange={(event) => {
                            if (!event.target) return;

                            if (props.userPermissions["update"] === false) {
                              props.createSnackbar(
                                "You do not have permission to update, contact your administrator.",
                                "warning",
                                false
                              );
                              return;
                            }
                            updateConfig(index, "width", event.target.value);
                          }}
                        ></Input>
                      </Grid>
                      <Grid item xs={1} sm={1}>
                        <Clear className={classes.icon} />
                      </Grid>
                      <Grid item xs={4} sm={4}>
                        <Input
                          type="text"
                          value={config.height}
                          placeholder={"height"}
                          disabled={!props.isRecorderEnabled || isRecording}
                          inputProps={{ min: 0, style: { textAlign: "center" } }}
                          onChange={(event) => {
                            if (props.userPermissions["update"] === false) {
                              props.createSnackbar(
                                "You do not have permission to update, contact your administrator.",
                                "warning",
                                false
                              );
                              return;
                            }
                            updateConfig(index, "height", event.target.value);
                          }}
                        ></Input>
                      </Grid>
                      <Grid item xs={2} sm={2}>
                        <IconButton
                          className={classes.removeConfigButton}
                          disabled={isAutoplay || isRecording || isLoading || !props.isRecorderEnabled}
                          onClick={(event) => {
                            if (props.userPermissions["update"] === false) {
                              props.createSnackbar(
                                "You do not have permission to update, contact your administrator.",
                                "warning",
                                false
                              );
                              return;
                            }
                            ReactGA.event({
                              category: "Config",
                              action: "Resolution Removed",
                              label:
                                "game: " +
                                props.selectedGame.title +
                                ", base: " +
                                props.selectedBase.title +
                                ", version: " +
                                props.selectedVersion.title,
                              dimension1: localStorage.getItem("user.id"),
                            });
                            ReactGA4.event({
                              category: "Config",
                              action: "Resolution Removed",
                              label:
                                "game: " +
                                props.selectedGame.title +
                                ", base: " +
                                props.selectedBase.title +
                                ", version: " +
                                props.selectedVersion.title,
                              dimension1: localStorage.getItem("user.id"),
                            });
                            removeConfig(config.id);
                          }}
                        >
                          <Remove />
                        </IconButton>
                      </Grid>
                    </Grid>
                  ))}
                </div>
              </div>
              {configs.length === 0 && (
                <Typography variant="caption" display="block" gutterBottom>
                  There is no configuration
                </Typography>
              )}

              <div style={{ marginTop: "2rem" }}>
                <Typography variant="subtitle2" style={{ paddingLeft: "0.5rem" }}>
                  Delay After Game End
                </Typography>
                <SliderDelayOnEnd
                  classes={props.classes}
                  disabled={isAutoplay || isRecording || isLoading || !props.isRecorderEnabled}
                  value={_.get(props, `selectedVersion.recorderSettings.delayOnEnd`, 0)}
                  min={0}
                  max={10}
                  step={1}
                  setValue={(value) => {
                    setDelayOnEnd(value);
                  }}
                  userPermissions={props.userPermissions}
                  createSnackbar={props.createSnackbar}
                ></SliderDelayOnEnd>
              </div>
              <div style={{ marginBottom: "0.5rem" }}>
                {isAutoplayExist() && (
                  <>
                    {!isLoading ? (
                      <Button
                        color="secondary"
                        variant="contained"
                        className={classes.recordButton}
                        disabled={
                          isAutoplay ||
                          isRecording ||
                          !props.isRecorderEnabled ||
                          selectedLanguages.length === 0 ||
                          configsRef.current.length === 0
                        }
                        startIcon={<PlayCircleOutline />}
                        onClick={(event) => {
                          if (
                            props.userCompany.subscription.periods[props.userCompany.subscription.periods.length - 1]
                              .monthlyCreatedVideos +
                              configs.length >
                            props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)].numberOfVideos
                          ) {
                            props.createSnackbar(
                              `Your company's current plan allows up to ${
                                props.plans[props.getPlanIndex(props.userCompany.subscription.currentPlan)]
                                  .numberOfVideos
                              } videos`,
                              "warning"
                            );
                            return;
                          }
                          ReactGA.event({
                            category: "Config",
                            action: "Autoplay & Record - Record Clicked",
                            label:
                              "game: " +
                              props.selectedGame.title +
                              ", base: " +
                              props.selectedBase.title +
                              ", version: " +
                              props.selectedVersion.title,
                            dimension1: localStorage.getItem("user.id"),
                          });
                          ReactGA4.event({
                            category: "Config",
                            action: "Autoplay & Record - Record Clicked",
                            label:
                              "game: " +
                              props.selectedGame.title +
                              ", base: " +
                              props.selectedBase.title +
                              ", version: " +
                              props.selectedVersion.title,
                            dimension1: localStorage.getItem("user.id"),
                          });
                          event.preventDefault();
                          isAutoplayClicked.current = true;
                          selectedIndex.current = 0;
                          arrowIndex.current = 0;
                          window.addEventListener("message", handleMessageFromExtension);
                          startNextRecord();
                        }}
                      >
                        Record
                      </Button>
                    ) : (
                      <Button
                        variant="contained"
                        className={classes.recordButton + " " + classes.stopButton}
                        disabled={false}
                        startIcon={<Stop />}
                        onClick={(event) => {
                          ReactGA.event({
                            category: "Config",
                            action: "Autoplay & Record - Stop Clicked",
                            label:
                              "game: " +
                              props.selectedGame.title +
                              ", base: " +
                              props.selectedBase.title +
                              ", version: " +
                              props.selectedVersion.title,
                            dimension1: localStorage.getItem("user.id"),
                          });
                          ReactGA4.event({
                            category: "Config",
                            action: "Autoplay & Record - Stop Clicked",
                            label:
                              "game: " +
                              props.selectedGame.title +
                              ", base: " +
                              props.selectedBase.title +
                              ", version: " +
                              props.selectedVersion.title,
                            dimension1: localStorage.getItem("user.id"),
                          });
                          stopRecording();
                          props.createSnackbar("Recording will be stopped after loading is finished", "info");
                        }}
                      >
                        Stop
                      </Button>
                    )}
                  </>
                )}
              </div>
            </CardContent>
          </Card>
        </FormGroup>
      )}
    </>
  );
}
