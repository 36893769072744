import React, { useEffect, useRef, useState } from "react";
import { Card, CardContent, IconButton, makeStyles, MenuItem, TextField, Typography } from "@material-ui/core";
import _ from "lodash";
import SwitchGeneric from "./switchGeneric";
import Tooltip from "@material-ui/core/Tooltip";
import Fab from "@material-ui/core/Fab";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import { Add, Remove } from "@material-ui/icons";
import SelectorCardOrder from "./selectorCardOrder";
import SelectorColor from "./selectorColor";
import SliderCheerEveryXMoves from "./sliderCheerEveryXMoves";
import SelectorGeneric from "./selectorGeneric";
import SelectorImage from "./selectorImage";
import SliderPosition from "./sliderPosition";
import SliderScale from "./sliderScale";
import SliderGeneric from "./sliderGeneric";
import SelectorAtlas from "./selectorAtlas";
const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  formControl: {
    margin: theme.spacing(1),
    width: 300,
  },
  indeterminateColor: {
    color: "#50ADB6",
  },
  selectAllText: {
    fontWeight: 500,
  },
  selectedAll: {
    backgroundColor: "rgba(255, 255, 255, 0.16)",
  },
  menuItem: {
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.08) !important",
    },
  },
}));

/**
 * @param props.value
 * @param props.components
 * @returns {JSX.Element}
 */
export default function selectorRibbonType(props) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const classes = useStyles();

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
        paddingLeft: "16px",
      },
    },
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "center",
    },
    transformOrigin: {
      vertical: "top",
      horizontal: "center",
    },
    variant: "menu",
  };

  if (_.isNil(props.value)) {
    return null;
  }
  useEffect(() => {
    checkMargin();
  }, [props.isVisible]);

  function checkMargin() {
    try {
      if (props.tooltip) {
        document.getElementById(`${props.id}.textField-label`).style.marginTop = "-48.5px";
      } else {
        document.getElementById(`${props.id}.textField-label`).style.marginTop = "-30.5px";
      }
    } catch {}
  }

  const setValue = _.debounce((p) => {
    props.setSelectedVersionPathValue(p);
  }, 200);

  let [selectedValue, setSelectedValue] = useState(props.value);
  const [components, setComponents] = useState(props.components);
  const [component, setComponent] = useState(_.get(components, "[0]", null));

  useEffect(() => {
    setComponentFromType(props.value);
  }, []);

  useEffect(() => {
    setSelectedValue(props.value);
    setComponentFromType(props.value);
  }, [props.value]);

  const setComponentFromType = (ribbonType) => {
    let componentId;
    if (ribbonType === "Ribbon Type 1") {
      componentId = "cheerBoard1";
    } else if (ribbonType === "Ribbon Type 2") {
      componentId = "cheerBoardWithTexture1";
    } else if (ribbonType === "Ribbon Type 3") {
      componentId = "cheerBoardWithAtlas1";
    }
    let selectedComponent = components.find((component) => component.id === componentId);
    setComponent(selectedComponent);
  };

  function renderTooltip(tooltip, isVisible, marginLeft) {
    return isVisible && tooltip ? (
      // Tooltip for image upload sizes, .etc.  style={{marginBottom:"-60px"}}
      <div>
        <Tooltip title={tooltip} aria-label="add">
          {/* <Fab
                  color="secondary"
                  className={classes.absolute}
                  style={{
                     marginLeft: tooltipLength + "px",
                     // width: "5px",
                     // height: "5px",
                     // minHeight: "5px",
                     backgroundColor: "transparent",
                  }}
               > */}
          {/* style={{ marginLeft: tooltipLength + "px", marginTop: "20px" }} */}
          <HelpOutlineIcon style={{ marginLeft: marginLeft }} color={"primary"} />
          {/* </Fab> */}
        </Tooltip>
      </div>
    ) : null;
  }
  function getTextWidth(ttt) {
    let text = document.createElement("span");
    document.body.appendChild(text);

    (text.style.font = " Roboto"), "Helvetica", "Arial", "sans-serif";
    text.style.fontSize = "0.9285714285714286rem";
    text.style.height = "auto";
    text.style.width = "auto";
    text.style.letterSpacing = "0.00938em";
    text.style.position = "absolute";
    text.style.whiteSpace = "no-wrap";
    text.innerHTML = ttt;

    let width = Math.ceil(text.clientWidth);
    let formattedWidth = width + "px";

    document.body.removeChild(text);
    return width;
  }
  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }
  const renderSelect = () => (
    <TextField
      select
      label={_.get(props, `label`)}
      id={`${props.id}.textField`}
      value={selectedValue}
      InputLabelProps={{ shrink: false, style: { marginTop: gettingMargin(props.tooltipLength) } }}
      className={props.classes.textField}
      onChange={(event) => {
        if (props.userPermissions["update"] === false) {
          props.createSnackbar("You do not have permission to update, contact your administrator.", "warning", false);
          return;
        }
        setSelectedValue(event.target.value);
        props.setValue(props.path, event.target.value);
      }}
    >
      {props.items.map((item, index) => {
        return (
          <MenuItem key={index} value={item}>
            {item}
          </MenuItem>
        );
      })}
    </TextField>
  );

  return (
    <>
      {" "}
      {
        <div className={props.classes.editorItem} id={`${props.id}.Div`}>
          {renderSelect()}
          {!_.isNil(component) && props.isVisible && (
            <div className={props.classes.card} key={`${component.id}-${component.caption.replace(/\s/g, "")}`}>
              {_.get(component, "settings").map((setting) => {
                if (setting.editorType === "switchGeneric") {
                  let tooltipLength = getTextWidth(setting.caption);

                  return (
                    <div key={`${component.id}.${setting.path}`}>
                      {renderTooltip(setting.tooltip, props.isVisible, tooltipLength + 52)}

                      <SwitchGeneric
                        style={{ width: "100%" }}
                        tooltipLength={-55}
                        tooltip={setting.tooltip}
                        key={`${component.id}.${setting.path}`}
                        classes={props.classes}
                        value={_.get(
                          props,
                          `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                          false
                        )}
                        setValue={(value) => {
                          setValue({
                            path: `gameConfig.components.${component.id}.${setting.path}`,
                            value,
                          });
                        }}
                        label={setting.caption}
                        userPermissions={props.userPermissions}
                        createSnackbar={props.createSnackbar}
                        isVisible={props.isVisible}
                      ></SwitchGeneric>
                    </div>
                  );
                }
                if (setting.editorType === "color") {
                  let tooltipLength = getTextWidth(setting.caption);

                  return (
                    <div key={`${component.id}.${setting.path}`}>
                      {renderTooltip(setting.tooltip, props.isVisible, tooltipLength + 5)}

                      <SelectorColor
                        key={`${component.id}.${setting.path}`}
                        label={setting.caption}
                        tooltipLength={-29}
                        tooltip={setting.tooltip}
                        classes={props.classes}
                        value={_.get(
                          props,
                          `selectedVersion.gameConfig.components.${component.id}.${setting.path}`,
                          "#ffffff"
                        )}
                        setValue={(value) => {
                          setValue({
                            path: `gameConfig.components.${component.id}.${setting.path}`,
                            value,
                          });
                        }}
                        userPermissions={props.userPermissions}
                        createSnackbar={props.createSnackbar}
                        isVisible={props.isVisible}
                      ></SelectorColor>
                    </div>
                  );
                }

                if (setting.editorType === "selectorString") {
                  let tooltipLength = getTextWidth(setting.caption);

                  return (
                    <div key={`${component.id}.${setting.path}`}>
                      {renderTooltip(setting.tooltip, props.isVisible, tooltipLength + 5)}

                      <SelectorGeneric
                        style={{ width: "100%" }}
                        key={`${component.id}.${setting.path}`}
                        label={setting.caption}
                        classes={props.classes}
                        tooltipLength={-48}
                        tooltip={setting.tooltip}
                        value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 0)}
                        items={setting.items}
                        setValue={(value) => {
                          setValue({
                            path: `gameConfig.components.${component.id}.${setting.path}`,
                            value,
                          });
                        }}
                        userPermissions={props.userPermissions}
                        createSnackbar={props.createSnackbar}
                        isVisible={props.isVisible}
                      ></SelectorGeneric>
                    </div>
                  );
                }
                if (setting.editorType === "selectorAtlas") {
                  let tooltipLength = getTextWidth(setting.caption);

                  return (
                    <div key={`${component.id}.${setting.path}`}>
                      {renderTooltip(setting.tooltip, props.isVisible, tooltipLength + 5)}

                      <SelectorAtlas
                        key={`${component.id}.${setting.path}`}
                        selectedBase={props.selectedBase}
                        componentID={component.id}
                        label={setting.caption}
                        classes={props.classes}
                        tooltipLength={-28}
                        tooltip={setting.tooltip}
                        atlases={_.get(props, "atlases", [])}
                        componentType={component.type}
                        path={setting.path}
                        bucket={setting.bucket}
                        destinations={setting.destinations}
                        selectedAnimationSetID={_.get(
                          props,
                          `selectedVersion.gameConfig.components.${component.id}.${setting.path}.[0].animationSet`
                        )}
                        setValue={(p) => {
                          setValue({
                            path: `gameConfig.components.${component.id}.${setting.path}`,
                            value: p.value,
                          });
                        }}
                        insertAtlasPathValue={props.insertAtlasPathValue}
                        deleteAnimationSetFromAtlases={props.deleteAnimationSetFromAtlases}
                        createSnackbar={props.createSnackbar}
                        selectedVersion={props.selectedVersion}
                        userPermissions={props.userPermissions}
                        isVisible={props.isVisible}
                      ></SelectorAtlas>
                    </div>
                  );
                }
                if (setting.editorType === "selectorImage") {
                  let tooltipLength = getTextWidth(setting.caption);

                  return (
                    <div key={`${component.id}.${setting.path}`}>
                      {renderTooltip(setting.tooltip, props.isVisible, tooltipLength + 5)}

                      <SelectorImage
                        style={{ width: "100%" }}
                        key={`${component.id}.${setting.path}`}
                        selectedBase={props.selectedBase}
                        componentID={component.id}
                        tooltipLength={-28}
                        tooltip={setting.tooltip}
                        label={setting.caption}
                        classes={props.classes}
                        images={_.get(props, "images", [])}
                        componentType={component.type}
                        path={setting.path}
                        destination={setting.destination}
                        bucket={setting.bucket}
                        selectedImage={_.get(
                          props,
                          `selectedVersion.gameConfig.components.${component.id}.${setting.path}[0]`
                        )}
                        setValue={(p) => {
                          setValue({
                            path: `gameConfig.components.${component.id}.${setting.path}`,
                            value: p.value,
                          });
                        }}
                        insertMediaPathValue={props.insertMediaPathValue}
                        createSnackbar={props.createSnackbar}
                        selectedVersion={props.selectedVersion}
                        deleteImageFromMedia={props.deleteImageFromMedia}
                        userPermissions={props.userPermissions}
                        isVisible={props.isVisible}
                        setIsDialogConfirmRefreshOpen={props.setIsDialogConfirmRefreshOpen}
                        setIsDialogCircularProgressOpen={props.setIsDialogCircularProgressOpen}
                      ></SelectorImage>
                    </div>
                  );
                }
                if (setting.editorType === "sliderGeneric") {
                  let tooltipLength = getTextWidth(setting.caption);

                  return (
                    <div key={`${component.id}.${setting.path}`} style={{ marginTop: "5%" }}>
                      {renderTooltip(setting.tooltip, props.isVisible, tooltipLength + 5)}

                      <SliderGeneric
                        id={`${component.id}.${setting.path}`}
                        key={`${component.id}.${setting.path}`}
                        min={setting.min}
                        max={setting.max}
                        step={setting.step}
                        classes={props.classes}
                        tooltipLength={-28}
                        tooltip={setting.tooltip}
                        editorSubType={setting.editorSubType}
                        newSetPortrait={props.newSetPortrait}
                        path={`gameConfig.components.${component.id}.${setting.path}`}
                        label={setting.caption}
                        value={_.get(props, `selectedVersion.gameConfig.components.${component.id}.${setting.path}`, 0)}
                        setValue={(pathToSet, value) => {
                          setValue({
                            path: pathToSet,
                            value,
                          });
                        }}
                        userPermissions={props.userPermissions}
                        createSnackbar={props.createSnackbar}
                        isVisible={props.isVisible}
                      ></SliderGeneric>
                    </div>
                  );
                }
              })}
            </div>
          )}
        </div>
      }
    </>
  );
}
