import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import fetcher from "../modules/fetcher";
import { TextField, MenuItem } from "@material-ui/core";
import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";

const useStyles = makeStyles((theme) => ({}));

export default function selectorBase(props) {
  const classes = useStyles();

  function getBaseByID(baseID) {
    const found = props.items.filter((base) => {
      return String(_.get(base, "_id")) === String(baseID);
    });
    return _.size(found) > 0 ? found[0] : null;
  }

  if (_.size(props.items) > 0 && _.has(props, "selectedItem._id"))
    return (
      <div id="selectorBase">
        <TextField
          id="selectorBase_textField"
          select
          value={props.selectedItem._id}
          onChange={(event) => {
            if (!_.isNil(props.selectedVersion) && props.userPermissions["update"]) {
              const createdLanguages = _.get(props, "selectedVersion.createdLanguages") ? _.get(props, "selectedVersion.createdLanguages"): ["en"];

              fetch(getServerUri("version/updateOne"), {
                method: "POST",
                headers: { "content-type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams({
                  accessToken: localStorage.getItem("session.accessToken"),
                  versionID: String(_.get(props, "selectedVersion._id")),
                  gameConfig: JSON.stringify(_.get(props, "selectedVersion.gameConfig")),
                  recorderSettings: JSON.stringify(_.get(props, "selectedVersion.recorderSettings")),
                  createdLanguages: JSON.stringify(createdLanguages)

                  //isBeingUpdated: String(false)
                }),
              })
                .then((response) => {
                  if (response.status === 200) {
                    console.log({ message: "version updated" });
                    const newVersion = _.cloneDeep(props.selectedVersion);
                    //_.set(newVersion, 'isBeingUpdated', false);
                    const newVersions = _.cloneDeep(props.versions);
                    for (let i = 0; i < newVersions.length; i++)
                      if (newVersions[i]._id === props.selectedVersion._id) {
                        newVersions[i] = newVersion;
                      }
                    props.setVersions(newVersions);
                    props.setSelectedItem(getBaseByID(event.target.value));
                  } else if (response.status === 401) {
                    //props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
                    response
                      .text()
                      .then((textResponse) => {
                        console.log({ message: "error updating", textResponse });
                      })
                      .catch(console.error);
                    props.setSelectedItem(getBaseByID(event.target.value));
                  } else {
                    props.setSelectedItem(getBaseByID(event.target.value));
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            } else {
              props.setSelectedItem(getBaseByID(event.target.value));
            }
          }}
        >
          {props.items.map((base) => (
            <MenuItem id="selectorBase_popup" key={String(base._id)} value={String(base._id)}>
              {" "}
              {String(base.title)}
            </MenuItem>
          ))}
        </TextField>
      </div>
    );
  else return null;
}
