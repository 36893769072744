import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  CardContent,
  Card,
  Typography,
  MenuItem,
  Select,
  Dialog,
  DialogTitle,
  Button,
  DialogContent,
  IconButton,
  CircularProgress,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { getServerUri } from "../modules/uriUtility";
import _ from "lodash";
import jsonImage from "../../images/json_d-2.png";
import DialogAtlasDelete from "./dialogAtlasDelete";
import ImageAtlasPreview from "./ImageAtlasPreviewComponent";
import { Remove, Close, Visibility } from "@material-ui/icons";
import Tooltip from "@mui/material/Tooltip";
const useStyles = makeStyles({
  iconButton: {
    padding: 0,
  },
});
import ReactGA4 from "react-ga4";
ReactGA4.initialize("UA-186063268-1", {
  debug: false,
  gaOptions: {
    userId: localStorage.getItem("user.id"),
  },
});

/**
 * @param props.componentID
 * @param props.componentType
 * @param props.path
 * @param props.setValue
 * @param props.value
 * @param props.atlases
 * @param props.classes
 * @param props.replaces
 * @param props.componentID
 * @param props.selectedAnimationSetID
 * @param props.insertAtlasPathValue
 * @param props.deleteAnimationSetFromAtlases
 * @param props.selectedBase
 * @param props.userPermissions
 * @param props.createSnackbar
 * @returns {JSX.Element}
 */
export default function selectorAtlas(props) {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorCount, setErrorCount] = useState(0);
  const [atlases, setAtlases] = useState(atlasesToView(_.get(props, "atlases", [])));
  const [isUploading, setIsUploading] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [newImageSrc, setNewImageSrc] = useState(null);
  const [fileOrginalSize, setOriginalSize] = useState(null);
  const [fileOptimizedSize, setOptimizedSize] = useState(null);
  const [imageLength, setImageLength] = useState(null);
  const [sliderValue, setSliderValue] = useState(0);
  const [compressionRate, setCompressionRate] = useState(0);
  const [compressedFile, setCompressedFile] = useState(null);
  const [fileArray, setFileArray] = useState(new Array(20).fill(null));
  const [countOfDiv, setCountOfDiv] = useState(0);
  const [imageUrl, setImageUrl] = useState(null);
  const [imgID, setImgID] = useState(null);
  const [buttonID, setButtonID] = useState(null);
  const [inputID, setInputID] = useState(null);
  const [checkButtonID, setCheckButtonID] = useState(null);

  const [previousValue, setPreviousValue] = useState(0); // Önceki değeri saklamak için bir state tanımlandı
  const [circularProgress, setCircularProgress] = useState(false);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const animationSets = atlases.reduce((arr, item) => {
    const animationSet = _.get(item, "animationSet");
    if (!_.isNil(animationSet) && arr.indexOf(animationSet) === -1) {
      arr.push(animationSet);
    }
    return arr;
  }, []);
  const defaultAnimationSetID = _.get(animationSets, "[0]", "");
  const [isDeleteAtlasDialogOpen, setIsDeleteAtlasDialogOpen] = useState(false);
  const [animationSetToDeleteID, setAnimationSetToDeleteID] = useState(null);
  const [selectedAnimationSetID, setSelectedAnimationSetID] = useState(
    _.get(props, "selectedAnimationSetID", defaultAnimationSetID)
  );
  const [imageUploadOpen, setImageUploadOpen] = useState(false);
  const [compressText, setCompressText] = useState("Image is already compressed");

  const classes = useStyles();

  useEffect(() => {
    checkFilesValid();
  }, [isDialogOpen]);

  useEffect(() => {
    let isMounted = true;

    if (imageUrl !== null) {
      fetch(imageUrl)
        .then((response) => response.blob())
        .then((blob) => {
          if (isMounted) {
            const objectURL = URL.createObjectURL(blob);
            document.getElementById(imgID).src = objectURL;
            document.getElementById(buttonID).style.display = "none";
            document.getElementById(imgID).style.display = "block";
            checkFilesValid();
          }
        })
        .catch((error) => {
          console.error("test error", error);
        });
    } else {
      if (buttonID !== null || imgID !== null) {
        setInputFileListEmpty(inputID);
        document.getElementById(imgID).style.display = "none";
        document.getElementById(buttonID).style.display = "block";
      }
    }

    return () => {
      isMounted = false;
    };
  }, [imageUrl]);

  useEffect(() => {
    setAtlases(atlasesToView(_.get(props, "atlases", [])));
  }, [_.get(props, "atlases", []), _.size(_.get(props, "atlases", []))]);

  function atlasesToView(atlases) {
    return atlases.filter((atlas) => {
      return (
        !_.isNil(_.get(atlas, "bucket")) &&
        !_.isNil(_.get(atlas, "animationSet")) &&
        _.get(atlas, "bucket") === _.get(props, "bucket")
      );
    });
  }

  function gettingMargin(numberMargin) {
    if (props.tooltip != undefined) {
      if (numberMargin) {
        return numberMargin.toString() + "px";
      }
    } else return (0).toString() + "px";
  }
  let fileSelectors = [];
  let [uploadStatus, setUploadStatus] = useState("ready");

  useEffect(() => {
    const recordedSelectedAnimationSetID = _.get(props, "selectedAnimationSetID", "");
    if (isSelectionValid(recordedSelectedAnimationSetID)) {
      setSelectedAnimationSetID(recordedSelectedAnimationSetID);
    } else if (isSelectionValid(defaultAnimationSetID)) {
      selectAnimationSet(defaultAnimationSetID);
    }
  }, [_.get(props, "selectedVersion")]);

  function isSelectionValid(animationSetID, newAtlases) {
    let atlases2;
    if (_.isNil(newAtlases)) {
      atlases2 = atlases;
    } else {
      atlases2 = newAtlases;
    }
    const foundAtlases = atlases2.filter((atlas) => {
      return String(atlas.animationSet) === String(animationSetID);
    });
    return foundAtlases.length > 0 || animationSetID === "";
  }

  const variants = new Set(Object.keys(_.get(props, "destinations", {})));

  function getSelectedAtlasesByAnimationSetID(animationSetID, newAtlases) {
    const selectedAtlases = [];
    newAtlases.forEach((atlas) => {
      if (_.get(atlas, `animationSet`) === animationSetID) {
        const newAtlas = _.cloneDeep(atlas);
        _.set(newAtlas, "destination", _.get(props, `destinations.${_.get(newAtlas, `variant`)}`));
        selectedAtlases.push(newAtlas);
      }
    });
    return selectedAtlases;
  }
  function handleFileUpload(file) {
    // const file = event.target.files[0];
    const newFileName = file.name.substring(0, file.name.lastIndexOf(".")) || file.name;

    setUploadedFileName(newFileName);
    ReactGA4.event({
      category: "Preview",
      action: "Image upload page is open selectorAtlas ",
      label:
        "filename: " +
        file.name +
        " fileSize in mb: " +
        fileSizeInMB +
        " ,base: " +
        props.selectedBase.title +
        " ,originalVersion: " +
        props.selectedVersion.title,
      dimension1: localStorage.getItem("user.id"),
    });
    const fileSizeInMB = (file.size / (1024 * 1024)).toFixed(3);
    if (fileSizeInMB > 3) {
      props.createSnackbar("File size should be 3MB ", "warning", false);
      return;
    }
    if (fileSizeInMB >= 1) {
      setOriginalSize(`${fileSizeInMB.toFixed(1)}MB`);
    } else {
      const fileSizeInKB = file.size / 1024;
      setOriginalSize(`${fileSizeInKB.toFixed(2)}KB`);
    }
    setSelectedFile(file);
    const img = new Image();
    setCircularProgress(true);

    img.onload = () => {
      if (img.height > 4096 || img.width > 4096) {
        props.createSnackbar("Height and width must not exceed 4096px", "error");
        setImageUploadOpen(false); // set the state to close the dialog
        return;
      } else {
        setImageUploadOpen(true);
        CompressImage(file);
        setImageLength(`${img.width}px X ${img.height}px`);
        const inputImgRatio = img.width / img.height;
        const patternCanvas = createPatternCanvas();
        const cnvs = document.createElement("canvas");
        const exCtx = cnvs.getContext("2d");

        if (window.devicePixelRatio > 1) {
          cnvs.width = 450 * 4;
          cnvs.height = 450 * 4;
          cnvs.style.width = "450px";
          cnvs.style.height = "450px";
          exCtx.scale(4, 4);
        } else {
          cnvs.width = 450;
          cnvs.height = 450;
        }
        exCtx.globalCompositeOperation = "source-over";
        const pattern = exCtx.createPattern(patternCanvas, "repeat");
        exCtx.fillStyle = pattern;
        exCtx.fillRect(0, 0, cnvs.width, cnvs.height);
        if (img.width < 450 && img.height < 450) {
          exCtx.drawImage(img, (450 - img.width) / 2, (450 - img.height) / 2, img.width, img.height);
        } else {
          if (inputImgRatio > 1) {
            const imgHeight = 450 / inputImgRatio;
            exCtx.drawImage(img, 0, (450 - imgHeight) / 2, 450, 450 / inputImgRatio);
          } else {
            const imgWidth = 450 * inputImgRatio;
            exCtx.drawImage(img, (450 - imgWidth) / 2, 0, 450 * inputImgRatio, 450);
          }
        }

        const originalImageUrl = cnvs.toDataURL("image/png", 1);
        setOriginalImage(originalImageUrl);
      }
    };
    img.src = URL.createObjectURL(file);
    URL.revokeObjectURL(URL.createObjectURL(file));

    // setCircularProgress(false);
  }
  function selectAnimationSet(animationSetID, newAtlases) {
    if (!isSelectionValid(animationSetID, newAtlases)) return;
    setSelectedAnimationSetID(animationSetID);
    let atlases2;
    if (_.isNil(newAtlases)) {
      atlases2 = atlases;
    } else {
      atlases2 = newAtlases;
    }
    props.setValue({
      value: getSelectedAtlasesByAnimationSetID(animationSetID, atlases2),
    });
  }
  function CompressImage(file) {
    setCompressText(" ");
    const imgResized = new Image();
    imgResized.src = URL.createObjectURL(file);
    let safariAgent = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    imgResized.onload = () => {
      const cnvs = document.createElement("canvas");
      cnvs.width = imgResized.width;
      cnvs.height = imgResized.height;
      const ctx = cnvs.getContext("2d");
      ctx.drawImage(imgResized, 0, 0, imgResized.width, imgResized.height);
      let webp = null;
      let webpFile;

      if (safariAgent && file.type === "image/png") {
        webp = file;
        webpFile = webp;
        setCompressText(
          "⚠️ Png compress is not supported in Safari.\n You may still upload uncompressed png or Use Chrome. "
        );
        setSliderValue(0);
      } else {
        if (safariAgent) {
          webp = cnvs.toDataURL("image/jpeg", 0.99);
        } else {
          webp = cnvs.toDataURL("image/webp", 0.99);
        }
        webpFile = new File([webpToFile(webp)], file.type, { type: file.type });
        let base64String = findBase64(webp);
        let finalCompressedRatio = 0;
        if (base64String > file.size / 1024) {
          let target = file.size / 1024;
          let initial = base64String;
          let prevScale = 0;

          finalCompressedRatio = GetCompressionRate(cnvs, file.type, initial, 1, target, prevScale);
          setCompressionRate(finalCompressedRatio);
          console.log("finalCompress", finalCompressedRatio);
          if (finalCompressedRatio === -1) {
            setSliderValue(0);
            setCompressText("Image is already compressed");
          } else {
            if (safariAgent) {
              webp = cnvs.toDataURL("image/jpeg", 1 - (1 - finalCompressedRatio + (finalCompressedRatio * 1) / 10));
            } else {
              webp = cnvs.toDataURL("image/webp", 1 - (1 - finalCompressedRatio + (finalCompressedRatio * 1) / 10));
            }

            base64String = findBase64(webp);
            if (base64String.toFixed(2) > (file.size / 1024).toFixed(2)) {
              webpFile = file;
              setSliderValue(0);
              setCompressText("Image is already compressed");
            } else {
              setSliderValue(1);
            }
          }
        } else {
          setCompressionRate(0);
        }
        let compressedSize = 0;
        let nthreshold = 0;
        let prevVal = 0;
        let prevSize = 0;
        for (let i = 0; i < 10; i++) {
          let calculation = 0;
          if (finalCompressedRatio == -1) {
            break;
          }
          if (finalCompressedRatio === 0) {
            calculation = 1 - i / 10;
          } else {
            calculation = 1 - (1 - finalCompressedRatio + (finalCompressedRatio * i) / 10);
          }
          if (safariAgent) {
            webp = cnvs.toDataURL("image/jpeg", calculation);
          } else {
            webp = cnvs.toDataURL("image/webp", calculation);
          }
          base64String = findBase64(webp);
          compressedSize = base64String.toFixed(1);
          if (compressedSize - prevSize >= 0 && compressedSize - prevSize < 1) {
            nthreshold = compressedSize;
            prevVal = i;
            break;
          }

          prevSize = compressedSize;
          prevVal = 999;
        }
        let pVar = 0;
        if (prevVal - 1 >= 0 && prevVal < 100) {
          pVar = prevVal - 1;
        } else if (prevVal == 999) {
          pVar = 999;
        } else {
          pVar = 0;
        }
        if (pVar != 999) {
          setPreviousValue(pVar);
        } else {
          setPreviousValue(0);
        }

        if (pVar <= 0) {
          setSliderValue(0);
          setCompressText("Image is already compressed");
        } else {
          if (prevSize > file.size / 1024) {
            setSliderValue(0);
            setCompressText("Image is already compressed");
          } else {
            setSliderValue(1);
          }
        }
        if (safariAgent) {
          webp = cnvs.toDataURL("image/jpeg", 1 - 1 / 10);
        } else {
          webp = cnvs.toDataURL("image/webp", 1 - 1 / 10);
        }
        webpFile = new File([webpToFile(webp)], file.type, { type: file.type });
        const resizedSizeInMB = webpFile.size / (1024 * 1024);
        if (resizedSizeInMB >= 1) {
          setOptimizedSize(`${resizedSizeInMB.toFixed(1)}MB`);
        } else {
          const fileSizeInKB = webpFile.size / 1024;
          setOptimizedSize(`${fileSizeInKB.toFixed(2)}KB`);
        }
      }
      let compressedImage = new Image();
      setCircularProgress(false);
      compressedImage.src = URL.createObjectURL(webpFile);
      setImageUrl(URL.createObjectURL(webpFile));
    };
  }
  const CloseButtonOnclick = () => {
    if (selectedFile != null) {
      // ApplyToDB(selectedFile);
      setImageUploadOpen(false);
    }
  };
  function checkFilesValid() {
    let fileSelectorsErrorCount = 0;
    fileSelectors.forEach((fileSelector, index, array) => {
      let selectorErrorCount = 0;
      if (_.get(document.getElementById(fileSelector.id), "files.length", 0) === 0) {
        selectorErrorCount++;
        fileSelectorsErrorCount++;
      }
      if (_.get(document.getElementById(fileSelector.id), "files.length", 0) > 1) {
        selectorErrorCount++;
        fileSelectorsErrorCount++;
      }
      if (selectorErrorCount > 0) return;
    });
    setErrorCount(fileSelectorsErrorCount);
    return fileSelectorsErrorCount > 0 ? false : true;
  }

  function getAcceptableFileTypes(variant) {
    let type;
    switch (variant) {
      case "image":
        type = "image/*";
        break;
      case "JSON":
        type = "application/json";
        break;
      default:
        type = "image/*";
        break;
    }
    return type;
  }
  function CompressAndReturnSize(cnvs, file, scale) {
    let webp = null;
    let safariAgent = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (file == "image/png" || file.type == "image/jpeg") {
      if (safariAgent) {
        webp = cnvs.toDataURL("image/jpeg", scale);
      } else {
        webp = cnvs.toDataURL("image/webp", scale);
      }
    } else {
      webp = cnvs.toDataURL("image/jpeg", scale);
    }
    let base64String = findBase64(webp);
    return base64String;
  }

  function GetCompressionRate(cnvs, file, currentValue, currentScale, target, prevScale, counter = 0) {
    let scaleToGo = 0;
    let finalScale;

    if (counter >= 10) {
      if (currentValue < 7) {
        currentScale = -1;
      }

      return currentScale;
    }

    if (prevScale == 0) {
      scaleToGo = currentScale / 2;
      let newSize = CompressAndReturnSize(cnvs, file, scaleToGo);

      prevScale = currentScale;
      finalScale = GetCompressionRate(cnvs, file, newSize, scaleToGo, target, prevScale, counter + 1);
    } else {
      if (currentValue > target) {
        // current buyuk
        scaleToGo = currentScale - Math.abs(-currentScale + prevScale) / 2;
        let newSize = CompressAndReturnSize(cnvs, file, scaleToGo);

        prevScale = currentScale;
        finalScale = GetCompressionRate(cnvs, file, newSize, scaleToGo, target, prevScale, counter + 1);
      } else {
        //current küçük
        if (target - currentValue > 10) {
          scaleToGo = currentScale + Math.abs(-currentScale + prevScale) / 2;
          let newSize = CompressAndReturnSize(cnvs, file, scaleToGo);

          prevScale = currentScale;
          finalScale = GetCompressionRate(cnvs, file, newSize, scaleToGo, target, prevScale, counter + 1);
        } else {
          return currentScale;
        }
      }
    }

    return finalScale;
  }
  function findBase64(base64String) {
    var stringLength = base64String.length - "data:image/png;base64,".length;

    var sizeInBytes = 4 * Math.ceil(stringLength / 3) * 0.5624896334383812;
    var sizeInKb = sizeInBytes / 1000;
    return sizeInKb;
  }
  const createPatternCanvas = () => {
    const patternCanvas = document.createElement("canvas");
    const patternCtx = patternCanvas.getContext("2d");
    const size = 8; // desen boyutu

    patternCanvas.width = size * 2;
    patternCanvas.height = size * 2;
    // Dama deseni oluşturuluyor
    patternCtx.fillStyle = "#bfbfbf";
    patternCtx.fillRect(0, 0, size, size);
    patternCtx.fillRect(size, size, size, size);
    patternCtx.fillStyle = "#fefefe";
    patternCtx.fillRect(size, 0, size, size);
    patternCtx.fillRect(0, size, size, size);

    return patternCanvas;
  };
  function webpToFile(webp) {
    const parts = webp.split(",");
    const mime = parts[0].split(":")[1].split(";")[0];
    const bstr = atob(parts[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return u8arr;
  }
  function setInputFileListEmpty(inputId) {
    let list = new DataTransfer();
    document.getElementById(inputId).files = list.files;
  }
  const handleClose = () => {
    setImageUploadOpen(false); // set the state to close the dialog
  };
  return (
    <>
      {props.isVisible && (
        <>
          {_.get(props, `label`) && (
            <Typography id="discrete-slider" gutterBottom style={{ marginTop: gettingMargin(props.tooltipLength) }}>
              {_.get(props, `label`)}
            </Typography>
          )}
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "flex-start" }}>
            <Select
              key={`${props.componentID}.${props.path}`}
              onChange={(event) => {
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You do not have permission to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                selectAnimationSet(event.target.value);
              }}
              className={props.classes.imageSelector}
              value={selectedAnimationSetID}
            >
              {animationSets.map((animationSet) => (
                <MenuItem
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  key={animationSet}
                  value={animationSet}
                >
                  <div
                    style={{
                      display: "flex",
                      width: "60%",
                    }}
                  >
                    {atlases.map(
                      (atlasRecord) =>
                        _.get(atlasRecord, `animationSet`) === animationSet &&
                        _.get(atlasRecord, `variant`) === "image" && ( // just show variant 'image', variant 'json' has not view
                          <>
                            <img
                              key={_.get(atlasRecord, `_id`)}
                              style={{ height: "50px", maxWidth: "30px", objectFit: "contain" }}
                              src={`atlases/${atlasRecord.thumbFile || atlasRecord.file}`}
                            />
                            {atlasRecord.uploadedFileName && (
                              <div
                                style={{
                                  justifyContent: "center",
                                  display: "flex",
                                  alignItems: "center",
                                  marginLeft: "15px",
                                }}
                              >
                                <Tooltip
                                  // key={_.get(atlasRecord, `_id`)}
                                  id="uploadedFileName_tooltip"
                                  title={atlasRecord.uploadedFileName}
                                  enterDelay={1000}
                                  leaveDelay={200}
                                  placement="bottom-start"
                                >
                                  <Typography style={{ textOverflow: "ellipsis", width: "252px", overflow: "hidden" }}>
                                    {atlasRecord.uploadedFileName}{" "}
                                  </Typography>
                                </Tooltip>
                              </div>
                            )}
                          </>
                        )
                    )}
                  </div>
                  {selectedAnimationSetID !== animationSet && (
                    <div
                      style={{
                        float: "right",
                      }}
                    >
                      <IconButton
                        className={classes.iconButton}
                        onClick={(e) => {
                          e.stopPropagation();
                          if (props.userPermissions["delete"] === false) {
                            props.createSnackbar(
                              "You do not have permission to delete, contact your administrator.",
                              "warning",
                              false
                            );
                            return;
                          }
                          setAnimationSetToDeleteID(animationSet);
                          setIsDeleteAtlasDialogOpen(true);
                        }}
                      >
                        <Remove />
                      </IconButton>
                    </div>
                  )}
                </MenuItem>
              ))}
            </Select>
            <Dialog
              open={isDialogOpen}
              onClose={(e) => {
                setIsDialogOpen(false);
              }}
            >
              <DialogTitle>{"Select Atlas Animation Set"}</DialogTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    flexFlow: "row wrap",
                  }}
                >
                  {Array.from(variants).map((variant) => {
                    const imgID = `${_.get(props, `componentID`)}-${variant}-img`;
                    const buttonID = `${_.get(props, `componentID`)}-${variant}-button`;
                    const inputID = `${_.get(props, `componentID`)}-${variant}-input`;
                    const checkButtonID = `${_.get(props, `componentID`)}-${variant}-check-button`;
                    fileSelectors.push({
                      id: inputID,
                      variant,
                      replaces: _.get(props, `replaces.${variant}`, ""),
                    });
                    return (
                      <div
                        key={variant}
                        style={{
                          display: "flex",
                          flex: "1 0 auto",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          margin: "5px 15px 5px 15px",
                        }}
                      >
                        <Typography style={{ display: "block" }}>{variant}</Typography>
                        <input
                          id={inputID}
                          type={"file"}
                          hidden
                          accept={getAcceptableFileTypes(variant)}
                          onChange={(e) => {
                            const reader = new FileReader();
                            const newFile = e.target.files[0];
                            reader.readAsDataURL(e.target.files[0]);
                            // var file=event.target.files[0]
                            reader.onload = (e) => {
                              if (variant === "image") {
                                handleFileUpload(newFile);
                              } else if (variant === "JSON") {
                                const updatedArray = fileArray; // Önceden mevcut olan dizi
                                updatedArray[1] = newFile;
                                setFileArray(updatedArray);
                                document.getElementById(buttonID).style.display = "none";
                                document.getElementById(checkButtonID).style.display = "block";
                                checkFilesValid();
                              }
                            };
                          }}
                        />
                        <Dialog
                          open={imageUploadOpen}
                          onClose={handleClose}
                          scroll="paper"
                          fullWidth={true}
                          maxWidth={"md"}
                          // classes={{ paper: classes.dialogPaper }}
                        >
                          <DialogContent
                            style={{
                              paddingBottom: "20px",
                              position: "relative",
                            }}
                          >
                            <Button
                              style={{ float: "right", color: "#4eabb1" }}
                              onClick={(e) => {
                                setImageUploadOpen(false);
                                setImageUrl(null);
                              }}
                            >
                              <Close> </Close>
                            </Button>

                            <ImageAtlasPreview
                              imageLength={imageLength}
                              inputID={inputID}
                              compressText={compressText}
                              previousValue={previousValue}
                              compressedFile={compressedFile}
                              circularProgress={circularProgress}
                              compressionRate={compressionRate}
                              fileArray={fileArray}
                              countOfDiv={countOfDiv}
                              setFileArray={(value) => setFileArray(value)}
                              setImageUrl={(value) => setImageUrl(value)}
                              buttonID={buttonID}
                              sliderValue={sliderValue}
                              selectedBase={props.selectedBase}
                              componentID={props.componentID}
                              setIsDialogCircularProgressOpen={(value) => props.setIsDialogCircularProgressOpen(value)}
                              setValue={(value) => props.setValue(value)}
                              setImageUploadOpen={(value) => setImageUploadOpen(value)}
                              fileOrginalSize={fileOrginalSize}
                              fileOptimizedSize={fileOptimizedSize}
                              newImageSrc={newImageSrc}
                              originalImage={originalImage}
                              images={props.images}
                              userPermissions={props.userPermissions}
                              destination={props.destination}
                              selectedVersion={props.selectedVersion}
                              selectedFile={selectedFile}
                              createSnackbar={props.createSnackbar}
                              componentType={props.componentType}
                              selectedImage={props.selectedImage}
                              insertMediaPathValue={props.insertMediaPathValue}
                              path={props.path}
                              bucket={props.bucket}
                            ></ImageAtlasPreview>
                          </DialogContent>
                        </Dialog>
                        <Card
                          // id={imageSetCard}
                          className={props.classes.imageUploadPreview}
                          onClick={(e) => {
                            document.getElementById(inputID).click();
                            setInputID(inputID);
                            setButtonID(buttonID);
                            setImgID(imgID);
                            setCheckButtonID(checkButtonID);
                            // setImgID(imgID);
                          }}
                        >
                          <CardContent
                            style={{
                              display: "flex",
                              flex: "1 0 auto",
                              flexDirection: "column",
                              alignItems: "center",
                              justifyContent: "center",
                              weight: "100%",
                              height: "100%",
                            }}
                          >
                            <img
                              id={imgID}
                              style={{
                                display: "none",
                                objectFit: "contain",
                                width: "100%",
                                height: "100%",
                              }}
                              // onClick={(e) => {
                              // document.getElementById(inputID).click();
                              // }}
                            />
                            <AddIcon id={buttonID} style={{ width: 50, height: 50 }}></AddIcon>
                            <img
                              id={checkButtonID}
                              src={jsonImage}
                              style={{ width: 50, height: 50, display: "none" }}
                              onClick={(e) => {
                                document.getElementById(inputID).click();
                              }}
                            />
                          </CardContent>
                        </Card>
                      </div>
                    );
                  })}
                </div>
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <CircularProgress
                    color={"secondary"}
                    style={{
                      textAlign: "center",
                      display: isUploading ? "block" : "none",
                    }}
                  ></CircularProgress>
                  <Button
                    style={{
                      width: "100%",
                      height: "100%",
                      margin: 5,
                      display: !isUploading ? "block" : "none",
                    }}
                    disabled={!isDialogOpen || errorCount > 0}
                    onClick={(e) => {
                      setIsUploading(true);
                      const formData = new FormData();
                      formData.append("path", _.get(props, "path"));
                      formData.append("accessToken", localStorage.getItem("session.accessToken"));
                      formData.append("componentType", _.get(props, "componentType"));
                      formData.append("bucket", _.get(props, "bucket"));
                      fileSelectors.forEach((fileSelector, index, array) => {
                        formData.append("variant", fileSelector.variant);
                        if (fileSelector.variant == "image") {
                          formData.append("uploadedFileName", uploadedFileName);
                        }

                        formData.append("files", fileArray[index]);
                      });
                      setFileArray([]);
                      if (errorCount > 0) return;
                      fetch(getServerUri("atlas/uploadAtlas"), {
                        method: "POST",
                        body: formData,
                      }).then((response) => {
                        setIsUploading(false);
                        if (response.status !== 200) {
                          if (response.status === 401) {
                            props.createSnackbar(
                              "You are not authorized to insert, contact your administrator.",
                              "warning"
                            );
                            response
                              .text()
                              .then((textResponse) => {
                                console.log({ message: "error inserting", textResponse });
                              })
                              .catch(console.error);
                          } else {
                            response
                              .text()
                              .then((textResponse) => {
                                console.error("Upload atlas failed: ", textResponse);
                              })
                              .catch((err) => {
                                console.error(err);
                              });
                          }
                          setIsDialogOpen(false);
                        } else {
                          response
                            .json()
                            .then((objectResponse) => {
                              console.info("Upload atlas Succeeded", objectResponse);
                              const newAtlases = _.cloneDeep(atlases);
                              const newRecords = _.get(objectResponse, "records", []);
                              newAtlases.push(...newRecords);
                              setAtlases(newAtlases);
                              props.insertAtlasPathValue(newRecords);
                              setUploadStatus("complete");

                              const newAnimationSetID = newRecords[0].animationSet;
                              setSelectedAnimationSetID(newAnimationSetID);
                              selectAnimationSet(newAnimationSetID, newAtlases);

                              setIsDialogOpen(false);
                              ReactGA4.event({
                                category: "Preview",
                                action: "Image Atlas",
                                label:
                                  "filename: " +
                                  formData.resizedFile +
                                  " path " +
                                  formData.path +
                                  " ,base: " +
                                  props.selectedBase.title +
                                  " ,originalVersion: " +
                                  props.selectedVersion.title,
                                dimension1: localStorage.getItem("user.id"),
                              });
                            })
                            .catch((err) => {
                              console.error(err);
                              setIsDialogOpen(false);
                            });
                        }
                      });
                    }}
                  >
                    {uploadStatus === "ready" && "Upload"}
                    {uploadStatus === "complete" && "Uploaded Successfully"}
                  </Button>
                </div>
                <div>{errorCount > 0 && <div style={{ padding: "0.5rem" }}>* Please select all assets.</div>}</div>
              </div>
            </Dialog>
            <IconButton
              className={classes.iconButton}
              onClick={(e) => {
                if (props.userPermissions["insert"] === false) {
                  props.createSnackbar(
                    "You are not authorized to insert, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                if (props.userPermissions["update"] === false) {
                  props.createSnackbar(
                    "You are not authorized to update, contact your administrator.",
                    "warning",
                    false
                  );
                  return;
                }
                setUploadStatus("ready");
                setIsDialogOpen(true);
              }}
            >
              <AddIcon />
            </IconButton>
            <DialogAtlasDelete
              isOpen={isDeleteAtlasDialogOpen}
              setIsOpen={(value) => setIsDeleteAtlasDialogOpen(value)}
              animationSetToDeleteID={animationSetToDeleteID}
              atlases={atlases}
              setAtlases={setAtlases}
              deleteAnimationSetFromAtlases={props.deleteAnimationSetFromAtlases}
              createSnackbar={props.createSnackbar}
            />
          </div>
        </>
      )}
    </>
  );
}
