import React, { useEffect, useState } from "react";
import { Button, Dialog, DialogTitle } from "@material-ui/core";
import { getServerUri } from "../modules/uriUtility";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";

const useClasses = makeStyles({
  dialogDelete: {
    margin: 10,
  },
});
/**
 *
 * @param {Boolean} props.isOpen is it open?
 * @param {Function} props.setIsOpen set is open to true or false
 * @param {Function} props.deleteVersionFromView delete the version from view
 * @param {String} props.selectedVersionID the selected version id
 * @param props.createSnackbar
 * @returns {JSX.Element}
 */
export default function dialogVersionDelete(props) {
  let [versionHie, setVersionHierarchy] = useState(1);

  const [versionID, setVersionID] = useState("");
  const [nversionID, setNVersionID] = useState("");
  const [defaultVersion, setDefaultVersion] = useState("");

  useEffect(() => {
    if (versionHie != 1) {
      var baseFolder = new Promise((resolve, reject) => {
        fetch(
          getServerUri("version/updateHierarchy"),

          {
            method: "POST",
            headers: { "content-type": "application/x-www-form-urlencoded" },
            body: new URLSearchParams({
              accessToken: localStorage.getItem("session.accessToken"),
              baseID: _.get(props, "selectedBase._id"),
              versionHierarchy: JSON.stringify(versionHie),
              companyID: props.companyID,
            }),
          }
        ).then((response) => {
          if (response.status === 200) {
            props.setVersionHierarchy(versionHie);
            // this is for folder render process
            props.setInsertNewVersion(versionID);
          }
        });
      });
    }
  }, [versionHie]);
  useEffect(() => {
    findDefaultVersion();
  }, []);
  useEffect(() => {
    if (nversionID === String(_.get(props, "selectedVersion._id"))) {
      fetchVersionHierarchy(_.get(props, "selectedVersion"));
    }
  }, [nversionID]);

  function resetSearchBar() {
    const searchBar = document.getElementById("searchBar");

    if (searchBar) {
      props.setSearchTerm(Math.floor(Math.random() * 10000) + 1);
      // searchBar.value = "";
    }
  }
  function fetchVersionHierarchy(version) {
    //take the version hierarchy
    fetch(getServerUri("version/getHierarchy"), {
      method: "POST",
      headers: { "content-type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        baseID: _.get(props, "selectedBase._id"),
        companyID: props.companyID,
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          //güncellenicek burası
          response.json().then((json) => {
            json.versionHierarchy.map(function (versionHier, index) {
              if (versionHier.id === version._id) {
                json.versionHierarchy.splice(index, 1);
              }
            });

            let newVersionTree = {};
            newVersionTree["tree"] = json.versionHierarchy;
            setVersionHierarchy(newVersionTree);
          });
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error updating", textResponse });
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((textResponse) => {
              console.log({ message: "error renaming", textResponse });
              props.createSnackbar("An error occurred when renaming a new version.", "error");
            })
            .catch(console.error);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  }
  function findDefaultVersion(){

    return new Promise((resolve, reject) => {
      fetch(getServerUri("version/findDefaultVersion"), {
        method: "POST",
        headers: { "content-type": "application/x-www-form-urlencoded" },
        body: new URLSearchParams({
          accessToken: localStorage.getItem("session.accessToken"),
          baseID: String(props.selectedBase._id),
        }),
      })
        .then((response) => {
          if (response.status === 200) {
            response.json().then((fetchedVersion) => {
              setDefaultVersion(fetchedVersion);
              resolve(fetchedVersion);
            });
          } else {
            response
              .text()
              .then((err) => reject(err))
              .catch((err) => {
                console.log(err);
              });
          }
        })
        .catch((err) => reject(err));
    });
  }

  function deleteNode(versionArray, parentID, newVersionArray, nNewVersionArray) {
    for (let i = 0; i < versionArray.length; i++) {
      // recursive içteki folderlar da konıdtrol edilcek, silinecek versiyonları eklenicek
      if (versionArray[i].parent == parentID) {
        newVersionArray.push(versionArray[i].id);
        nNewVersionArray.push(versionArray[i]);
        let arrays = deleteNode(versionArray, versionArray[i].id, newVersionArray, nNewVersionArray);
        newVersionArray = arrays[0];
        nNewVersionArray = arrays[1];
      }
    }
    return [newVersionArray, nNewVersionArray];
  }
  function deleteVersions(versionIdList) {
    fetch(getServerUri("version/deleteMultiple"), {
      method: "DELETE",
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: new URLSearchParams({
        accessToken: localStorage.getItem("session.accessToken"),
        versionIdList: JSON.stringify(versionIdList),
      }),
    })
      .then((response) => {
        if (response.status === 200) {
          props.createSnackbar("Successfully deleted", "success");

          if(!_.isNil(props.defaultVersion) && props.defaultVersion !== ""){

            const versionID = versionIdList.filter((versionID) => versionID == props.defaultVersion.versionID);

            if(versionID.length > 0){
              props.setDefaultVersionUpdated(true);
              fetch(getServerUri("version/deleteDefaultVersion"), {
                method: "DELETE",
                headers: {
                  "content-type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                  accessToken: localStorage.getItem("session.accessToken"),
                  versionID: versionID[0]
                }),
              })
                .then((response) => {
                  if (response.status === 200) {
                    props.createSnackbar("Successfully deleted", "success");
                    props.setDefaultVersionUpdated(false);

                  } else if (response.status === 401) {
                    props.createSnackbar("You are not authorized to delete, contact your administrator.", "warning");
                    response
                      .text()
                      .then((textResponse) => {
                        console.error(textResponse);
                      })
                      .catch(console.error);
                  } else {
                    response
                      .text()
                      .then((responseText) => {
                        console.log(responseText);
                      })
                      .catch(console.error);
                  }
                })
                .catch((err) => {
                  console.log("fetch error");
                  console.error(err);
                  props.createSnackbar("An error occurred when deleting the version.", "error");
                });

            }

          }


          //  props.deleteVersionFromView(versionId);
        } else if (response.status === 401) {
          props.createSnackbar("You are not authorized to delete, contact your administrator.", "warning");
          response
            .text()
            .then((textResponse) => {
              console.error(textResponse);
            })
            .catch(console.error);
        } else {
          response
            .text()
            .then((responseText) => {
              console.log(responseText);
            })
            .catch(console.error);
        }
        props.setIsOpen(false);
      })
      .catch((err) => {
        console.error(err);
        props.createSnackbar("An error occurred when deleting the version.", "error");
      });
  }

  const classes = useClasses();
  return (
    <Dialog
      id="deleteAll_dialog"
      open={props.isOpen}
      onClose={() => {
        props.setIsOpen(false);
      }}
    >
      <DialogTitle id="delete_dialogTitle">Delete?</DialogTitle>
      <div className={classes.dialogDelete}>
        <Button
          id="dialogDeleteAll_buttonID"
          label={"OK"}
          onClick={(event) => {
            console.log("props.selectedFolderId", props.selectedFolderId);
            if (props.selectedFolderId == 9999999) {
              resetSearchBar();
              fetch(getServerUri("version/deleteOne"), {
                method: "DELETE",
                headers: {
                  "content-type": "application/x-www-form-urlencoded",
                },
                body: new URLSearchParams({
                  accessToken: localStorage.getItem("session.accessToken"),
                  versionID: String(_.get(props, "selectedVersion._id")),
                }),
              })
                .then((response) => {
                  if (response.status === 200) {
                    props.createSnackbar("Successfully deleted", "success");
                    setVersionID(String(_.get(props, "selectedVersion._id")));
                    setNVersionID(String(_.get(props, "selectedVersion._id")));

                    props.deleteVersionFromView(String(_.get(props, "selectedVersion._id")), false, [1, 2]);
                    if(!_.isNil(props.defaultVersion) && props.defaultVersion !== ""){

                      const versionID = String(_.get(props, "selectedVersion._id"));

                      if(versionID == props.defaultVersion.versionID){
                        props.setDefaultVersionUpdated(true);

                        fetch(getServerUri("version/deleteDefaultVersion"), {
                          method: "DELETE",
                          headers: {
                            "content-type": "application/x-www-form-urlencoded",
                          },
                          body: new URLSearchParams({
                            accessToken: localStorage.getItem("session.accessToken"),
                            versionID: String(_.get(props, "selectedVersion._id"))
                          }),
                        })
                          .then((response) => {
                            if (response.status === 200) {
                              props.createSnackbar("Successfully deleted", "success");
                              props.setDefaultVersionUpdated(false);

                            } else if (response.status === 401) {
                              props.createSnackbar("You are not authorized to delete, contact your administrator.", "warning");
                              response
                                .text()
                                .then((textResponse) => {
                                  console.error(textResponse);
                                })
                                .catch(console.error);
                            } else {
                              response
                                .text()
                                .then((responseText) => {
                                  console.log(responseText);
                                })
                                .catch(console.error);
                            }
                          })
                          .catch((err) => {
                            console.log("fetch error");
                            console.error(err);
                            props.createSnackbar("An error occurred when deleting the version.", "error");
                          });

                      }

                    }

                  } else if (response.status === 401) {
                    props.createSnackbar("You are not authorized to delete, contact your administrator.", "warning");
                    response
                      .text()
                      .then((textResponse) => {
                        console.error(textResponse);
                      })
                      .catch(console.error);
                  } else {
                    response
                      .text()
                      .then((responseText) => {
                        console.log(responseText);
                      })
                      .catch(console.error);
                  }
                  props.setIsOpen(false);
                })
                .catch((err) => {
                  console.log("fetch error");
                  console.error(err);
                  props.createSnackbar("An error occurred when deleting the version.", "error");
                });

            } else {
              resetSearchBar();
              fetch(getServerUri("version/getHierarchy"), {
                method: "POST",
                headers: { "content-type": "application/x-www-form-urlencoded" },
                body: new URLSearchParams({
                  accessToken: localStorage.getItem("session.accessToken"),
                  baseID: _.get(props, "selectedBase._id"),
                  companyID: props.companyID,
                }),
              })
                .then((response) => {
                  if (response.status === 200) {
                    let newVersionArray = [];
                    let nNewVersionArray = [];
                    let ab = [];
                    let newVersionId = 0;

                    response.json().then((json) => {
                      json.versionHierarchy.map(function (versionHier, index) {
                        if (versionHier.id === props.selectedFolderId.id) {
                          newVersionId = versionHier;
                        }
                      });
                      if (newVersionId != 0) {
                        ab = json.versionHierarchy.filter((a) => a != newVersionId);
                      }

                      let arrays = deleteNode(
                        json.versionHierarchy,
                        props.selectedFolderId.id,
                        newVersionArray,
                        nNewVersionArray
                      );
                      newVersionArray = arrays[0];
                      nNewVersionArray = arrays[1];

                      if (newVersionArray.length != 0) {
                        nNewVersionArray.map(function (versionHier, index) {
                          ab = ab.filter((a) => a != versionHier);
                        });
                      }
                      if (ab.length >= 0) {
                        let newVersionTree = {};
                        newVersionTree["tree"] = ab;
                        setVersionHierarchy(newVersionTree);
                      }
                      if (nNewVersionArray.length > 0) {
                        props.deleteVersionFromView(String(_.get(props, "selectedVersion._id")), true, newVersionArray);
                        deleteVersions(newVersionArray);
                      } else {
                        props.setIsOpen(false);
                      }

                      props.setSelectedFolderId(9999999);
                    });
                  } else if (response.status === 401) {
                    props.createSnackbar("You are not authorized to update, contact your administrator.", "warning");
                    response
                      .text()
                      .then((textResponse) => {
                        console.log({ message: "error updating", textResponse });
                      })
                      .catch(console.error);
                  } else {
                    response
                      .text()
                      .then((textResponse) => {
                        console.log({ message: "error renaming", textResponse });
                        props.createSnackbar("An error occurred when renaming a new version.", "error");
                      })
                      .catch(console.error);
                  }
                })
                .catch((err) => {
                  console.error(err);
                });
            }
          }}
        >
          OK
        </Button>
        <Button
          id="deleteAll_cancelBtn"
          label={"Cancel"}
          onClick={() => {
            props.setIsOpen(false);
          }}
        >
          Cancel
        </Button>
      </div>
    </Dialog>
  );
}
